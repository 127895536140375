import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as catalog from '../communicator/catalog';

class StoreCatalog {
    list_categories = [];

    constructor() {
        this.getCategories();
    }

    async getCategories() {
        try {
            const returnData = await catalog.getCategories();
            runInAction(() => {
                this.list_categories = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getOrdersByCategory(year, category) {
        let list_orders_by_month;
        try {
            const returnData = await catalog.getOrdersByCategory(year, category);
            runInAction(() => {
                list_orders_by_month = returnData.data;
                return list_orders_by_month;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return list_orders_by_month;
    }

    async getOrdersByMonth(year, month) {
        let list_orders_by_month;
        try {
            const returnData = await catalog.getOrdersByMonth(year, month);
            runInAction(() => {
                list_orders_by_month = returnData.data;
                return list_orders_by_month;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return list_orders_by_month;
    }

    async checkOutOfStock() {
        let listStock;
        try {
            const returnData = await catalog.checkOutOfStock();
            runInAction(() => {
                listStock = returnData.data;
                return listStock;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return listStock;
    }

    async removeFromSite(sku) {
        try {
            const returnData = await catalog.removeFromSite(sku);
            runInAction(() => {

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async setAlternative(sku, value) {
        const data ={
            sku: sku,
            alternative: value
        }
        try {
            const returnData = await catalog.setAlternative(data);
            runInAction(() => {

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

}

decorate(StoreCatalog, {
    list_categories: observable,
    checkOutOfStock: action,
    getCategories: action,
    getOrdersByCategory: action,
    removeFromSite: action,
    setAlternative: action
})

export default StoreCatalog;