import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';

import OrdersRow from '../components/tables/orders-row';

const AdminOrders = inject("stores") (
    observer (
        class AdminOrders extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
                
                this.state = {
                    orders: [],
                    title: ''
                }

                this.filters = [
                    {
                        filter: 'all',
                        display_value: 'Alle orders',
                        title: 'Alle bestellingen'
                    },{
                        filter: 'open',
                        display_value: 'Open orders',
                        title: 'Open orders'
                    },{
                        filter: 'paid',
                        display_value: 'Betaald',
                        title: 'Betaalde bestellingen'
                    },{
                        filter: 'banktransfer',
                        display_value: 'Overschrijving',
                        title: 'Bestellingen die per bankoverschrijving betaald worden'
                    },{
                        filter: 'onaccount',
                        display_value: 'Debiteuren',
                        title: 'Bestellingen op rekening'
                    },{
                        filter: 'afterdelivery',
                        display_value: 'Naleveringen',
                        title: 'Naleveringen'
                    },{
                        filter: 'fulfilled',
                        display_value: 'Verzonden',
                        title: 'Verzonden bestellingen'
                    }
                ]
            }

            componentDidMount = async() => {
                const orders = await this.storeOrders.getAllOrders();

                this.setState({
                    orders: orders,
                    filter: this.filters[0].filter,
                    title: this.filters[0].title
                })
            }

            filterOrders = async(filter) => {
                const orders = await this.storeOrders.getAllOrders();
                
                let orders_filtered = orders;

                switch(filter.filter) {
                    case 'open':
                        orders_filtered = this.storeOrders.orders
                            .filter(order => order.order_paid === null && order.order_banktransfer === null && order.order_fulfilled === null)
                        break;
                    case 'paid':
                        orders_filtered = this.storeOrders.orders
                            .filter(order => order.order_paid !== null && order.order_fulfilled === null)
                        break;
                    case 'afterdelivery':
                        let orders_after_delivery = await this.storeOrders.getAfterDeliveryOrders();
                        orders_filtered = this.storeOrders.orders
                            .filter(order => orders_after_delivery.indexOf(order.order_id) !== -1)
                        break;
                    case 'onaccount':
                        orders_filtered = this.storeOrders.orders
                            .filter(order => (order.order_paid === null && order.order_fulfilled !== null) || (order.order_paid === null && order.order_onaccount !== null))
                        break;
                    case 'banktransfer':
                        orders_filtered = this.storeOrders.orders
                            .filter(order => order.order_paid == null && order.order_banktransfer !== null && order.order_fulfilled === null)
                        break;
                    case 'fulfilled':
                        orders_filtered = this.storeOrders.orders
                            .filter(order => order.order_fulfilled !== null)
                        break;
                    default:
                        orders_filtered = this.storeOrders.orders;
                }

                this.setState({
                    orders: orders_filtered,
                    filter: filter.filter,
                    title: filter.title
                })
            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                        section = 'shop'
                        feature = 'orders'
                        title = 'Bestellingen'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Beheer orders</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>{this.state.title}</h2>
                                    <div className="content--section__form-actions">
                                        <span>Filter:</span>
                                        {this.filters.map((filter) => (
                                            <button className={"btn btn--secundary" + (this.state.filter === filter.filter ? ' --selected' : '')} onClick={() => this.filterOrders(filter)}>{filter.display_value}</button>
                                        ))}
                                    </div>
                                </div>
                                <div className="content--section__columns">
                                    <table className="table table--orders-overview">
                                    <thead>
                                        <tr>
                                            <th>Ordernummer</th>
                                            <th>Klant</th>
                                            <th className="table--orders-overview__cell-summary">Order</th>
                                            <th>Status</th>
                                            <th>Opmerking</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.orders && this.state.orders.length > 0 && this.state.orders.map((order) => ( 
                                        <OrdersRow 
                                            order = {order}
                                            orderr= {order.order_id}
                                            order_fulfilled = {order.order_fulfilled}
                                            order_onaccount = {order.order_onaccount}
                                            order_paid = {order.order_paid}
                                            order_banktransfer = {order.order_banktransfer}
                                            order_cb = {order.order_cb}
                                        />
                                    ))}
                                    {this.state.orders && this.state.orders.length == 0 &&
                                        <tr>
                                            <td colspan="5" className="table--orders-overview__cell-feedback">Er zijn geen bestellingen gevonden</td>
                                        </tr>
                                    }
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminOrders));
