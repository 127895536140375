import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import TextInput from '../../elements/form-input-text';
import FormNewsletterSelect from '../../components/forms/form-newsletter-select';
import FormNewsletterTags from '../../components/forms/form-newsletter-tags';
import Card from '../../components/documentation/card';

const NewsletterCreate = inject("stores") (
    observer (
        class NewsletterCreate extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeDocs = this.props.stores.storeDocs;
            }

            componentDidMount = async() => {
                
            }

            render() {
                return (                  
                    <div className="content--section">
                        <div className="content--section__header">
                            <h2>Maak een nieuwe nieuwsbrief</h2>
                        </div>
                        <div className="content--section__columns">
                            <div className="content--section__column">
                                <h3>Zet nieuwe nieuwsbrief klaar</h3>
                                <ol className="list--ordered">
                                    <li>Kopieer nieuwsbrief-files naar nieuwe directory op server.<br/>
                                        <div className="content--code">C:/Documents/websites/succesboeken.nl react/api/mailings/..</div>
                                    </li>    
                                    <li>
                                        Kopieer covers van nieuwe boeken naar server<br/>
                                        <div className="content--code">C:/Documents/websites/succesboeken.nl react/api/public/images/CoversVK/</div>
                                        <div className="content--code">C:/Documents/websites/succesboeken.nl react/api/public/images/CoversVKg/</div>
                                        <div className="content--code">C:/Documents/websites/succesboeken.nl react/api/public/images/CoversVKg-all/</div>
                                    </li>
                                    <li>
                                        Kopieer eventueel previews van nieuwe boeken naar server<br/>
                                        <div className="content--code">C:/Documents/websites/succesboeken.nl react/api/public/preview-pdf/</div>
                                    </li>
                                </ol>
                            </div>
                            
                            <div className="content--section__column">
                                <h3>Maak nieuwsbrief in html</h3>
                            </div>
                            
                            <div className="content--section__column">
                                <h3>Update TopMedia in database</h3>
                                <div>
                                    Voeg boeken toe in tabel TopMedia.
                                </div>
                                <p>Dit geeft de ISBN-nummers en volgorde weer van de boeken die bij een nieuwsbrief horen.</p>
                                    {/* /*S SBC TODO */}
                                {/*<span className="--error">TO DO lijstje om ISBN en volgorde in te vullen</span> */}
                            </div>
                        </div>
                    </div>   
                )
            }
        }
    )
)

export default withTranslation()(withRouter(NewsletterCreate));
