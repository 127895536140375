import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import moment from "moment";
import * as sort from '../../utilities/sort';
import Loader from '../../elements/loader';

import CellCheckMissingPng from './cell-check-missing-png';
import CellCheckMissingPngBack from './cell-check-missing-png-back';
import CellCheckMissingPdf from './cell-check-missing-pdf';

const CBResultDailyLine = inject("stores") (
    observer (
        class CBResultDailyLine extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeCb = this.props.stores.storeCb;

                this.state = {
                    expanded: false,
                    view: 5
                }
            }

            componentDidMount = () => {
            }

            componentDidUpdate = (prevProps) => {

            }

            handleToggle = () => {
                this.setState({
                    expanded: !this.state.expanded
                })
            }

            render() {
                const arrDay = this.storeCb.files_daily
                    .filter((file) => file && (file.date == this.props.day));

                const count = arrDay.length;

                let status = 'ok';
                if (count > 0) { 
                    status = 'ok';
                    arrDay.forEach((file) => {return file.status !== 'ok' ? status = 'todo' : status = status})
                } else {
                    status = 'no files';
                }

                return (
                    <React.Fragment>
                        <tr className="table--reports__row--header">
                            <td colSpan={9}>
                                {count > 0
                                    ?   <span onClick={() => this.handleToggle()} className="--link">{this.props.day} {this.state.expanded ? 'Verberg files' : 'Toon files'}</span>
                                    :   <span className="">{this.props.day}</span>
                                }
                            </td>
                        </tr>
                        {this.state.expanded &&
                             <React.Fragment>                           
                                {arrDay && arrDay.map((file) => (
                                    <CBResultDailyFile
                                        filename = {file.filename}
                                        date = {file.date}
                                        data = {file}
                                        loading_file = {this.storeCb.loading_file_daily}
                                        startCbDaily = {this.props.startCbDaily}
                                        startResourcesDaily = {this.props.startresourcesDaily}
                                        items_added = {file.items_added}
                                        items_changed = {file.items_changed}
                                        items_covers = {file.items_covers}
                                        items_backcovers = {file.items_backcovers}
                                        items_pdfs = {file.items_pdfs}
                                        status = {file.status}
                                    />
                                ))}
                         </React.Fragment> 
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

const CBResultDailyFile = inject("stores") (
    observer (
        class CBReportDailyFile extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeCb = this.props.stores.storeCb;

                this.state = {
                    loading: false,
                    status: this.props.data.status,
                    filename: '',
                    items_added:  0,
                    items_changed: 0,
                    items_covers:  0,
                    items_backcovers:  0,
                    items_pdfs: 0
                }
            }

            componentDidMount = () => {
                this.setState({
                    items_added: this.props.items_added,
                    items_changed: this.props.items_changed,
                    items_covers: this.props.items_covers,
                    items_backcovers: this.props.items_backcovers,
                    items_pdfs: this.props.items_pdfs,
                    status: this.props.status
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.loading_file !== this.props.loading_file) {
                    let status = '';
                    const idx = this.storeCb.files_daily
                        .findIndex((file) => file && file.filename === this.props.filename);
                    status = this.storeCb.files_daily[idx].status;
                    this.setState({
                        loading: this.props.filename == this.props.loading_file ? true : false,
                        status: status,
                        items_added: this.storeCb.files_daily[idx].items_added,
                        items_changed: this.storeCb.files_daily[idx].items_changed,
                        items_covers: this.storeCb.files_daily[idx].items_covers,
                        items_pdfs: this.storeCb.files_daily[idx].items_pdfs
                    })
                }

                if (prevProps.items_added !== this.props.items_added) {
                    this.setState({
                        items_added: this.props.items_added
                    })
                }

                if (prevProps.items_changed !== this.props.items_changed) {
                    this.setState({
                        items_changed: this.props.items_changed
                    })
                }

                if (prevProps.items_covers !== this.props.items_covers) {
                    this.setState({
                        items_covers: this.props.items_covers
                    })
                }

                if (prevProps.items_backcovers !== this.props.items_backcovers) {
                    this.setState({
                        items_backcovers: this.props.items_backcovers
                    })
                }

                if (prevProps.items_pdfs !== this.props.items_pdfs) {
                    this.setState({
                        items_pdfs: this.props.items_pdfs
                    })
                }

                if (prevProps.status !== this.props.status) {
                    this.setState({
                        status: this.props.status
                    })
                }
            }

            render() {
                return (
                    <React.Fragment>
                        <tr className="table--reports__row--data">
                            <td>{this.props.filename}</td>
                            <td>{this.state.items_added}</td>
                            <td>{this.state.items_changed}</td>
                            <td>{this.state.items_covers}</td>
                            <td>
                                <CellCheckMissingPng 
                                    date = {this.props.date}
                                    filename = {this.props.filename}
                                />
                            </td>
                            <td>{this.state.items_backcovers}</td>
                            <td>
                                <CellCheckMissingPngBack
                                    date = {this.props.date}
                                    filename = {this.props.filename}
                                />
                            </td>
                            <td>{this.state.items_pdfs}</td>
                            <td>
                                <CellCheckMissingPdf 
                                    date = {this.props.date}
                                    filename = {this.props.filename}
                                />
                            </td>
                        </tr>
                    </React.Fragment>
                )
            }
        }
    )
)

const CBUpdatesDaily = inject("stores") (
    observer (
        class CBUpdatesDaily extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_CUSTOM_NODE_ENV;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeCb = this.props.stores.storeCb;

                this.state = {
                    view: 5
                }
            }

            componentDidMount = async() => {
                const status = await this.storeCb.getInitReports('DAILY');
            }

            clearUpdates = async() => {
                const cleared = await this.storeCb.clearUpdates();
            }

            // createDates = () => {
            //     let day;
            //     let month;
            //     let year;
            //     let date;
            //     Array.from(Array(10), (e, i) => {
            //         date = moment
            //         day = moment().format(DD);  
            //         month = moment().format(MM);
            //         year = moment().format(YYYY);
            //         str_date = year + month + day;
            //         return str_date;
            //     })
            // }

            // getListFiles = async() => {
            //     const list = await this.storeCb.listFilesDaily();
            //     console.log('SbC list daily', list)
            // }

            getUpdateReports = async() => {
                const status = await this.storeCb.getInitReports('DAILY');
            }

            startCbDaily = async(filename, date) => {
                let loaded;
                this.storeCb.clearFeedback();

                if (filename == 'all') {
                    for (let file of this.storeCb.files_daily) {
                        if (file.status !== 'ok') {
                            loaded = await this.storeCb.initCatalog('INIT_DAILY', file.filename, file.date);
                        }
                    }
                }
                if (filename == 'day') {
                    for (let file of this.storeCb.files_daily) {
                        if (file.date ===  date) {
                            loaded = await this.storeCb.initCatalog('INIT_DAILY', file.filename, file.date);
                        }
                    }
                } 
                if  (filename !== 'all' && filename !== 'day') {
                    loaded = await this.storeCb.initCatalog('INIT_DAILY', filename, date);
                }
            }

            startResourcesDaily = async(filename, date) => {
                
            }

            toggleView = (days) => {
                this.setState({
                    view: this.state.view === days ? 5 : days
                })
            }

            render() {
                const arrDates = this.storeCb.dates.sort((a,b) => b - a);

                return (
                    <div className="content--section">
                        <div className="content--section__header">
                            <h2>Dagelijkse updates</h2>
                            <span onClick={() => this.toggleView(20)} className="--link">
                                Toon laatste 20 dagen
                            </span>
                            <span>&nbsp;-&nbsp;</span>
                            <span onClick={() => this.toggleView(50)} className="--link">
                                Toon laatste 50 dagen
                            </span>
                            <span>&nbsp;-&nbsp;</span>
                            <span onClick={() => this.toggleView(100)} className="--link">
                                Toon laatste 100 dagen
                            </span>
                            <span>&nbsp;-&nbsp;</span>
                            <span onClick={() => this.toggleView(999)} className="--link">
                                Toon alle dagen
                            </span>
                        </div>
                    
                        <div className="content--section__columns">
                            <table className="table--reports">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th colSpan={2}>ISBN</th>
                                    <th colSpan={2}>Covers</th>
                                    <th colSpan={2}>BackCovers</th>
                                    <th colSpan={2}>Pdf</th>
                                </tr>
                                <tr>
                                    <th>Datum</th>
                                    <th>Toegevoegd</th>
                                    <th>Gewijzigd</th>
                                    <th>Verwijzingen</th>
                                    <th>Ontbrekend in VKg-all</th>
                                    <th>Verwijzingen</th>
                                    <th>Ontbrekend in AK</th>
                                    <th>Verwijzingen</th>
                                    <th>Ontbrekend in preview-pdf-all</th>
                                </tr>
                            </thead>
                            <tbody>
                                {arrDates.map((day, index) => ( 
                                    index < this.state.view && 
                                        <CBResultDailyLine
                                            day = {day}
                                            view = {this.state.view}
                                            startCbDaily = {this.startCbDaily}
                                        >
                                           
                                        </CBResultDailyLine>
                                ))}
                            </tbody>
                            </table>
                        </div>
                        
                        <div className="wrapper--content__columns">
                            <div className="content--section__actions">
                                <span className="btn btn--secundary" onClick={() => this.getUpdateReports()}>Haal update reports op</span>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(CBUpdatesDaily);
