import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const NewsletterLinksUrls = inject("stores") (
    observer (
        class NewsletterLinksUrls extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeAnalytics = this.props.stores.storeAnalytics;
                
                this.state = {
                    links: []                     
                }

                this.arrLinks = ['url', 'counter'];
            }

            componentDidMount = async() => {
                const arrLinks = await this.storeAnalytics.getCountNewsletterUrls();

                this.setState({
                    links: arrLinks
                })
            }

            componentDidUpdate = async() => {

            }

            render() {
                return (     
                    this.state.links.map((link) => (              
                        <div className="content--section__table-row">
                            <div className="table--counters__column table--counters__column--text">
                                {link.tag}
                            </div>
                            <div className="table--counters__column table--counters__column--text">
                                <a href={link.url} target="_blank">{link.url}</a>
                            </div>
                            <div className="table--counters__column">
                                {link.count}
                            </div>                     
                        </div>
                    ))
                )
            }
        }
    )
)

export default withTranslation()(withRouter(NewsletterLinksUrls));
