import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import BlockPromobooks from '../components/content/block-promobooks';

const AdminContentBooks = inject("stores") (
    observer (
        class AdminContentBooks extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = async() => {

            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'content'
                        feature = 'promobooks'
                        title = 'Promo boeken op homepage'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Boeken in content</h1>
                            </div>

                            <div className="content--section__columns">
                                <div className="content--section__column">
                                    <div className="content--section">
                                        <div className="content--section__header">
                                            <h2>Promo boeken op startpagina</h2>
                                        </div>

                                        <BlockPromobooks

                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminContentBooks));
