import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as partners from '../communicator/partners';
import * as reports from '../communicator/reports';
import * as orders from '../communicator/orders';

class StoreReports {
    list_categories = [];

    constructor() {
    }

    async getCategories(shop) {
        let listCategories;

        try {
            const returnData = await reports.getCategories(shop);
            runInAction(() => {
                listCategories = returnData.data;
                return listCategories;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return listCategories;
    }

    async getOrdersByCategory(year, category, shop) {
        let list_orders_by_month;
        try {
            const returnData = await reports.getOrdersByCategory(year, category, shop);
            runInAction(() => {
                list_orders_by_month = returnData.data;
                return list_orders_by_month;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return list_orders_by_month;
    }

    async getOrdersByMonth(year, month) {
        let list_orders_by_month;
        try {
            const returnData = await reports.getOrdersByMonth(year, month);
            runInAction(() => {
                list_orders_by_month = returnData.data;
                return list_orders_by_month;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return list_orders_by_month;
    }

    async getOrdersByShop(year, month, shop) {
        let list_orders_by_month;
        try {
            const returnData = await reports.getOrdersByShop(year, month, shop);
            runInAction(() => {
                list_orders_by_month = returnData.data;
                return list_orders_by_month;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return list_orders_by_month;
    }

    async getOrdersBySku(sku) {
        let listOrders;
        try {
            const returnData = await orders.getOrdersBySku(sku);
            runInAction(() => {
                listOrders = returnData.data;
                return listOrders;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return listOrders;
    }

}

decorate(StoreReports, {
    list_categories: observable,
    getCategories: action,
    getOrdersByCategory: action
})

export default StoreReports;