import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as notifications from '../communicator/notifications';

class StoreNotifications {
    form_feedback = '';
    list_notifications = [];

    constructor() {
        
    }

    async getNotifications() {
        let list_notifications;
        try {
            const returnData = await notifications.getNotifications();
            runInAction(() => {
                list_notifications = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async updateNotifications(formControls) {
        let list_notifications;
        try {
            const returnData = await notifications.updateNotifications(formControls);
            runInAction(() => {
                list_notifications = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }
}

decorate(StoreNotifications, {
    form_feedback: observable,
    list_notifications: observable,
    getNotifications: action,
    updateNotifications: action
})

export default StoreNotifications;