import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Link } from 'react-router-dom';

import CellOrderAddress from './cell-order-address';
import CellOrderStatus from './cell-order-status';

const OrderRowProcess = inject("stores") (
    observer (
        class OrderRowProcess extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
            }

            componentDidMount = async() => {
                console.log('SbC props process::', this.props)
            }

            componentDidUpdate = async(prevProps) => {

            }

            render() {
                return (       
                    <React.Fragment>      
                    <tr>
                        <td className="table--orders-overview__cell-header">
                            Proces
                        </td>
                        <td>
                            <span className="table--orders__cell-label">Besteld</span>
                            <span className="table--orders__cell-value">{this.props.order.order_date && moment(this.props.order.order_date.date).format(this.storeUi.format_date)}</span>
                            <span className="table--orders__cell-label">Betaald</span>
                            {this.props.order.order_paid !== null 
                            ?   <span className="table--orders__cell-value">{this.props.order.order_paid && moment(this.props.order.order_paid.date).format(this.storeUi.format_date)}</span>
                            :   <span className="table--orders__cell-value">-</span>
                            }
                            <span className="table--orders__cell-label">Bankoverschrijving</span>
                            {this.props.order.order_banktransfer !== null
                            ?   <span className="table--orders__cell-value">{this.props.order.order_banktransfer && moment(this.props.order.order_banktransfer.date).format(this.storeUi.format_date)}</span>
                            :   <span className="table--orders__cell-value">-</span>
                            }
                            <span className="table--orders__cell-label">Besteld CB</span>
                            {this.props.order.order_cb !== null
                            ?   <span className="table--orders__cell-value">{this.props.order.order_cb && moment(this.props.order.order_cb.date).format(this.storeUi.format_date)}</span>
                            :   <span className="table--orders__cell-value">-</span>
                            }
                            <span className="table--orders__cell-label">Afgehandeld</span>
                            {this.props.order.order_fulfilled !== null
                            ?   <span className="table--orders__cell-value">{this.props.order.order_fulfilled &&moment(this.props.order.order_fulfilled.date).format(this.storeUi.format_date)}</span>
                            :   <span className="table--orders__cell-value">-</span>
                            }
                        </td>
                    </tr>
                    </React.Fragment> 
                )
            }
        }
    )
)

export default withTranslation()(withRouter(OrderRowProcess));
