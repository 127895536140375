import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import TextInput from '../../elements/form-input-text';

const BlockDownload = inject("stores") (
    observer (
        class BlockDownload extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeEmail = this.props.stores.storeEmail;
                
                this.state = {
                    formControls: {
                        firstname: {
                            label: i18n.t("forms.label.firstname"),
                            value: ''
                        },
                        lastname: {
                            label: i18n.t("forms.label.lastname"),
                            value: ''
                        },
                        email: {
                            label: i18n.t("forms.label.email"),
                            value: ''
                        }
                    },
                    email_sent: false
                }
            }

            componentDidMount = () => {
            }

            handleChange = (event) => {     
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls,
                    email_sent: false
                })
            }

            submitData = () => {
                this.storeEmail.sendLinkDownload(this.props.link, this.state.formControls);
                this.setState({
                    email_sent: true
                })
            }

            render() {
                return (                  
                    <React.Fragment>
                        <div className="wrapper--content__header">
                            <h1>E-mail mp3-download "{this.props.link}"</h1>
                            {this.props.link == 'placebo' &&
                                <span className="subtitle">CD</span>
                            }
                            {this.props.link == 'doorbreek' &&
                                <span className="subtitle">Dubbel-CD</span>
                            }
                        </div>

                        <div className="wrapper--content__columns">
                            <div className="content-column">
                                <ul className="block block-edit__list">
                                    {Object.keys(this.state.formControls).map((key) => (
                                        <li className="block block-edit__listitem">
                                            <span className="block block-edit__listitem--label">{this.state.formControls[key].label}</span>
                                            <span className="block block-edit__listitem--field">
                                                <TextInput 
                                                    name = {key} 
                                                    value = {this.state.formControls[key].value}
                                                    onChange = {(e) => this.handleChange(e)}
                                                    touched = {this.state.formControls[key].touched}
                                                />
                                            </span>       
                                        </li>
                                    ))}
                                </ul>
                                {Object.keys(this.storeEmail.form_feedback).length > 0 && 
                                    <div className="form__feedback">
                                        {this.state.email_sent && 'success' in this.storeEmail.form_feedback && 
                                            <React.Fragment>
                                                <span className="form__feedback--success">De e-mail is verstuurd</span>
                                            </React.Fragment>
                                        }
                                    </div>
                                }
                                <div className="content--section__form-actions">
                                    <span className="btn btn--primary" onClick={(e) => this.submitData('placebo')}>Verstuur e-mail {this.props.link}-meditaties Joe Dispenza</span>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockDownload));
