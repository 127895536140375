import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const CellCheckMissingPng = inject("stores") (
    observer (
        class CellCheckMissingPng extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeCb = this.props.stores.storeCb;
                
                this.state = {
                }
            }

            componentDidMount = async() => {
                this.checkResources()
            }

            checkResources = async() => {
                const update = 'DAILY';
                const filename = this.props.filename;
                const date = this.props.date;
                let amount = 0;
                let skus = [];
                skus = await this.storeCb.checkRecourcesCBMissing('COVERS', update, filename, date);
                amount = skus.length;

                this.setState({
                    amount_covers: amount
                })
            }

            getResources = async() => {
                const update = 'DAILY';
                const filename = this.props.filename;
                const date = this.props.date;
                let skus;
                skus = await this.storeCb.downloadResourcesCBMissing('COVERS', update, filename, date);
                skus = await this.storeCb.checkRecourcesCBMissing('COVERS', update, filename, date);
                const amount = skus.length;

                this.setState({
                    amount_covers: amount
                })
            }

            render() {
                return (                  
                    <div className="content--section__actions">
                        <span>{this.state.amount_covers}</span>
                        <button className="btn btn--secundary" onClick={() => this.checkResources()}>Controleer</button>
                        {this.state.amount_covers > 0 &&
                            <button className="btn btn--primary" onClick={() => this.getResources()}>Download</button>
                        }
                    </div>                    
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CellCheckMissingPng));
