import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const ReportSalesShopTotals = inject("stores") (
    observer (
        class ReportSalesShopTotals extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storeReports = this.props.stores.storeReports;
                this.state = {
                    list_totals: []
                }
            }

            componentDidMount = async() => {
                let year = this.props.year;
                let month = this.props.month;

                year = 2024;
                const list_totals = await this.storeReports.getOrdersByMonth(year, month);

                this.setState({
                    list_totals: list_totals
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.year !== this.props.year) {
                    let year = this.props.year;
                    let month = this.props.month;

                    const list_totals = await this.storeReports.getOrdersByMonth(year, month);

                    this.setState({
                        list_totals: list_totals
                    })
                }
            }

            render() {
                return (  
                    <td>
                        <span 
                            className = "table--sales__counters"
                        >
                            <span
                                className = "table--sales__counter"
                            >
                                {this.state.list_totals.nrOrders}
                            </span>
                            <span
                                className = "table--sales__counter"
                            >
                                {this.state.list_totals.nrBooks}
                            </span>
                        </span>
                    </td>
                )
            }
        }
    )
)

const ReportSalesShopRow = inject("stores") (
    observer (
        class ReportSalesShopRow extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storeReports = this.props.stores.storeReports;

                this.state = {
                    list_shop: []
                }
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.has_totals !== this.props.has_totals || prevProps.year !== this.props.year) {
                    let year = this.props.year;
                    let month;
                    let totals = [];
                    let list_shop = [];
                    let shop;

                    if (this.props.shop === 'SHOP_SB') {
                        shop = 1;
                    } else {
                        shop = 2;
                    }

                    [...Array(12)].map(async(e, i) => {
                        year = 2024;
                        month = i + 1;
                        list_shop[i] = await this.storeReports.getOrdersByShop(year, month, shop);

                        
                        if (i == 11) {
                            [...Array(12)].map(async(e, ii) => {
                                totals.push(list_shop[ii])
                            });
                            this.setState({
                                list_shop: totals,
                                has_totals: true
                            })
                        }
                    })               
                }
            }

            render() {
                return (  
                    <tr>
                        <td>{this.props.shop}</td>
                        {[...Array(12)].map((book, i) => 
                            <td>
                                <span 
                                    className={this.state.list_shop && this.state.list_shop[i] && this.state.list_shop[i].nrBooks == 0 ? '--inactive': ''}
                                >
                                    {this.state.list_shop && this.state.list_shop[i] && this.state.list_shop[i].nrBooks}
                                </span>
                            </td>
                        )}
                    </tr>
                )
            }
        }
    )
)


const ReportSalesShop = inject("stores") (
    observer (
        class ReportSalesShop extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeReports = this.props.stores.storeReports;

                this.state = {
                    totals: [],
                    has_totals: false
                }
            }

            componentDidMount = async() => {
                this.createYears();
                this.getOrdersByMonth();
            }

            getOrdersByMonth = async() => {
                let year = this.state.year;
                let month;
                let totals = [];
                let list_totals = [];

                [...Array(12)].map(async(e, i) => {
                    month = i + 1;
                    list_totals[i] = await this.storeReports.getOrdersByMonth(year, month);
                    
                    if (i == 11) {
                        [...Array(12)].map(async(e, ii) => {
                            totals.push(list_totals[ii])
                        });
                        this.setState({
                            totals: totals,
                            has_totals: true
                        }, () => console.log('SbC totals::', totals))
                    }
                })
            }

            createYears = () => {
                const startYear = 2020;
                const currentYear = new Date().getFullYear(); 
                let years = [];

                let i = startYear;
                while (i <= currentYear) {
                    years.push(i);
                    i++;
                }

                this.setState({
                    year: currentYear,
                    years: years
                })
            }

            selectYear = (year) => {
                this.setState({
                    year: year
                })
            }

            render() {
                return (                  
                    <div className="content--section">
                        <div className="content--section__header">
                            <h2>Maandelijkse verkopen per shop in {this.state.year}</h2>
                            <div class="content--section__form-actions">
                                {this.state.years && this.state.years.map(year => (
                                    <button 
                                        className={"btn btn--secundary" + (year === this.state.year ? " --selected" : "")}
                                        onClick={() => this.selectYear(year)}
                                    >
                                        {year}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="content--section__columns">
                            <table className="table table--sales">
                            <thead>
                                <tr>
                                    <th>Shop</th>
                                    <th>Jan</th>
                                    <th>Feb</th>
                                    <th>Maa</th>
                                    <th>Apr</th>
                                    <th>Mei</th>
                                    <th>Jun</th>
                                    <th>Jul</th>
                                    <th>Aug</th>
                                    <th>Sep</th>
                                    <th>Okt</th>
                                    <th>Nov</th>
                                    <th>Dec</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    {[...Array(12)].map((e, i) => 
                                        <ReportSalesShopTotals
                                            year = {this.state.year}
                                            month = {i + 1}
                                        />
                                    )}
                                </tr>
                                <ReportSalesShopRow
                                    shop = 'SHOP_SB'
                                    year = {this.state.year}
                                    has_totals = {this.state.has_totals}
                                />
                                 <ReportSalesShopRow
                                    shop = 'SHOP_ALL'
                                    year = {this.state.year}
                                    has_totals = {this.state.has_totals}
                                />
                            </tbody>
                            </table>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(ReportSalesShop));
