import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';

const AdminToplists = inject("stores") (
    observer (
        class AdminToplists extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;

                this.storeUi = this.props.stores.storeUi;
                this.storeEBooks = this.props.stores.storeEBooks;
                
                this.state = {
                    ebooks: [],
                    activeId: 0
                }
            }

            componentDidMount = async() => {

            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'content'
                        feature = 'top60'
                        title = 'Bestsellerlijsten'
                    >   
                        <div className="wrapper--content__header">
                            <h1>Toplists</h1>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Update Top 60 en Top 10's vanuit excel-lijsten</h2>
                                <p>Lijsten worden wekelijks op woensdag aangeleverd.</p>
                            </div>

                            <div className="content--section__columns">
                                <div className="content--section__column">
                                    <h4>1. Hernoem data files</h4>
                                    <p>Hernoem file naar "jaar weeknr originele naam", bijv "202305 Genres..."</p>
                                    <h4>2. Verplaats data files</h4>
                                    <p>Verplaats de files naar:</p>
                                    <ul>
                                        <li>/api/admin/toplists/data/top60</li>
                                        <li>/api/admin/toplists/data/top10</li>
                                    </ul>
                                    <h4>3. Update database</h4>
                                    <p>Update de database tabel met nieuwe data dmv onderstaande urls:</p>
                                    <ul>
                                        <li>https://www.succesboeken.nl/api/v2/admin/toplists/top60/update</li>
                                        <li>https://www.succesboeken.nl/api/v2/admin/toplists/top10s/update</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Beheer Top 10's</h2>
                            </div>

                            <div className="content--section__columns">
                                
                            </div>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Beheer boeken custom Top 10's</h2>
                            </div>

                            <div className="content--section__columns">
                                
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminToplists));
