import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

import Card from './card';

const BusinessRules = inject("stores") (
    observer (
        class BusinessRules extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeDocs = this.props.stores.storeDocs;
            }

            componentDidMount = async() => {
                const file = 'rules';
                const result = await this.storeDocs.getDocumentation(file);
            }

            render() {

                return (
                    <div className="container--card">
                        {this.storeDocs.docs_rules.map((rule) => (
                            <Card 
                                task = {rule}
                            />
                        ))}
                    </div>
                )
            }
        }
    )
)

export default withRouter(BusinessRules);
