import * as apifetch from './fetch.js';

export async function getSections() {
    const urlEndpoint = '/v2/admin/wiki/sections';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getFeatures(section) {
    const urlEndpoint = '/v2/admin/wiki/sections/' + section + '/features';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getFeature(feature) {
    const urlEndpoint = '/v2/admin/wiki/features/' + feature;

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getFeatureBlocks(feature) {
    const urlEndpoint = '/v2/admin/wiki/features/' + feature + '/blocks';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getFeatureProcedures(feature) {
    const urlEndpoint = '/v2/admin/wiki/features/' + feature + '/procedures';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getFeatureDevelopment(feature) {
    const urlEndpoint = '/v2/admin/wiki/features/' + feature + '/development';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getFeatureRemarks(feature) {
    const urlEndpoint = '/v2/admin/wiki/features/' + feature + '/remarks';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}