import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';

const ReportSalesSku = inject("stores") (
    observer (
        class ReportSalesSku extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.storeCatalog = this.props.stores.storeCatalog;
                this.storeReports = this.props.stores.storeReports;
                
                this.state = {
                    orders: [],
                    year: 2021
                }
            }

            componentDidMount = async() => {
                const sku = '9789492665713';
                const orders = await this.storeReports.getOrdersBySku(sku);

                console.log('SbC orders in page', orders)

                this.setState({
                   orders: orders
                })
            }

            selectYear = (year) => {
                this.setState({
                   
                })
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Overzicht verkopen per ISBN</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>{this.state.title}</h2>
                                    <div className="content--section__form-actions">
                                        ISBN is nu alleen nog: 9789492665713 (Blijf weg van mijn operatietafel)
                                    </div>
                                </div>
                                <div className="content--section__columns">
                                    <div className="content--section__table table--data">
                                        <div className="table--counters__row table--data__row--header">
                                            <div className="table--data__column ">Bestellingnr</div>
                                            <div className="table--data__column table--counters__column--date">Datum</div>
                                            <div className="table--data__column">Klant account</div>
                                            <div className="table--data__column">Klant email</div>
                                            <div className="table--data__column">Partnercode</div>
                                            <div className="table--data__column">Partner</div>
                                        </div>
                                        {this.state.orders && this.state.orders.map((item) => (
                                            <React.Fragment>
                                                {item[0] && item[0].orderId &&
                                                    <div className="content--section__table-row">
                                                        <div className="table--data__column">
                                                            {item[0] && item[0].orderId}
                                                        </div>

                                                        <div className="table--data__column">
                                                            {moment(item[0].dateOrder.date).format("DD-MM-YYYY")} 
                                                        </div>

                                                        <div className="table--data__column">
                                                            {item[0] && item[0].accountId}
                                                        </div>

                                                        <div className="table--data__column">
                                                            {item[0] && item[0].email}
                                                        </div>

                                                        <div className="table--data__column">
                                                            {item[0] && item[0].partnerId}
                                                        </div>

                                                        <div className="table--data__column">
                                                            {item[0] && item[0].name}
                                                        </div>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        ))} 
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(ReportSalesSku));
