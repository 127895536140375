import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import AdminEbook from './admin-ebook';

const AdminEBooks = inject("stores") (
    observer (
        class AdminEBooks extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;

                this.storeUi = this.props.stores.storeUi;
                this.storeEBooks = this.props.stores.storeEBooks;
                
                this.state = {
                    ebooks: [],
                    activeId: 0
                }
            }

            componentDidMount = async() => {
                const ebooks = await this.storeEBooks.getEBooks();
                this.setState({
                    ebooks: ebooks
                })
            }

            editEbook = (ebook) => {
                this.setState({
                    activeId: ebook.id
                })
            }

            createNew = () => {
                this.setState({
                    activeId: -1
                })
            }

            handleAdd = async() => {
                const ebooks = await this.storeEBooks.getEBooks();

                this.setState({
                    ebooks: ebooks,
                    activeId: 0
                })
            }

            handleDelete = async() => {
                const ebooks = await this.storeEBooks.getEBooks();

                this.setState({
                    ebooks: ebooks,
                    activeId: -1
                })
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'catalog'
                        feature = 'ebooks'
                        title = 'E-boeken'
                    >   
                        <div className="wrapper--content__header">
                            <h1>E-Boeken</h1>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Wijzig e-boek</h2>
                            </div>

                            <div className="content--section__columns">
                                <div className="content--section__column">
                                    <h4>Aanwezige e-boeken</h4>
                               
                                    <ul className="block block-edit__list list--clean">
                                        {this.state.ebooks && this.state.ebooks.map((ebook) => (
                                            <li 
                                                className = "block block-edit__listitem --link"
                                                onClick = {() => this.editEbook(ebook)}
                                            >
                                                <span>{ebook.title}</span>
                                            </li>
                                        ))}
                                    </ul>
                                    
                                    <div className="content--section__form-actions">
                                        <button className="btn btn--primary" onClick={(e) => this.createNew(e)}>Voeg nieuw e-boek toe</button>
                                    </div>
                                </div>

                                <div className="content--section__column">
                                    <h4>Gegevens e-boek</h4>
                                        <AdminEbook
                                            ebooks = {this.state.ebooks}
                                            activeId = {this.state.activeId}
                                            onDelete = {() => this.handleDelete()}
                                            onAdd = {() => this.handleAdd()}
                                        />
                                </div>
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminEBooks));
