import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import ReportOverviewPartnerChecksSummary from './report-overview-partner-checks-summary';

const ReportOverviewPartnerChecks = inject("stores") (
    observer (
        class ReportOverviewPartnerChecks extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storePartners = this.props.stores.storePartners;
                
                this.state = {
                    sortOrder: 'ASC',
                    partners: []
                }

                this.columns = [
                    {
                        'id': 1,
                        'name': '',
                        'displayValue': 'Account&nbsp;ID',
                        'bem': '',
                        'sortValue': 'AccountId'
                    },{
                        'id': 3,
                        'name': '',
                        'displayValue': 'Partner&nbsp;ID',
                        'bem': '',
                        'sortValue': 'PartnerId'
                    },{
                        'id': 3,
                        'name': '',
                        'displayValue': 'Naam',
                        'bem': '',
                        'sortValue': 'Lastname'
                    },{
                        'id': 4,
                        'name': '',
                        'displayValue': 'Bedrijfsnaam',
                        'bem': '',
                        'sortValue': 'Company'
                    },{
                        'id': 6,
                        'name': '',
                        'displayValue': 'Controledatum',
                        'bem': '',
                        'sortValue': 'DateChecked'
                    },{
                        'id': 5,
                        'name': '',
                        'displayValue': 'Error code',
                        'bem': '',
                        'sortValue': 'Checked'
                    },{
                        'id': 7,
                        'name': '',
                        'displayValue': 'Opmerking',
                        'bem': '',
                        'sortValue': 'Comment'
                    }]
            }

            componentDidMount = async() => {
                const partners = await this.storePartners.getReportPartnersOverview();

                this.setState({
                    partners: partners
                })
            }

            componentDidUpdate = async(prevProps) => {

            }

            handleSortColumn = async(id, sortValue) => {
                let partners;
                let sortOrder = this.state.sortOrder;
                if (this.state.sortOrder === 'ASC') {
                    sortOrder = 'DESC';
                } else {
                    sortOrder = 'ASC';
                }
                this.setState({
                    sortOrder: sortOrder
                })

                if (id === 1 || id === 3 || id === 4) {
                    partners = await this.storePartners.getReportPartnersOverview(sortValue, sortOrder);
                } 
                if (id === 2 || id === 5 || id === 6) {
                    partners = await this.storePartners.getReportPartnerChecksOverview(sortValue, sortOrder);
                }

                this.setState({
                    partners: partners
                })
            }

            render() {
                return (
                    <div className="content-section--report">
                        <div className="content-section--report__header">
                            <div className="content-section--report__columns content-section--report__columns--checks">
                                {this.columns.map((column) => (
                                    <div 
                                        className={"content-section--report__column" + (column.bem !== '' ? " content-section--report__column--" + column.bem : "") + " --link"}
                                        onClick = {() => this.handleSortColumn(column.id, column.sortValue)}
                                        dangerouslySetInnerHTML={{ __html: column.displayValue}}
                                    />
                                ))}
                            </div>
                        </div>
                        
                        {this.state.partners && this.state.partners.map((partner) => (
                            <ReportOverviewPartnerChecksSummary 
                                id = {partner.accountId}
                            />
                        ))}
                    </div>
                )
            }
        }
    )
)




export default withTranslation()(withRouter(ReportOverviewPartnerChecks));
