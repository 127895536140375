import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Loader from '../elements/loader';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';
import ReportOverviewPartners from '../components/partners/report-overview-partners';
import ReportOverviewPartners2 from '../components/partners/report-overview-partners2';

const AdminPartners = inject("stores") (
    observer (
        class AdminPartners extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storePartners = this.props.stores.storePartners;
                
                this.state = {
                    loading: false,
                    feedback: '',
                    formControls: {
                        
                    }
                }
            }

            componentDidMount = async() => {
            }

            recalcPartnerOverview = async() => {
                this.setState({
                    loading: true,
                    feedback: ''
                }, () => this.loadPartnerOverview())
            }

            loadPartnerOverview = async() => {
                const partners = await this.storePartners.initPartners();

                this.setState({
                    loading: false,
                    feedback: "Het overzicht is vernieuwd."
                })
            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                        section = 'shop'
                        feature = 'partners'
                        title = 'Partners'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Beheer partners</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Herberekening partneroverzicht</h2>
                                </div>

                                <div className="wrapper--content__columns">
                                    <div className="content--section__actions">
                                        <span className="btn btn--primary" onClick={(e) => this.recalcPartnerOverview()}>Start herberekening</span>
                                    </div>
                                </div>

                                {this.state.loading &&
                                    <div className="status">
                                        <Loader />
                                        <span>loading...</span>
                                    </div>
                                }

                                {this.state.feedback !== '' &&
                                    <div>
                                        {this.state.feedback}
                                    </div>
                                }
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Overzicht</h2>
                                    <ReportOverviewPartners 
                                    
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminPartners));
