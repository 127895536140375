import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

import Card from './card';

const Orders = inject("stores") (
    observer (
        class Orders extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeDocs = this.props.stores.storeDocs;
            }

            componentDidMount = async() => {
                const file = 'orders';
                const result = await this.storeDocs.getDocumentation(file);
            }

            render() {

                return (
                    <div className="container--card">
                        {this.storeDocs.docs_orders.map((task) => (
                            <Card 
                                task = {task}
                            />
                        ))}
                    </div>
                )
            }
        }
    )
)

export default withRouter(Orders);
