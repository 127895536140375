import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const CellOrderAddress = inject("stores") (
    observer (
        class CellOrderAddress extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                    order: {}
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
            }

            componentDidMount = async() => {
                console.log('SbC order', this.props.order)
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.nr !== this.props.nr) {
                    this.setState({
                        order: this.props.order
                    })
                }

                if (prevProps.order_id !== this.props.order_id) {
                    this.setState({
                        order: this.props.order
                    })
                }
            }

            render() {
                return (                  
                    <React.Fragment>
                        <div className="table--orders__cell-block">
                            {this.state.order.invoice_address === this.state.order.delivery_address &&
                                <span className="table--orders__cell-label">
                                    Factuur- & afleveradres
                                </span>
                            }
                            {this.state.order.invoice_address !== this.state.order.delivery_address &&
                                <span className="table--orders__cell-label">
                                    Factuuradres
                                </span>
                            }
                            <span className="table--orders__cell-value">{this.state.order.order_name}</span>
                            <span className="table--orders__cell-value">{this.state.order.invoice_address}</span>
                            <span className="table--orders__cell-value">{this.state.order.invoice_city}</span>
                            <span className="table--orders__cell-value">{this.state.order.invoice_country}</span>
                        </div>

                        {this.state.order.invoice_address !== this.state.order.delivery_address &&
                            <div className="table--orders__cell-block">
                                <span className="table--orders__cell-label table--orders__cell-label">
                                    Afleveradres
                                </span>
                                <span className="table--orders__cell-value">{this.state.order.delivery_name}</span>
                                <span className="table--orders__cell-value">{this.state.order.delivery_address}</span>
                                <span className="table--orders__cell-value">{this.state.order.delivery_city}</span>
                                <span className="table--orders__cell-value">{this.state.order.delivery_country}</span>
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CellOrderAddress));
