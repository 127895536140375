import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const ReportOverviewPartnerSalesRow = inject("stores") (
    observer (
        class ReportOverviewPartnerSalesRow extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storeUi = this.props.stores.storeUi;
                this.storePartners = this.props.stores.storePartners;
                
                this.state = {
                    partner: [],
                    exception: false,
                    partnerFee: {}
                }
            }

            componentDidMount = async() => {
                let partnerFee;
                let exception = false;

                const partnerFees = this.props.partnerFees;
                const bookId = this.props.sale.bookId;
         
                const idx = partnerFees
                    .findIndex((item) => parseInt(item.bookId) === parseInt(bookId));

                if (idx > -1) {
                    partnerFee = partnerFees[idx];
                    exception = true;
                }

                this.setState({
                    partner: this.props.partner,
                    partnerFee: partnerFee,
                    exception: exception
                })
            }

            componentDidUpdate = async(prevProps) => {
                let settleDate;
                let partnerFee;

                if (prevProps.id !== this.props.id) {
                    this.setState({
                        id: this.props.id,
                        partner: this.props.partner,
                        settleDate: this.props.settleDate
                    })
                } 
            }

            formatNumber = (price, decimals) => {
                const priceFormatted = parseFloat(price).toFixed(decimals);
                    
                return priceFormatted;
            }

            formatDate = (date) => {
                const dateFormatted = moment(date.date).format(this.storeUi.format_date_simple)

                return dateFormatted;
            }

            checkSettleDate = (date) => {
                let classOpen = '';

                if (this.props.settleDate !== '') {
                    if (moment(date.date).isAfter(moment(this.props.settleDate.date))) {
                        classOpen = ' --open';
                    }
                } else {
                    classOpen = ' --open';
                }

                return classOpen;
            }

            render() {
                const sale = this.props.sale;

                return (  
                    <div className={"content-section--report__columns content-section--report__columns--zebra" + this.checkSettleDate(sale.date)}>
                        <div className="content-section--report__column">
                            {this.formatDate(sale.date)}
                        </div>
                        <div className="content-section--report__column">
                            {sale.description}
                        </div>
                        <div className="content-section--report__column">
                            {sale.invoice}
                        </div>
                        <div className="content-section--report__column">
                            {parseInt(sale.bookId) > 200000 ? 2 : 1}
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {sale.amount}
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {this.storePartners.handleSale(this.props.partner, sale)}&nbsp;
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {parseInt(sale.commissionSB) === 1
                                ?   this.state.exception
                                    //SbC custom perc for exceptions, for example event tickets
                                    ?   <span>{this.state.partnerFee && this.state.partnerFee.percFee} %</span>
                                    :   (sale.commission_current !== '' && sale.commission_current !== null)
                                        ?   <span>{this.formatNumber(sale.commission_current, 0)} %</span>
                                        :   <span>{this.formatNumber(this.props.partner.commission, 0)} %</span>
                                :   (sale.commission_3party_current !== '' && sale.commission_3party_current !== null)
                                    ?   <span>{this.formatNumber(sale.commission_3party_current, 0)} %</span>
                                    :   <span>{this.formatNumber(this.props.partner.commission_3party, 0)} %</span>
                            }
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {this.storePartners.handleCommission(this.props.partner, sale, 2)}
                        </div>
                    </div>

                )
            }
        }
    )
)




export default withTranslation()(withRouter(ReportOverviewPartnerSalesRow));
