import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import CBReportDaily from '../../components/cb/cb-report-daily';
import CBReportInit from '../../components/cb/cb-report-init';
import CBReportOverview from '../../components/cb/cb-report-overview';

import Select from '../../elements/form-input-select';

const CBChecks = inject("stores") (
    observer (
        class CBChecks extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeCb = this.props.stores.storeCb;
                
                this.state = {
                    formControls: {
                        dir_select: {
                            label: 'Kies update directorie',
                            value: 1,
                            placeholder: 'Kies update directorie',
                            touched: false,
                            options: ['2001', '2002']
                        }
                    },
                    feedback: ''
                }
            }

            componentDidMount = async() => {
                const dirs = await this.storeCb.getDailyDirs();

                this.setState({
                    dirs: dirs
                })
            }

            checkUpdates = async() => {
                this.setState({
                    updates: [],
                    feedback: ''
                })

                const date = this.state.formControls.dir_select.value;
                const updates = await this.storeCb.checkUpdates2008(date);

                this.setState({
                    updates: updates, 
                    feedback: 'success'
                })
            }

            handleChange = (event, action) => {  
                const name = event.target.name;
                let value = event.target.value;
         
                let updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedControls[name] = updatedFormElement;

                this.setState({
                    formControls: updatedControls
                })
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Controle dagelijkse updates</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Overzicht dagelijkse wijzigingen in ONIX files</h2>
                                </div>

                                <div className="wrapper--content__columns">
                                    <div className="content-column">
                                        <h2>Kies dag</h2>

                                        {this.state.dirs &&
                                            <Select 
                                                name="dir_select"
                                                value = {this.state.formControls.dir_select.value}
                                                options = {this.state.dirs}
                                                touched = {this.state.formControls.dir_select.touched}
                                                onChange = {this.handleChange}
                                                valueSelected = {this.state.formControls.dir_select.value}
                                            />
                                        }

                                        {this.state.feedback !== '' &&
                                            <div>DONE</div>
                                        }
                                    
                                        <div className="content--section__actions">
                                            <button className="btn btn--primary" onClick={() => this.checkUpdates()}>Haal wijzigingen op</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Wijzigingen in Boekenlijst2008</h2>
                                </div>

                                <div className="wrapper--content__columns">
                                    <div className="content-column">
                                        <div className="content--section__columns">
                                            <table className="table table--reports">
                                            <thead>
                                                <tr>
                                                    <th>ISBN</th>
                                                    <th>Prijs (db)</th>
                                                    <th>Voorraad (db)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.updates && this.state.updates.map((update) => (
                                                    <tr>
                                                        <td>
                                                            {update.sku}<br/>
                                                            {update.title}
                                                        </td>
                                                        <td>
                                                            {update.price_incl}
                                                        </td>
                                                        <td>
                                                            {update.stock}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            
                            </div> 
                                                  
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CBChecks));
