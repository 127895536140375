import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Link } from 'react-router-dom';

import CellOrderAddress from './cell-order-address';
import CellOrderSummary from './cell-order-summary';
import CellOrderStatus from './cell-order-status';
import CellOrderDetails from './cell-order-details';

const OrderRowDetails = inject("stores") (
    observer (
        class OrderRowDetails extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                    items: []
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
            }

            componentDidMount = async() => {
                // const items = await this.storeOrders.getOrderItemsData(this.props.order.order_id);
                // this.setState({
                //     items: items
                // })
            }

            componentDidUpdate = async(prevProps) => {
                // if (prevProps.order_id !== this.props.order_id) {
                //     const items = await this.storeOrders.getOrderItemsData(this.props.order_id);
                //     this.setState({
                //         items: items
                //     })
                // }
            }

            render() {
                return (       
                    <React.Fragment>
                    <tr className="table--orders-overview__row-header">
                        <td className="table--orders-overview__cell-header">
                            Order
                        </td>
                        <td>
                            <CellOrderDetails
                                order_id = {this.props.order_id}
                                order = {this.props.order}
                            />
                        </td>
                        <td className="table--orders-overview__cell-summary">
                            <CellOrderSummary
                                view = {'extended'}
                                order_id = {this.props.order_id}
                                order_fulfilled = {this.props.order_fulfilled}
                                
                            />
                        </td>
                    </tr>
                    </React.Fragment> 
                )
            }
        }
    )
)

export default withTranslation()(withRouter(OrderRowDetails));
