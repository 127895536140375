import * as apifetch from './fetch.js';

export async function getCategories(shop) {
    const urlEndpoint = '/catalog/getCategories.php';

    const postData = {
        shop: shop
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getOrdersByCategory(year, category, shop) {
    const urlEndpoint = '/v2/admin/reports/sales';
    let id;

    if (shop === 'SHOP_SB') {
        id = 1;
    } else {
        id = 2;
    }

    const postData = {
        shop: id,
        year: year,
        category: category
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getOrdersByMonth(year, month) {
    const urlEndpoint = '/admin/reports/getOrdersByMonth.php';  

    const postData = {
        year: year,
        month: month
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getOrdersByShop(year, month, shop) {
    const urlEndpoint = '/admin/reports/getOrdersByShop.php';  

    const postData = {
        year: year,
        month: month,
        shop: shop
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

