import * as apifetch from './fetch.js';

export async function getEBooks() {
    const urlEndpoint = '/v2/catalog/ebooks/admin';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function deleteEBook(id) {
    const urlEndpoint = '/v2/admin/ebooks/' + id;

    return apifetch.sbFetch(urlEndpoint, 'DELETE');
}

export async function saveEBook(id, data) {
    let urlEndpoint;
    let requestMethod;
    const postData = data;

    if (id !== -1 && id !== 0) {
        urlEndpoint = '/v2/admin/ebooks/' + id;
        requestMethod = 'PUT';
    } else {
        console.log('SbC new ebook')
        urlEndpoint = '/v2/admin/ebooks';
        requestMethod = 'POST';
    }

    return apifetch.sbFetch(urlEndpoint, requestMethod, postData);
}