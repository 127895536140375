import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import TextInput from '../../elements/form-input-text';
import Counter from '../ui/counter';
import GraphBars from '../ui/graph-bars';

const AccountMutations = inject("stores") (
    observer (
        class AccountMutations extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeAnalytics = this.props.stores.storeAnalytics;
                
                this.state = {
                    mailings: [],
                    tag: '',
                    views: {
                        count_accounts: 0,
                        count_accounts_new: 0,
                        count_subscriptions: 0,
                        count_subscrubtions_added: 0,
                        count_subscrubtions_removed: 0
                    },
                    count_accounts: {
                        total: 0,
                        new_by_week: 0
                    }
                }
            }

            componentDidMount = async() => {
                let str = this.props.week.toString().slice(-2); 
                const count_accounts = await this.storeAnalytics.getAccountMutations(this.props.year, parseInt(str));
                this.setState({
                    week: this.props.week,
                    count_accounts: count_accounts
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.year !== this.props.year) {
                    let str = this.props.week.toString().slice(-2); 
                    const count_accounts = await this.storeAnalytics.getAccountMutations(this.props.year, parseInt(str));
                    this.setState({
                        week: this.props.week,
                        count_accounts: count_accounts
                    })
                }
            }

            convertToDate = (week) => {
                let str = week.toString();             
                let weeknr = str.slice(-2);
                let year =  "20" + str.substring(0,2);
                let date = moment().day("Monday").year(year).week(weeknr).format('DD-MM-YYYY');

                return date;
            }

            convertToWeek = (week) => {
                let str = week.toString();  
                let weeknr = str.slice(-2);
                return ('wk ' + weeknr);
            }

            render() {
                return (                  
                    <div className="content--section__table-row">
                        <div className="table--counters__column">
                            {this.convertToDate(this.props.week)} ({this.convertToWeek(this.props.week)})
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.count_accounts.total}
                                    title = {'accounts<br/>totaal'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.count_accounts.total
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.count_accounts.new_by_week}
                                    title = {'accounts<br/>nieuw'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.count_accounts.new_by_week
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = { this.state.count_accounts.total_subscribers}
                                    title = {'nieuwsbrief<br/>totaal'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.count_accounts.total_subscribers
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = { this.state.views.count_subscrubtions_added}
                                    title = {'aanmeldingen'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.views.count_subscrubtions_added
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = { this.state.views.count_subscrubtions_removed}
                                    title = {'afmeldingen'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.views.count_subscrubtions_removed
                            }
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AccountMutations));
