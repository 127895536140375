import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import Counter from '../components/ui/counter';

const SMS = inject("stores") (
    observer (
        class SMS extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeAnalytics = this.props.stores.storeAnalytics;
                
                this.state = {
                    formControls: {
                        
                    }
                }
            }

            componentDidMount = async() => {
                const balance = await this.storeAnalytics.checkBalanceSms();
            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                        section = 'analytics'
                        feature = 'spryng'
                        title = 'Spryng SMS'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>SMS</h1>
                            </div>
                            
                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Dashboard</h2>
                                </div>
                                <div className="content--section__columns">
                                    <div className="content--section__column">
                                        <p>Het versturen van SMS-berichten verloopt via Spryng.<br/>
                                        Momenteel wordt SMS alleen gebruikt als alternatief voor het opvragen van wachtwoord per e-mail.</p>
                                        <p>Het opwaarderen van het saldo kan via het dashboard van Spryng.</p>
                                        <p><a href="https://portal.spryngsms.com/" target="_blank">Direct naar Spryng SMS Dashboard</a></p>
                                    </div>
                                    <div className="content--section__column">
                                        <Counter
                                            value = {this.storeAnalytics.balance_sms}
                                            title = {'saldo'}
                                            value_alert = {20}
                                        />
                                    </div>

                                    <div className="content--section__column">

                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(SMS));
