import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as email from '../communicator/email';

class StoreEmail {
    form_feedback = '';
    admin_interface = { 
        isDialogFeedbackOpen: false
    }

    constructor() {
        
    }

    openDialogFeedback = () => {
        this.admin_interface.isDialogFeedbackOpen = true;
    }

    closeDialogFeedback = () => {
        this.admin_interface.isDialogFeedbackOpen = false;
    }

    clearFormFeedback() {
        this.form_feedback = '';
    }

    async sendConfirmPartner(formControls) {
        const data = {
            firstname: formControls.firstname.value,
            lastname: formControls.lastname.value,
            partner_code: formControls.partner_code.value,
            email: formControls.email.value,
        }
        try {
            const returnData = await email.sendConfirmPartner(data);
            runInAction(() => {
                console.log('SbC link partner sent!')
                this.form_feedback = returnData.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async sendLinkDownload(link, formControls) {
        const data = {
            firstname: formControls.firstname.value,
            lastname: formControls.lastname.value,
            email: formControls.email.value,
            link: link
        }
        try {
            const returnData = await email.sendLinkDownload(data);
            runInAction(() => {
                this.form_feedback = returnData.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async sendTestEmail(url) {
        const data = {
            url: url
        }
        try {
            const returnData = await email.sendTestEmail(data);
            runInAction(() => {
                this.form_feedback = returnData.msg.success;
                this.admin_interface.isDialogFeedbackOpen = true;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }
}

decorate(StoreEmail, {
    form_feedback: observable,
    admin_interface: observable,
    clearFormFeedback: action,
    closeDialogFeedback: action,
    openDialogFeedback: action,
    sendConfirmPartner: action,
    sendLinkDownload: action,
    sendTestEmail: action
})

export default StoreEmail;