import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";
import moment from "moment";

import * as analytics from '../communicator/analytics';

class StoreAnalytics {
    balance_sms = 0;

    views_newsletter = {
        tag: "",
        count_pdf_email: 0,
        count_php_email: 0,
        count_pdf_site: 0,
        count_php_site: 0
    }

    weeks = []

    delivery_newsletter = []

    constructor() {
    }

    async checkBalanceSms() {
        try {
            const returnData = await analytics.checkBalanceSms();
            runInAction(() => {
                this.balance_sms = returnData.msg.balance;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async checkViewsNewsletter(tag) {
        let newsletter_views = 0;
        const data = {
            tag: tag
        }
        try {
            const returnData = await analytics.checkViewsNewsletter(data);
            runInAction(() => {
                newsletter_views = returnData.data[0];
                return newsletter_views;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return newsletter_views;
    }

    async checkDeliveryNewsletter(tag) {
        let newsletter_delivery = 0;
        const data = {
            tag: tag
        }
        try {
            const returnData = await analytics.checkDeliveryNewsletter(data);
            runInAction(() => {
                newsletter_delivery = returnData.data;
                return newsletter_delivery;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return newsletter_delivery;
    }

    async checkLinksNewsletter(tag) {
        let newsletter_links = 0;
        const data = {
            tag: tag
        }
        try {
            const returnData = await analytics.checkLinksNewsletter(data);
            runInAction(() => {
                newsletter_links = returnData.data;
                return newsletter_links;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return newsletter_links;
    }

    async getCountNewsletterUrls() {
        let newsletterLinksUrls = [];

        try {
            const returnData = await analytics.getCountNewsletterUrls();
            runInAction(() => {
                newsletterLinksUrls = returnData.body;
                return newsletterLinksUrls;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return newsletterLinksUrls;
    }

    async getMailings() {
        try {
            const returnData = await analytics.getMailings();
            runInAction(() => {
                this.mailings = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getAccountMutations(year, week) {
        let mutations = 0;

        let data = {
            year: year,
            week: week
        }

        try {
            const returnData = await analytics.getAccountTotal(data);
            runInAction(() => {
                mutations = returnData.data;
                return mutations;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return mutations;
    }

    getNumberOfWeek() {
        const today = new Date();
        return moment(today).week();
    }

    getNumberOfYear() {
        const today = new Date();
        return moment(today).year();
    }

    setWeeks(year) {
        let yr = year.toString();
        let week = yr.slice(-2) + "00";
        let weeknr = 0;
        let count = 0;
        let max_count = 0;
        this.weeks = [];

        if (year == this.getNumberOfYear()) {
            max_count = this.getNumberOfWeek();
        } else {
            max_count = 53;
        }
        for(count = 1; count <= max_count; count++) {
            weeknr = parseInt(week) + count;
            this.weeks.push(weeknr);
        }
        this.weeks.sort(function(a, b){return b-a});
    }
}

decorate(StoreAnalytics, {
    balance_sms: observable,
    mailings: observable,
    views_newsletter: observable,
    delivery_newsletter: observable,
    checkBalanceSms: action,
    checkDeliveryNewsletter: action,
    checkViewsNewsletter: action,
    checkLinksNewsletter: action,
    getAccountMutations: observable,
    getMailings: action,
    setWeeks: action
})

export default StoreAnalytics;