import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

import logoSB from '../../assets/images/branding/logo-succesboeken.svg';
import logo_img from '../../assets/images/branding/sb_logo.png';
import logo_txt from '../../assets/images/branding/sb_logo-text.png';

const Branding = inject("stores") (
    observer (
        class Branding extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            render() {

                return (
                    <div className="branding">
                        <Link to="/start/">
                            <img src={logo_img} className="branding__logo--img" />
                        </Link>
                        <span className="branding__text-container">
                            <Link to="/start/">
                                <img src={logo_txt} className="branding__logo--txt" />
                            </Link>
                        </span>
                    </div>
                )
            }
        }
    )
)

export default withRouter(Branding);
