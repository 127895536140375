import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

const BlockBookDetails = inject("stores") (
    observer (
        class BlockBookDetails extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlAPI = process.env.REACT_APP_URL_API;
            }

            componentDidMount = () => {
            }

            handleLink = () => {
                const url = this.urlAPI + '/public/docs/NUR-lijst-versie-2011.pdf';
                window.open(url);
            }

            render() {
                return (                  
                    <ul className="block block-list list--details">
                        <li className="list--details__listitem">
                            <span className="list--details__listitem--label">Titel</span>
                            <span className="list--details__listitem--field">{this.props.book && this.props.book.title}</span>
                        </li>
                        <li className="list--details__listitem">
                            <span className="list--details__listitem--label">Subtitel</span>
                            <span className="list--details__listitem--field">{this.props.book && this.props.book.subtitle}</span>
                        </li>
                        <li className="list--details__listitem">
                            <span className="list--details__listitem--label">Auteur</span>
                            <span className="list--details__listitem--field">{this.props.book && this.props.book.author1_firstname} {this.props.book && this.props.book.author1_prefix} {this.props.book && this.props.book.author1_lastname}</span>
                        </li>
                        <li className="list--details__listitem">
                            <span className="list--details__listitem--label">Auteur 2</span>
                            {this.props.book && this.props.book.author2_lastname !== null 
                            ?   <span className="list--details__listitem--field">{this.props.book && this.props.book.author2_firstname} {this.props.book && this.props.book.author1_prefix} {this.props.book && this.props.book.author2_lastname}</span>
                            :   <span>-</span>
                            }
                        </li>
                        <li className="list--details__listitem">
                            <span className="list--details__listitem--label">
                                NUR-code 
                            </span>
                            <span className="list--details__listitem--field">{this.props.book && this.props.book.nur}</span>
                        </li>
                        <li className="list--details__listitem">
                            <span
                                className = "list--details__listitem--link --link"
                                onClick = {() => this.handleLink()}
                            >
                                Bekijk nur-ljst
                            </span>
                        </li>
                        <li className="list--details__listitem">
                            <span className="list--details__listitem--label">Stock code</span>
                            <span className="list--details__listitem--field">{this.props.book && this.props.book.stock_code}</span>
                        </li>
                    </ul>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockBookDetails));
