import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

import TemplateCard from '../../templates/template-card';

const Card = inject("stores") (
    observer (
        class Card extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeDocs = this.props.stores.storeDocs;
            }

            componentDidMount = async() => {

            }

            render() {
                return (
                    <TemplateCard
                        card = {this.props.task}
                    >
                        <dl>
                        {Object.keys(this.props.task).map((key, i) => (
                            <React.Fragment>
                                {(!key.includes('_table') 
                                    && !key.includes('_key') 
                                    && !key.includes('_example') 
                                    && key != 'title'
                                    && key != 'remarks'
                                    && key != 'errors'
                                    && key != 'id') &&
                                    <React.Fragment>
                                        <dt>{key}</dt>
                                        <dd dangerouslySetInnerHTML={{ __html: this.props.task[key]}} />
                                    </React.Fragment>
                                }

                                {key.includes('_table') &&
                                    <dd className="dd--table">
                                        <span>Database tabel:</span>
                                        <span dangerouslySetInnerHTML={{ __html: this.props.task[key]}} />
                                    </dd>
                                }
                              
                                {key.includes('_key') &&
                                    <dd className="dd--key">
                                        <span>Key:</span>
                                        <span dangerouslySetInnerHTML={{ __html: this.props.task[key]}} />
                                    </dd>
                                }
                                {key.includes('_example') &&
                                    <dd className="dd--example">
                                        <span>Voorbeeld:</span>
                                        <span dangerouslySetInnerHTML={{ __html: this.props.task[key]}} />
                                    </dd>
                                }
                                {key == 'remarks' &&
                                    <React.Fragment>
                                    <dt>Opmerkingen</dt>
                                    <dd className="dd--remarks">
                                        {this.props.task[key].map((remark) => (
                                            Object.keys(remark).map((key, i) => (
                                                <React.Fragment>
                                                    <span className="dd--remarks__title">{key}</span>
                                                    <span className="dd--remarks__description" dangerouslySetInnerHTML={{ __html: remark[key]}} />
                                                </React.Fragment>
                                            ))
                                        ))}
                                    </dd>
                                    </React.Fragment>
                                }

                                {key == 'errors' &&
                                        <React.Fragment>
                                        <dt>Foutcodes</dt>
                                        <dd className="dd--errors">
                                            {this.props.task[key].map((error) => (
                                                Object.keys(error).map((key, i) => (
                                                    <div className="dd--errors__container">
                                                        <span className="dd--errors__title">{key}</span>
                                                        <span className="dd--errors__description" dangerouslySetInnerHTML={{ __html: error[key]}} />
                                                    </div>
                                                ))
                                            ))}
                                        </dd>
                                        </React.Fragment>
                                    }
                            </React.Fragment>
                        ))}
                        </dl>
                    </TemplateCard>
                )
            }
        }
    )
)

export default withRouter(Card);
