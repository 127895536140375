import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';

import OrderErrorsRow from '../components/tables/order-errors-row';

const AdminOrderErrors = inject("stores") (
    observer (
        class AdminOrderErrors extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
                
                this.state = {
                    orders: [],
                    title: ''
                }
            }

            componentDidMount = async() => {
                const orders = await this.storeOrders.getOrders('all');

                this.setState({
                    orders: orders
                })
            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                        section = 'shop'
                        feature = 'ordererrors'
                        title = 'Controle bestellingen'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Controle order errors</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__columns">
                                    <table className="table table--orders-overview">
                                    <thead>
                                        <tr>
                                            <th>Ordernummer</th>
                                            <th>Klant</th>
                                            <th>Error report</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.orders && this.state.orders.length > 0 && this.state.orders.map((order) => ( 
                                        <OrderErrorsRow 
                                            order = {order}
                                        />
                                    ))}
                                    {this.state.orders && this.state.orders.length == 0 &&
                                        <tr>
                                            <td colspan="5" className="table--orders-overview__cell-feedback">Er zijn geen bestellingen gevonden</td>
                                        </tr>
                                    }
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminOrderErrors));
