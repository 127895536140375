import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import PropTypes from 'prop-types';
import i18n from "i18next";

import ButtonDialog from '../../elements/button-dialog';

const DialogFeedback = inject("stores") (
    observer (
        class DialogFeedback extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeEmail = this.props.stores.storeEmail;
            }

            componentDidMount = () => {
                document.addEventListener("keydown", this.escFunction, false);
            }
            
            componentWillUnmount = () => {
                document.removeEventListener("keydown", this.escFunction, false);
            }

            escFunction = (event) => {
                if (event.keyCode === 27) {
                    this.props.onClose();
                }
            }

            render() {
                if(!this.props.show) {
                    return null;
                }

                return (
                    <div className="overlay">
                        <div className="dialog dialog--confirmation">
                            <div className="dialog-header">
                                <h2>{this.props.title}</h2>
                            </div>
                            <div>
                                {this.storeEmail.form_feedback}
                            </div>
                            {this.props.buttons &&
                                <div className="dialog-footer">
                                    {this.props.buttons.map((button, i) => (
                                        <ButtonDialog
                                            key = {i}
                                            caption = {button.text}
                                            onAction = {button.onClick}
                                            btnClass = {button.btnClass}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                );
            }
        }
    )
)
        
export default DialogFeedback;