import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';

import CustomerRowHistory from '../components/tables/customer-row-history';
import FormSearchClient from '../components/forms/form-search-client';

const AdminCustomer = inject("stores") (
    observer (
        class AdminCustomer extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeCustomers = this.props.stores.storeCustomers;
                this.storeOrders = this.props.stores.storeOrders;
                
                this.state = {
                    customer: {},   
                    orders: [],
                    title: ''
                }
            }

            componentDidMount = async() => {
                const id = this.props.match.params.id;
                const customer = await this.storeCustomers.getCustomer(id);
                const orders = await this.storeOrders.getAllOrders(id);
                this.setState({
                    customer: customer,
                    orders: orders
                })
            }

            componentDidUpdate = async() => {
                //const customer = await this.storeCustomers.getCustomer(this.props.location.client_id);
                //console.log('SbC customer 2::', this.props.location.client_id, customer)
                // const orders = await this.storeOrders.getAllOrders(this.props.location.client_id);
                // this.setState({
                //     customer: customer,
                //     orders: orders
                // })
            }

            submitSearch = async(value) => {
                const customers = await this.storeCustomers.searchCustomers(value);

                this.setState({
                    customers: customers
                })

                const path = '/admin-customers';
                this.props.history.push(path);
            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Beheer klant</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Klant: {this.props.match.params.id}</h2>
                                    <FormSearchClient 
                                        form = {false}
                                        submitSearch = {(e) => this.submitSearch(e)} 
                                    />
                                </div>
                            
                                <div className="content--section__columns">
                                    <table className="table table--account-overview">
                                        <tr>
                                            <td>
                                                <dl>
                                                    <dt><span className="table--account-overview__cell-label">Voornaam</span></dt>
                                                    <dd><span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.firstname}</span></dd>
                                                    <dt><span className="table--account-overview__cell-label">Achternaam</span></dt>
                                                    <dd><span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.lastname}</span></dd>
                                                </dl>
                                            </td>
                                            <td>
                                                <dl>
                                                    <dt><span className="table--account-overview__cell-label">E-mail</span></dt>
                                                    <dd><span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.email}</span></dd>
                                                    <dt><span className="table--account-overview__cell-label">Telefoon</span></dt>
                                                    <dd><span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.phonenr}</span></dd>
                                                </dl>
                                            </td>
                                            <td>
                                                <dl>
                                                    <dt><span className="table--account-overview__cell-label">Adres</span></dt>
                                                    <dd>
                                                        <span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.address}</span>
                                                        <span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.housenr}</span>
                                                        <span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.addition}</span>
                                                    </dd>
                                                    <dt><span className="table--account-overview__cell-label">Postcode</span></dt>
                                                    <dd><span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.zipcode}</span></dd>
                                                    <dt><span className="table--account-overview__cell-label">Plaats</span></dt>
                                                    <dd><span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.city}</span></dd>
                                                    <dt><span className="table--account-overview__cell-label">Land</span></dt>
                                                    <dd><span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.country}</span></dd>
                                                </dl>
                                            </td>
                                            <td>
                                                <dl>
                                                    <dt><span className="table--account-overview__cell-label">Bedrijfsnaam</span></dt>
                                                    <dd><span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.company}</span></dd>
                                                    <dt><span className="table--account-overview__cell-label">KvK</span></dt>
                                                    <dd><span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.nrKvK}</span></dd>
                                                    <dt><span className="table--account-overview__cell-label">Btw&#8209;nummer</span></dt>
                                                    <dd><span className="table--account-overview__cell-value">{this.state.customer && this.state.customer.nrVAT}</span></dd>
                                                </dl>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Bestelgeschiedenis</h2>
                                    <div className="content--section__form-actions">
                                        
                                    </div>
                                </div>
                                <div className="content--section__columns">
                                <table className="table table--orders-overview">
                                    <thead>
                                        <tr>
                                            <th>Ordernummer</th>
                                            <th className="table--orders-overview__cell-summary">Order</th>
                                            <th>Proces</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.orders && this.state.orders.length > 0 && this.state.orders.map((order) => ( 
                                        <CustomerRowHistory 
                                            order_id = {order.order_id}
                                            order = {order}
                                        />
                                    ))}
                                    {this.state.orders && this.state.orders.length == 0 &&
                                        <tr>
                                            <td colspan="4" className="table--orders-overview__cell-feedback">Er zijn geen bestellingen gevonden</td>
                                        </tr>
                                    }
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminCustomer));
