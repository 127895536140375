import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import TextInput from '../../elements/form-input-text';

const ConfirmPartner = inject("stores") (
    observer (
        class ConfirmPartner extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeEmail = this.props.stores.storeEmail;
                
                this.state = {
                    formControls: {
                        firstname: {
                            value: ''
                        },
                        lastname: {
                            value: ''
                        },
                        partner_code: {
                            value: ''
                        },
                        email: {
                            value: ''
                        }
                    }
                }
            }

            componentDidMount = () => {
                this.storeEmail.clearFormFeedback();
            }

            handleChangeSetting = (event) => {   
                this.storeEmail.clearFormFeedback();
                  
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            submitData = (e) => {
                this.storeEmail.sendConfirmPartner(this.state.formControls);
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Bevestig partner</h1>
                            </div>

                            <div className="wrapper--content__columns">
                                <div className="content-column">

                                    <ul className="block block-edit__list">
                                        {Object.keys(this.state.formControls).map((key) => (
                                            <li className="block block-edit__listitem">
                                                <span className="block block-edit__listitem--label">{key}</span>
                                                <span className="block block-edit__listitem--field">
                                                    <TextInput 
                                                        name = {key} 
                                                        value = {this.state.formControls[key].value}
                                                        onChange = {(e) => this.handleChangeSetting(e)}
                                                        touched = {this.state.formControls[key].touched}
                                                    />
                                                </span>       
                                            </li>
                                        ))}
                                    </ul>
                                    <button onClick={(e) => this.submitData(e)}>Verstuur e-mail met partnercode</button>
                                    
                                    {Object.keys(this.storeEmail.form_feedback).length > 0 && 
                                        <div className="form__feedback">
                                            {'success' in this.storeEmail.form_feedback &&
                                                <React.Fragment>
                                                    <span className="form__feedback--success">De e-mail is verstuurd</span>
                                                </React.Fragment>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(ConfirmPartner));
