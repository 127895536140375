import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import TextInput from '../../elements/form-input-text';
import FormNewsletterSelect from '../../components/forms/form-newsletter-select';
import FormNewsletterTags from '../../components/forms/form-newsletter-tags';
import Card from '../../components/documentation/card';

const NewsletterShow = inject("stores") (
    observer (
        class NewsletterShow extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeDocs = this.props.stores.storeDocs;
                
                this.state = {
                    formControls: {
                        title: {
                            label: 'Titel',
                            value: '',
                            placeholder: 'Vul titel in',
                        },
                        has_pdf: {
                            label: 'Heeft pdf-versie',
                            value: '',
                            placeholder: '',
                        },
                        has_php: {
                            label: 'Heeft php-versie',
                            value: '',
                            placeholder: '',
                        }
                    },
                    week_dir: '',
                    week_file: '',
                    week_tag: '',
                    week_str: '',
                    request1_success: false,
                    request2_success: false
                }
            }

            componentDidMount = async() => {
                const file = 'features';
                const result = await this.storeDocs.getDocumentation(file);

                const doc_send = this.storeDocs.docs_features
                    .filter((doc) => doc.id == "NEWSLETTER_SHOW")
                this.setState({
                    doc_send: doc_send[0]
                })
            }

            handleChange = (event, action) => {     
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            setSectionValue = (section, key, value) => {
                const updatedControls = {
                    ...this.state
                };

                updatedControls[key] = value;
          
                this.setState({
                    ...updatedControls
                })
            }
          
            submitSettingsData = () => {
                const data = {
                    week_file: this.state.week_dir + '/' + this.state.week_str,
                    week_tag: this.state.week_tag
                }
                this.storeUi.setNewsletter(data);

                this.setState({
                    request1_success: true
                })
            }

            submitTitle = () => {
                const data = {
                    week_tag: this.state.week_tag,
                    week_title: this.state.formControls.title.value,
                    has_pdf: this.state.formControls.has_pdf.value,
                    has_php: this.state.formControls.has_php.value
                }
                this.storeUi.setNewsletterTitle(data);

                this.setState({
                    request2_success: true
                })
            }

            render() {
                return (                  
                    <div className="content--section">
                        <div className="content--section__header">
                            <h2>Toon nieuwsbrief op website</h2>
                        </div>
                        <div className="content--section__columns">  
                            <div className="content--section__column content--section">
                                <FormNewsletterSelect 
                                    section = 'SHOW'
                                    setSectionValue = {this.setSectionValue}
                                />
                            </div>

                            <div className="content--section__column">
                                <h3>Update settings in tabel SettingSB</h3>
                                <p>Deze settings bepalen welke nieuwsbrief op de homepage getoond wordt als 'meest recente' en welke boeken dus direct in het contentgedeelte van de homepage getoond worden. </p>
                                <p>Deze settings bestaan uit een tweetal tags die automatisch uit de hiernaast ingevulde datum afgeleid kunnen worden.</p>
                                <div className="content--section__form-actions">
                                    {this.state.week_tag === ''
                                        ?   <button className="btn btn--primary --disabled">Voeg titel toe aan database</button>
                                        :   <button className="btn btn--primary" onClick={(e) => this.submitSettingsData()}>Update settings</button>
                                    }
                                </div> 
                                {this.state.request1_success &&
                                    <div className="--success">
                                        Settings zijn aangepast
                                    </div>
                                }  
                            </div>

                            <div className="content--section__column">
                                <h3>Update data in tabel Newsletters</h3>
                                <p>Geef de titel van de nieuwsbrief op en of er een pdf- en/of php-versie van deze nieuwsbrief is</p>
                                {Object.keys(this.state.formControls).map((key) => (
                                    <div>
                                        <label className="form-label">{this.state.formControls[key].label}</label>
                                        <span className="block block-edit__listitem--field">
                                            <TextInput 
                                                name = {key} 
                                                placeholder = {this.state.formControls[key].placeholder}
                                                value = {this.state.formControls[key].value}
                                                onChange = {(e) => this.handleChange(e)}
                                                touched = {this.state.formControls[key].touched}
                                            />
                                        </span>       
                                    </div>
                                ))}
                                
                                <div className="content--section__form-actions">
                                    {(this.state.week_tag === '' || this.state.formControls.title.value === '')
                                        ?   <button className="btn btn--primary --disabled">Voeg titel toe aan database</button>
                                        :   <button className="btn btn--primary" onClick={(e) => this.submitTitle()}>Voeg data toe aan database</button>
                                    }
                                </div>
                                {this.state.request2_success &&
                                    <div className="--success">
                                        Data is gewijzigd
                                    </div>
                                } 
                            </div>
                        </div>
                    </div>  
                )
            }
        }
    )
)

export default withTranslation()(withRouter(NewsletterShow));
