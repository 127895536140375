export async function checkLoginAdmin(email, pw) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/admin/checkLogin.php';

    const postData = {
        email: email,
        pw: pw
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            })
        });
        let responseJson = await response.json();
        if (responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error login: ', error.message);
    }
}



