import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Link } from 'react-router-dom';

import CellOrderAddress from './cell-order-address';
import CellOrderContact from './cell-order-contact';
import CellOrderDetails from './cell-order-details';

const OrderRowSummary = inject("stores") (
    observer (
        class OrderRowSummary extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                    items: []
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
            }

            componentDidMount = async() => {
                const items = await this.storeOrders.getOrderItemsData(this.props.order.order_id);
                this.setState({
                    items: items
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.order_id !== this.props.order_id) {
                    const items = await this.storeOrders.getOrderItemsData(this.props.order_id);
                    this.setState({
                        items: items
                    })
                }
            }

            render() {
                return (       
                    <React.Fragment>     
                    <tr className="table--orders-overview__row-header">
                        <td className="table--orders-overview__cell-header">
                            Klant
                        </td>
                        <td>
                            <CellOrderAddress
                                type = 'invoice'
                                order_id = {this.props.order_id}
                                order = {this.props.order}
                                nr = {this.state.items.length}
                            />
                        </td>
                        <td>
                            <CellOrderAddress
                                type = 'delivery'
                                order_id = {this.props.order_id}
                                order = {this.props.order}
                                nr = {this.state.items.length}
                            />
                        </td>
                        <td>
                            <CellOrderContact
                                order_id = {this.props.order_id}
                                order = {this.props.order}
                                nr = {this.state.items.length}
                            />
                        </td>
                    </tr>
                    </React.Fragment> 
                )
            }
        }
    )
)

export default withTranslation()(withRouter(OrderRowSummary));
