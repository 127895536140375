import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import moment from "moment";

const CBReportOverview = inject("stores") (
    observer (
        class CBReportOverview extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    cb_count: 0,
                    sb_count: 0,
                    cb_stock: 0,
                    sb_stock: 0,
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeCb = this.props.stores.storeCb;
            }

            componentDidMount = async() => {
                let cb_count = await this.storeCb.getDBCount('SHOP_ALL', 'all');
                let sb_count = await this.storeCb.getDBCount('SHOP_SB', 'all');
                let cb_stock = await this.storeCb.getDBCount('SHOP_ALL', 'stock');
                let sb_stock = await this.storeCb.getDBCount('SHOP_SB', 'stock');
                this.setState({
                    cb_count: cb_count,
                    sb_count: sb_count,
                    cb_stock: cb_stock,
                    sb_stock: sb_stock
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.loading_file_init !== this.props.loading_file_init || prevProps.loading_file_daily !== this.props.loading_file_daily) {
                    let cb_count = await this.storeCb.getDBCount('SHOP_ALL', 'all');
                    let sb_count = await this.storeCb.getDBCount('SHOP_SB', 'all');
                    let cb_stock = await this.storeCb.getDBCount('SHOP_ALL', 'stock');
                    let sb_stock = await this.storeCb.getDBCount('SHOP_SB', 'stock');
                    this.setState({
                        cb_count: cb_count,
                        sb_count: sb_count,
                        cb_stock: cb_stock,
                        sb_stock: sb_stock
                    })

                }
            }

            render() {

                return (
                    <div className="content--section__columns">
                        <table className="table table--reports">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Alle boeken</th>
                                <th>Succesboeken</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Database
                                </td>
                                <td>
                                    succesboekenCatalog
                                </td>
                                <td>
                                    succesboeken
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Database table
                                </td>
                                <td>
                                    Boekenlijst2021
                                </td>
                                <td>
                                    Boekenlijst2008
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Aantal items
                                </td>
                                <td>
                                    {this.state.cb_count}
                                </td>
                                <td>
                                    {this.state.sb_count}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Aantal items op voorraad
                                </td>
                                <td>
                                    {this.state.cb_stock}
                                </td>
                                <td>
                                    {this.state.sb_stock}
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                )
            }
        }
    )
)

export default withRouter(CBReportOverview);
