import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import TextArea from '../../elements/form-input-textarea';

const ToolTranslation = inject("stores") (
    observer (
        class ToolTranslation extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                
                this.state = {
                    formControls: {
                        textOriginal: {
                            value: '',
                            placeholder: "Vul text in",
                            valid: true
                        }
                    }
                }
            }

            componentDidMount = () => {
            }

            handleChange = (event) => {   
                //const name = event.target.name;
                const name = "textOriginal";
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                    >   
                        <div className="wrapper--content__header">
                            <h1>Vertalingen</h1>
                        </div>

                        <div className="content--section__columns">
                            <div className="content--section__column">
                                <TextArea
                                    valid = {this.state.formControls.textOriginal.valid}
                                    onChange = {this.handleChange}
                                />
                            </div>

                            <div className="content--section__column">
                                {this.state.formControls.textOriginal.value}
                            </div>
                        </div>

                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(ToolTranslation));
