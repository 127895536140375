import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';

const AdminNotifications = inject("stores") (
    observer (
        class AdminNotifications extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeNotifications = this.props.stores.storeNotifications;
                
                this.state = {
                    formControls: {
                        
                    }
                }
            }

            componentDidMount = async() => {
                let settings = await this.storeNotifications.getNotifications(); 
                this.setSettings();
            }

            setSettings = () => {
                let settings = this.storeUi.settings_list;
                let setting = {};

                const updatedControls = {
                    ...this.state.formControls
                };

                Object.keys(settings).map((key) => {
                    updatedControls[key] = {
                        value: this.storeUi.settings_list[key]
                    }
                })

                this.setState({
                    formControls: updatedControls
                })                
            }

            handleChangeSetting = (event) => {     
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            submitData = (e) => {
                this.storeUi.updateSettings(this.state.formControls)
            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Notificaties</h1>
                            </div>

                            <div className="wrapper--content__columns">
                                <ul className="block block-edit__list">
                                    {Object.keys(this.state.formControls).map((key) => (
                                        <li className="block block-edit__listitem">
                                            <span className="block block-edit__listitem--label">{key}</span>
                                            <span className="block block-edit__listitem--field">
                                                <TextInput 
                                                    name = {key} 
                                                    value = {this.state.formControls[key].value}
                                                    onChange = {(e) => this.handleChangeSetting(e)}
                                                    touched = {this.state.formControls[key].touched}
                                                />
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                <button onClick={(e) => this.submitData(e)}>Update settings</button>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminNotifications));
