import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as docs from '../communicator/documentation';

class StoreDocs {
    docs_blocks = [];
    docs_tasks = [];
    docs_rules = [];
    docs_features = [];
    docs_orders = [];
    docs_users = [];

    constructor() {
        
    }

    async getDocumentation(file) {
        try {
            const returnData = await docs.getDocumentation(file);
            console.log('SbC docs for::', file, returnData)
            runInAction(() => {
                switch(file) {
                    case 'blocks':
                        this.docs_blocks = returnData.data.blocks;
                        break;
                    case 'cb':
                        this.docs_tasks = returnData.data.tasks;
                        break;
                    case 'features':
                        this.docs_features = returnData.data.features;
                        break;
                    case 'rules':
                        this.docs_rules = returnData.data.rules;
                        break;
                    case 'orders':
                        this.docs_orders = returnData.data.orders;
                        break;
                    case 'users':
                        this.docs_users = returnData.data.users;
                        break;
                    default:
                } 
                
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

}

decorate(StoreDocs, {
    docs_blocks: observable,
    docs_tasks: observable,
    docs_rules: observable,
    docs_orders: observable,
    docs_users: observable,
    docs_features: observable,
    getDocumentation: action
})

export default StoreDocs;