import * as apifetch from './fetch.js';

export async function clearCatalog() {
    const urlEndpoint = '/admin/cb/clearCatalog.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function clearUpdates() {
    const urlEndpoint = '/admin/cb/clearUpdates.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function initCatalog(data) {
    let urlEndpoint = '/admin/cb/cb-init.php';

    if (data['update'] == 'INIT') {
        urlEndpoint = '/admin/cb/cb-init.php?update=INIT';
    } else {
        urlEndpoint = '/admin/cb/cb-init.php?update=INIT_DAILY';
    }

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function initResources(data) {
    let urlEndpoint = '/admin/cb/cb-download-resources.php';

    if (data.update == 'INIT') {
        urlEndpoint = '/admin/cb/cb-download-resources.php?update=INIT';
    } else {
        urlEndpoint = '/admin/cb/cb-download-resources.php?update=INIT_DAILY';
    }

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function checkRecourcesCBMissing(data) {
    let urlEndpoint = '/admin/cb/checks/checkRecourcesCBMissing.php';

    if (data.update == 'DAILY') {
        urlEndpoint = '/admin/cb/checks/checkRecourcesCBMissing.php?update=DAILY';
    }

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function checkPdfsMissing() {
    let urlEndpoint = '/admin/cb/checks/checkMissingPdfs.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function checkResourcesMissing(data) {
    let urlEndpoint = '/admin/cb/checkResourcesMissing.php';

    if (data.update == 'INIT') {
        urlEndpoint = '/admin/cb/checkResourcesMissing.php?update=INIT';
    } else {
        urlEndpoint = '/admin/cb/checkResourcesMissing.php?update=INIT_DAILY';
    }
    
    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function downloadResourcesMissing(data) {
    let urlEndpoint = '/admin/cb/checks/downloadResourcesMissing.php';

    if (data.update == 'DAILY') {
        urlEndpoint = '/admin/cb/checks/downloadResourcesMissing.php?update=DAILY';
    } 

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getResourcesMissing(data) {
    let urlEndpoint = '/admin/cb/getResourcesMissing.php';

    if (data.update == 'INIT') {
        urlEndpoint = '/admin/cb/getResourcesMissing.php?update=INIT';
    } else {
        urlEndpoint = '/admin/cb/getResourcesMissing.php?update=INIT_DAILY';
    }
  
    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getCBReports() {
    const urlEndpoint = '/admin/cb/getCBReports.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getDBCount(data) {
    const urlEndpoint = '/admin/cb/getDBCount.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getInitReports(data) {
    let urlEndpoint;
    if (data.update === 'INIT') {
        urlEndpoint = '/admin/cb/getInitReports.php';
    }
    if (data.update === 'DAILY') {
        urlEndpoint = '/admin/cb/getUpdateReports.php';
    }

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function listFilesInit() {
    const urlEndpoint = '/admin/cb/listFilesInit.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function listFiles(data) {
    const urlEndpoint = '/admin/cb/listFiles.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getDailyDirs() {
    const urlEndpoint = '/admin/cb/getDailyDirs.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function checkUpdates2008(data) {
    const urlEndpoint = '/admin/cb/checkUpdates2008.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getCoverLinks(file) {
    let urlEndpoint = '/admin/cb/checks/getCoverLinks.php';

    const postData = {
        file: file
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function restartCbDailyUpdate() {
    const urlEndpoint = '/admin/cb/restartCbDailyUpdate.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}
