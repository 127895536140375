import React from 'react';
import i18n from "i18next";

const ButtonDialog = (props) => {

    let btnClass = "dialog-footer__button";

    if (props.btnClass && props.btnClass !== '') {
        btnClass = btnClass + ' ' + btnClass + props.btnClass;
    }
  
    return (
        <button 
            className = {btnClass} 
            onClick = {props.onAction}
        >
            {props.caption}
        </button>
    );
}

export default ButtonDialog;