import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

import Template from '../templates/template';

const Start = inject("stores") (
    observer (
        class Start extends Component {

            constructor(props) {
                super(props);
                this.props = props;   
                
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    loggedin: false,
                    redirect: false
                }
            }

            componentDidMount = () => {

            }

            render() {
                if (!this.storeUi.admin_data.admin_loggedin) {
                    return <Redirect 
                        push to={"/login"} 
                    />;
                }

                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div>startpagina admin interface</div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Start));
