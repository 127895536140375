import { observable, computed, action, decorate } from "mobx";

import StoreUi from './store-ui';
import StoreAnalytics from './store-analytics';
import StoreBooks from './store-books';
import StoreCatalog from './store-catalog';
import StoreCb from './store-cb';
import StoreContent from './store-content';
import StoreCustomers from './store-customers';
import StoreDocs from './store-documentation';
import StoreEBooks from './store-ebooks';
import StoreEmail from './store-email';
import StoreNotifications from './store-notifications';
import StoreOrders from './store-orders';
import StorePartners from './store-partners';
import StoreReports from './store-reports';
import StoreWiki from './store-wiki';

class StoreRoot {
    constructor() {
        this.storeUi = new StoreUi(this);
        this.storeAnalytics = new StoreAnalytics(this);
        this.storeBooks = new StoreBooks(this);
        this.storeCb = new StoreCb(this);
        this.storeCatalog = new StoreCatalog(this);
        this.storeContent = new StoreContent(this);
        this.storeCustomers = new StoreCustomers(this);
        this.storeDocs = new StoreDocs(this);
        this.storeEBooks = new StoreEBooks(this);
        this.storeEmail = new StoreEmail(this);
        this.storeNotifications = new StoreNotifications(this);
        this.storeOrders = new StoreOrders(this);
        this.storePartners = new StorePartners(this);
        this.storeReports = new StoreReports(this);
        this.storeWiki = new StoreWiki(this);
    }
}

export default StoreRoot;