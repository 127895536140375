import * as apifetch from './fetch.js';

export async function getDocumentation(section) {
    const urlEndpoint = '/admin/documentation/getDocumentation.php';

    const postData = {
       file: section
    }
    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}
