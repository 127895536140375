import * as apifetch from './fetch.js';

export async function getAddressData(data) {
    const urlEndpoint = '/admin/myparcel/getAddressData.php';

    const postData = {
        data: data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function submitOrderToMyParcel(data) {
    const urlEndpoint = '/admin/myparcel/setTrackTrace.php';

    const postData = {
        data: data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getAllOrders(client_id) {
    const urlEndpoint = '/admin/orders/getAllOrders.php';

    const postData = {
        client_id: client_id
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getOrders(scope) {
    const urlEndpoint = '/v2/admin/orders/' + scope;

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getOrderErrors(orderId) {
    const urlEndpoint = '/v2/monitoring/error-report/' + orderId + '/admin';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getOrder(order_id) {
    const urlEndpoint = '/admin/orders/getOrder.php';

    const postData = {
        order_id: order_id
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getOrderItemsData(order_id) {
    const urlEndpoint = '/admin/orders/getOrderItemsData.php';

    const postData = {
        order_id: order_id
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getTrackTraceOrder(data) {
    const urlEndpoint = '/admin/myparcel/getTrackTrace.php';

    const postData = {
        data: data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getTrackTraceOrders() {
    const urlEndpoint = '/admin/orders/getTrackTraceOrders.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function setOrderStatus(data) {
    const urlEndpoint = '/admin/orders/setOrderStatus.php';

    const postData = {
        data: data
    }

    console.log('SbC set status::', postData)

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function setTrackTrace(data) {
    const urlEndpoint = '/admin/orders/setTrackTrace.php';

    const postData = {
        data: data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getAfterDeliveryOrders() {
    const urlEndpoint = '/admin/orders/getAfterDeliveryOrders.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function setAfterDelivery(data) {
    const urlEndpoint = '/admin/orders/setAfterDelivery.php';

    const postData = {
        data: data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getOrdersBySku(sku) {
    const urlEndpoint = '/v2/admin/reports/sales/' + sku;

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

