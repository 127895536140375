import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as customers from '../communicator/customers';
import * as partners from '../communicator/partners';

class StoreCustomers {
    form_feedback = '';
    customers = [];
    
    constructor() {
        
    }

    async getAllCustomers() {
        let customers_all;
        try {
            let returnData = await customers.getAllCustomers();
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.customers = returnData.data;
                    customers_all = returnData.data;
                } else {

                } 
                return customers_all;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return customers_all;
    }

    async searchCustomers(term) {
        let result;

        const data = {
            term: term
        }

        try {
            let returnData = await customers.searchCustomers(data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.customers = returnData.data;
                    result = returnData.data;
                } else {

                } 
                return result;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return result;
    }

    async getCustomer(id) {
        let customer;

        let data = {
            id: id
        }
        try {
            let returnData = await customers.getCustomer(data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    customer = returnData.data[0];
                } else {

                } 
                return customer;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return customer;
    }
}

decorate(StoreCustomers, {
    form_feedback: observable,
    customers: observable,
    getAllCustomers: action,
    getCustomer: action
})

export default StoreCustomers;