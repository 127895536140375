import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import Blocks from '../../components/documentation/blocks';
import BusinessRules from '../../components/documentation/business-rules';
import CBTasks from '../../components/documentation/cb-tasks';
import Features from '../../components/documentation/features';
import Orders from '../../components/documentation/orders';
import Users from '../../components/documentation/users';

const Documentation = inject("stores") (
    observer (
        class Documentation extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = () => {
            }

            render() {
                return (                  
                    <Template
                        type = 'documentation'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Documentatie</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Beschrijving features</h2>
                                </div>

                                <div className="wrapper--content__columns">
                                    <div className="content-column">
                                        <Features />
                                    </div>
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Beschrijving blokken</h2>
                                </div>

                                <div className="wrapper--content__columns">
                                    <div className="content-column">
                                        <Blocks />
                                    </div>
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Bestellingen</h2>
                                </div>

                                <div className="wrapper--content__columns">
                                    <div className="content-column">
                                        <Orders />
                                    </div>
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Gebruikers</h2>
                                </div>

                                <div className="wrapper--content__columns">
                                    <div className="content-column">
                                        <Users />
                                    </div>
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Business rules</h2>
                                </div>

                                <div className="wrapper--content__columns">
                                    <div className="content-column">
                                        <BusinessRules />
                                    </div>
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Centraal Boekhuis</h2>
                                </div>

                                <div className="wrapper--content__columns">
                                    <div className="content-column">
                                        <CBTasks />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Documentation));
