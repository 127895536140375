import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const CellOrderErrors = inject("stores") (
    observer (
        class CellOrderErrors extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeOrders = this.props.stores.storeOrders;
                
                this.state = {

                }
            }

            componentDidMount = async() => {
                const result = await this.storeOrders.getOrderErrors(this.props.orderId);

                this.setState({
                    errors: result
                })

                if (result.length === 0) {
                    this.props.setErrorCount(0);
                }
            }

            render() {
                return (                  
                    <React.Fragment>
                        <div>
                            <ul>
                                {this.state.errors && this.state.errors.map((error) =>
                                    <li
                                        dangerouslySetInnerHTML={{ __html: error}}
                                    />
                                )}
                            </ul>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CellOrderErrors));
