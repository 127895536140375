import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';

const Analytics = inject("stores") (
    observer (
        class Analytics extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                
                this.state = {
                    formControls: {
                        
                    }
                }
            }

            componentDidMount = async() => {

            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div>GOOGLE ANALYTICS</div>
                            <p><a href="https://analytics.google.com/analytics/web/?authuser=1#/report-home/a4967097w244719540p227698334" target="_blank">Direct naar Google Analytics</a></p>
                            <ul className="block block-edit__list">
                                <li className="block block-edit__listitem">
                                    <span className="block block-edit__listitem--label">Username:</span>
                                    <span className="block block-edit__listitem--field">Wachtwoord:</span>
                                </li>
                            </ul>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Analytics));
