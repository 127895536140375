import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Link } from 'react-router-dom';

import CellOrderSummary from './cell-order-summary';
import CellOrderStatus from './cell-order-status';

const CustomerRowHistory = inject("stores") (
    observer (
        class CustomerRowHistory extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                    items: []
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
            }

            componentDidMount = async() => {
                const items = await this.storeOrders.getOrderItemsData(this.props.order.order_id);
                this.setState({
                    items: items
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.order_id !== this.props.order_id) {
                    const items = await this.storeOrders.getOrderItemsData(this.props.order_id);
                    this.setState({
                        items: items
                    })
                }
            }

            render() {
                return (       
                    <React.Fragment>
                    <tr className="table--orders__row-header">
                        <td colspan="5">
                            <span className="table--orders__cell-value">
                                <Link 
                                    to = {{ 
                                        pathname: "/admin-order/" + this.props.order_id,
                                        order_id: this.props.order_id,
                                        order: this.props.order
                                    }}
                                >
                                    {this.props.order.order_id}
                                </Link>
                            </span>
                        </td>
                        <td>
                            
                        </td>
                    </tr>          
                    <tr>
                        <td>
                            {/* <span className="table--orders__cell-label">{moment(this.props.order.order_date.date).format("DD-MM-YYYY")}</span>
                            <span className="table--orders__cell-value">{this.props.order.order_id}</span> */}
                        </td>
                        <td className="table--orders-overview__cell-summary">
                            <CellOrderSummary
                                order_id = {this.props.order.order_id}
                                nr = {this.state.items.length}
                                items = {this.state.items}
                                order_fulfilled = {moment(this.props.order.order_fulfilled).format("DD-MM-YYYY")}
                            />
                        </td>
                        <td>
                            <span className="table--orders__cell-label">Besteld</span>
                            <span className="table--orders__cell-value">{moment(this.props.order.order_date.date).format("DD-MM-YYYY")}</span>
                            <span className="table--orders__cell-label">Betaald</span>
                            {this.props.order.order_paid !== null 
                            ?   <span className="table--orders__cell-value">{moment(this.props.order.order_paid.date).format("DD-MM-YYYY")}</span>
                            :   <span className="table--orders__cell-value">-</span>
                            }
                            <span className="table--orders__cell-label">Bankoverschrijving</span>
                            {this.props.order.order_banktransfer !== null
                            ?   <span className="table--orders__cell-value">{moment(this.props.order.order_banktransfer.date).format("DD-MM-YYYY")}</span>
                            :   <span className="table--orders__cell-value">-</span>
                            }
                            <span className="table--orders__cell-label">Besteld CB</span>
                            {this.props.order.order_cb !== null
                            ?   <span className="table--orders__cell-value">{moment(this.props.order.order_cb.date).format("DD-MM-YYYY")}</span>
                            :   <span className="table--orders__cell-value">-</span>
                            }
                            <span className="table--orders__cell-label">Afgehandeld</span>
                            {this.props.order.order_fulfilled !== null
                            ?   <span className="table--orders__cell-value">{moment(this.props.order.order_fulfilled.date).format("DD-MM-YYYY")}</span>
                            :   <span className="table--orders__cell-value">-</span>
                            }
                        </td>
                        <td>
                            <CellOrderStatus
                                order = {this.props.order}
                            />
                        </td>
                    </tr>
                    </React.Fragment> 
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CustomerRowHistory));
