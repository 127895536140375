import * as apifetch from './fetch.js';

export async function setNewsletter(data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/admin/content/setNewsletter.php';

    const postData = data;

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            console.log('SbC data return::', arrData)
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message, urlEndpoint);
    }
}

export async function getNewsletter(tag) {
    const urlEndpoint = '/admin/content/getNewsletter.php';

    const postData = {
        tag: tag
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getSettings() {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/admin/settings/getSettings.php';

    try {
        let response = await fetch(urlEndpoint, {
            method: 'GET',
            headers: {
                //'API-Key': '29CJV4LSY4IJLTSMQ2PBK8K7KDBS541D',
                //'Content-Type': 'application/json'
            }
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error settings: ', error.message);
    }
}

export async function getSettingsAdmin() {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/v2/admin/settings/list';

    try {
        let response = await fetch(urlEndpoint, {
            method: 'GET',
            headers: {
                //'API-Key': '29CJV4LSY4IJLTSMQ2PBK8K7KDBS541D',
                //'Content-Type': 'application/json'
            }
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error settings: ', error.message);
    }
}

export async function updateSettings(form_data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/admin/settings/updateSettings.php';

    const postData = {
        form_data: form_data
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                //'API-Key': '29CJV4LSY4IJLTSMQ2PBK8K7KDBS541D',
                //'Content-Type': 'application/json'
            }
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error settings: ', error.message);
    }
}

export async function updateSettingsAdmin(data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/v2/admin/settings/update';

    const postData = {
        data: data
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                //'API-Key': '29CJV4LSY4IJLTSMQ2PBK8K7KDBS541D',
                //'Content-Type': 'application/json'
            }
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error settings: ', error.message);
    }
}

export async function checkMailingExists(data) {
    const urlEndpoint = '/admin/content/checkMailingExists.php';

    const postData = {
        data: data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function sendMailing(data) {
    const urlEndpoint = '/email/sendMailing.php';

    const postData = {
        data: data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function sendMailingNew(data) {
    const urlEndpoint = '/v2/email/mailing';

    const postData = {
        data: data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function setNewsletterTitle(data) {
    const urlEndpoint = '/admin/content/setNewsletterTitle.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getNavigationCategories() {
    const urlEndpoint = '/admin/content/getNavigationCategories.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getListNURs() {
    const urlEndpoint = '/admin/content/getListNURs.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getCountForNUR(data) {
    const urlEndpoint = '/admin/content/getCountForNUR.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getListCatNURs() {
    const urlEndpoint = '/admin/content/getListCatNURs.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function addCategory(data) {
    const urlEndpoint = '/admin/content/addCategory.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function addNUR(data) {
    const urlEndpoint = '/admin/content/addNUR.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function deleteCategory(data) {
    const urlEndpoint = '/admin/content/deleteCategory.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function deleteNUR(data) {
    const urlEndpoint = '/admin/content/deleteNUR.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getQuote(data) {
    const urlEndpoint = '/content/getQuote.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function clearCountBooks() {
    const urlEndpoint = '/admin/content/clearCountBooks.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function countBooks() {
    const urlEndpoint = '/admin/content/countBooks.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function saveVtt(data) {
    const urlEndpoint = '/admin/videos/saveCCFile.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getPromoBooks() {
    const urlEndpoint = '/v2/admin/promos/promobooks';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function updatePromoBooks(data) {
    const urlEndpoint = '/v2/admin/promos/promobooks';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

