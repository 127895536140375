import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';

const Emails = inject("stores") (
    observer (
        class Emails extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeEmail = this.props.stores.storeEmail;
                
                this.state = {
                }
            }

            componentDidMount = () => {
            }

            handleTestEmail = (url) => {
                this.storeEmail.sendTestEmail(url);
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                    >   
                        <div className="wrapper--content__header">
                            <h1>Test automatisch verstuurde e-mails</h1>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Algemeen</h2>
                            </div>

                            <div className="wrapper--content__columns">
                                {/* <ul>
                                    <li><a href={this.urlAPI + "/test/sendTestEmail.php"}>E-mail ter verduidelijking globale vormgeving</a></li>
                                    <li><a href={this.urlAPI + "/test/sendLinkDownload.php"}>E-mail met link download meditatie Joe Dispenza</a></li>
                                    <li>TODO Verstuurd naar een relatie</li>
                                </ul> */}
                            </div>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Account</h2>
                            </div>

                            <div className="wrapper--content__columns">
                                <ul>
                                    <li className="--link" onClick={(e) => this.handleTestEmail('/test/sendTestConfirmAccount.php')}>E-mail bevestiging nieuw account (incl aanmelding nieuwsbrief)</li>
                                    <li className="--link" onClick={(e) => this.handleTestEmail("/test/sendTestUnsubscribe.php?type=formal")}>E-mail na uitschrijven nieuwsbrief (formal, u)</li>
                                    <li className="--link" onClick={(e) => this.handleTestEmail("/test/sendTestUnsubscribe.php?type=informal")}>E-mail na uitschrijven nieuwsbrief (informal, je)</li>
                                    <li className="--link" onClick={(e) => this.handleTestEmail("/test/sendTestRequestPartner.php")}>E-mail aanvraag partneraccount</li>
                                    <li className="--link" onClick={(e) => this.handleTestEmail('/test/sendTestPassword.php')}>E-mail wachtwoord opvragen</li>
                                    {/* <li>Bevestiging e-mail adres</li> */}
                                    {/* <li>TODO Verstuur partnercode / bevestiging acceptatie partneraanmelding</li> */}
                                </ul>
                            </div>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Bestelling</h2>
                            </div>

                            <div className="wrapper--content__columns">
                                <ul>
                                    {/* <li><a href={this.urlAPI + "test/sendTestConfirmOrder.php"}>Bevestiging bestelling</a> (niet gebruikt?)</li> */}
                                    {/* <li>Bevestiging bestelling</li> */}
                                    <li className="--link" onClick={(e) => this.handleTestEmail('/test/sendTestConfirmPayment.php')}>Bevestiging betaling</li> 
                                    <li className="--link" onClick={(e) => this.handleTestEmail('/test/sendTestConfirmBankTransfer.php')}>Bevestiging bankoverschrijving</li> 
                                    <li className="--link" onClick={(e) => this.handleTestEmail('/test/sendTestConfirmShipment.php')}>Factuur en uw bestelling wordt verstuurd</li>
                                </ul>
                            </div>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>E-mails naar admin</h2>
                            </div>

                            <div className="wrapper--content__columns">
                                <ul>
                                    <li>
                                        <p>Contact/opmerking</p>
                                        <ul>
                                            <li className="--link" onClick={(e) => this.handleTestEmail('/test/sendTestFormContact.php')}>Met demo data</li>
                                            <li><a href={this.urlSB + "/contact"} target="_blank">Vul zelf formulier in</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p>Bel mij terug</p>
                                        <ul>
                                            <li className="--link" onClick={(e) => this.handleTestEmail('/test/sendTestFormCallMeBack.php')}>Met demo data</li>
                                            <li><a href={this.urlSB + "/call-me-back"} target="_blank">Vul zelf verzoek in</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p>Pers aanvraag</p>
                                        <ul>
                                            <li className="--link" onClick={(e) => this.handleTestEmail('/test/sendTestFormPress.php')}>Met demo data</li>
                                            <li><a href={this.urlSB + "/press"} target="_blank">Vul zelf aanvraag in</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p>Ingezonden review</p>
                                        <ul>
                                            <li className="--link" onClick={(e) => this.handleTestEmail('/test/sendTestFormReview.php')}>Met demo data</li>
                                            <li><a href={this.urlSB + "/book/9789492665041/reviews"} target="_blank">Vul zelf review in</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>                           
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Emails));
