import React from 'react';
import i18n from "i18next";

const Counter = (props) => {

    let btnClass = "container--counter";
    let btnClassAlert = "--alert";

    if (props.value <= props.value_alert) {
        btnClass = btnClass + ' ' + btnClassAlert;
    }

    return (
        <div className={btnClass}>
            <div className="counter__content">
                <span 
                    className="counter__value">{props.value}
                </span>
                <span 
                    className="counter__title" 
                    dangerouslySetInnerHTML={{ __html: props.title}} />
            </div>
        </div>
    );
}

export default Counter;