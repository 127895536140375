import * as apifetch from './fetch.js';

export async function checkBalanceSms() {
    const urlEndpoint = '/sms/checkBalance.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function checkViewsNewsletter(data) {
    const urlEndpoint = '/admin/analytics/checkViewsNewsletter.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function checkDeliveryNewsletter(data) {
    const urlEndpoint = '/admin/analytics/checkDeliveryNewsletter.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function checkLinksNewsletter(data) {
    const urlEndpoint = '/admin/analytics/checkLinksNewsletter.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getCountNewsletterUrls() {
    const urlEndpoint = '/v2/admin/newsletters/count';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getMailings() {
    const urlEndpoint = '/admin/analytics/getMailings.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getAccountTotal(data) {
    const urlEndpoint = '/admin/reports/getAccountTotal.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}
