import * as mobx from 'mobx';

export function handleScroll(pos) {
    if (pos === 'top') {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    } else {
        window.scrollTo({
            top: pos,
            behavior: "smooth"
        });
    }
}

export function checkMatchingValues(val1, val2) {
    let matching = (val1 === val2 && val1 !== '' && val2 !== '') ? true : false;
    return matching;
}

export function contains(a, obj) {
    for (var i = 0; i < a.length; i++) {
        if (a[i] === obj) {
            return true;
        }
    }
    return false;
}

// export function autoSave(store, save) {
//     mobx.autorun(() => {
//         const json = JSON.stringify(mobx.toJS(store));
//         save(json);
//     });
// }

// export function save(json) {
//     this.saveToLS(json);
// }

// export function saveToLS(json) {
//     localStorage.setItem('APP_USER', json)
// }