import * as apifetch from './fetch.js';

export async function copyBook(data) {
    const sku = data.book.isbn13;
    const urlEndpoint = '/v2/admin/catalog/copy/' + sku;
    
    const postData = {
        data: data
    }
    
    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function searchBook(data) {
    const urlEndpoint = '/v2/admin/catalog/search/' + data.sku;

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getCategories(shop) {
    const urlEndpoint = '/catalog/getCategories.php';

    const postData = {
        shop: shop
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

