import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import PropTypes from 'prop-types';
import i18n from "i18next";


import Checkbox from '../../elements/form-input-checkbox';
import ButtonDialog from '../../elements/button-dialog';

const DialogMailing = inject("stores") (
    observer (
        class DialogMailing extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    formControls: {
                        confirm: {
                            label: 'Ja ik weet het zeker',
                            value: 0,
                            touched: false,
                            valid: true
                        }
                    },
                    btn_class: '--disabled',
                }
            }

            componentDidMount = () => {
                document.addEventListener("keydown", this.escFunction, false);
            }
            
            componentWillUnmount = () => {
                document.removeEventListener("keydown", this.escFunction, false);
            }

            escFunction = (event) => {
                if (event.keyCode === 27) {
                    this.props.onClose();
                }
            }

            handleCheckboxChange = (event) => {
                const name = 'confirm';

                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        [name]: {
                            ...this.state.formControls[name],
                            value: (parseInt(this.state.formControls[name].value) === 1) ? 0 : 1,
                            selected: (parseInt(this.state.formControls[name].value) === 1) ? 0 : 1
                        }
                    },
                    btn_class: this.state.formControls[name].value == 1 ? '--disabled' : ''
                });
            }

            render() {
                if(!this.props.show) {
                    return null;
                }

                return (
                    <div className="overlay">
                        <div className="dialog dialog--confirmation">
                            <div className="dialog-header">
                                <h2>{this.props.title}</h2>
                            </div>
                            <div>
                                Titel: <strong>{this.storeUi.active_mailing_title}</strong><br/>
                                Mailing: {this.storeUi.active_mailing}<br/>
                                <Checkbox 
                                    name = 'confirm' 
                                    label = {this.state.formControls['confirm'].label}
                                    value = {this.state.formControls['confirm'].value}
                                    isSelected = {this.state.formControls['confirm'].value}
                                    onCheckboxChange={this.handleCheckboxChange}
                                />
                            </div>
                            {this.props.buttons &&
                                <div className="dialog-footer">
                                    {this.props.buttons.map((button, i) => (
                                        <ButtonDialog
                                            key = {i}
                                            caption = {button.text}
                                            onAction = {button.type !== 'cancel' ? (this.state.btn_class !== '--disabled' && button.onClick) : button.onClick}
                                            btnClass = {button.btnClass == '--primary' ? ("--primary " + this.state.btn_class) : button.btnClass}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                );
            }
        }
    )
)


export default DialogMailing;
