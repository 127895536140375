import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';

const ReportOverviewPartnerPayouts = inject("stores") (
    observer (
        class ReportOverviewPartnerPayouts extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storeUi = this.props.stores.storeUi;
                this.storePartners = this.props.stores.storePartners;
                
                this.state = {
                    id: 0,
                    partner: [],
                    payouts: []
                }

                this.columns = [
                    {
                        'id': 1,
                        'name': '',
                        'displayValue': 'Datum',
                        'bem': ''
                    },{
                        'id': 2,
                        'name': '',
                        'displayValue': 'Omschrijving',
                        'bem': ''
                    },{
                        'id': 3,
                        'name': '',
                        'displayValue': 'Bedrag',
                        'bem': 'amount'
                    }
                ]
            }

            componentDidMount = async() => {
                this.setState({
                    id: this.props.id,
                    partner: this.props.partner,
                    payouts: this.props.payouts
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.id !== this.props.id) {
                    const payouts = await this.storePartners.getPartnerPayouts(this.props.id);

                    this.setState({
                        id: this.props.id,
                        partner: this.props.partner,
                        payouts: payouts
                    })
                } 
            }

            formatNumber = (price, decimals) => {
                let priceDisplay = parseFloat(price).toFixed(decimals);
                priceDisplay = priceDisplay.toString().replace('.',',');
                
                return priceDisplay;
            }

            formatDate = (date) => {
                const dateFormatted = moment(date.date).format(this.storeUi.format_date_simple)

                return dateFormatted;
            }

            calcTotalPayouts = () => {
                let total = 0;
                total = this.storePartners.calcTotalPayouts(this.state.payouts);

                return total;
            }

            render() {
                return (  
                    <div className="content-section--report">  
                        <div className="content-section--report__header">
                            <div className="content-section--report__columns">
                                {this.columns.map((column) => (
                                    <div className={"content-section--report__column" + (column.bem !== '' ? " content-section--report__column--" + column.bem : "")}>
                                        {column.displayValue}
                                    </div>
                                ))}
                            </div>
                        </div>  
                        {this.state.payouts && this.state.payouts.length > 0 &&
                            <div className="content-section--report__section">
                                {this.state.payouts.map((payout) => (
                                    !payout.description.startsWith("Correctie") &&
                                        <div className="content-section--report__columns content-section--report__columns--zebra">
                                            <div className="content-section--report__column">
                                                {this.formatDate(payout.date)}
                                            </div>
                                            <div className="content-section--report__column">
                                                {payout.description}
                                            </div>
                                            <div className="content-section--report__column content-section--report__column--amount">
                                                {this.formatNumber(payout.amount, 2)}
                                            </div>
                                        </div>
                                ))}
                            </div>
                        }
                        {this.state.payouts && this.state.payouts.length > 0
                        ?   <div className="content-section--report__section">
                                <div className="content-section--report__columns">
                                    <div className="content-section--report__column">

                                    </div>
                                    <div className="content-section--report__column">
                                        Totaal
                                    </div>
                                    <div className="content-section--report__column content-section--report__column--amount">
                                        {this.calcTotalPayouts()}
                                    </div>
                                </div>
                            </div>
                        :   <div className="content-section--report__section">
                                <div className="content-section--report__columns">
                                    <div className="content-section--report__column content-section--report__column--no-content">
                                        Er zijn nog geen uitbetalingen geweest
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )
            }
        }
    )
)




export default withTranslation()(withRouter(ReportOverviewPartnerPayouts));
