import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import TextInput from '../../elements/form-input-text';

const ReportOverviewPartnerPayout = inject("stores") (
    observer (
        class ReportOverviewPartnerPayout extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storeUi = this.props.stores.storeUi;
                this.storePartners = this.props.stores.storePartners;
                
                const endOfQuarter =  this.getLastDayOfQuarterMonth();

                this.state = {
                    formControls: {
                        settleDate: {
                            label: 'Uitbetalen t/m',
                            value: endOfQuarter,
                            placeholder: 'Vul datum in DD-MM-YYYY',
                        },
                        payout: {
                            label: 'Uitbetaling',
                            value: '',
                            placeholder: 'Vul bedrag in',
                        },
                        description: {
                            label: 'Omschrijving',
                            value: '',
                            placeholder: 'Omschrijving'
                        }
                    }
                }
            }

            componentDidMount = async() => {
                this.setState({
                    partner: this.props.partner
                })
            }

            componentDidUpdate = async(prevProps) => {
                
            }

            getLastDayOfQuarterMonth = () => {
                const date = moment().format('MM-DD-YYYY');
                const lastDay = moment().quarter(moment(date).quarter()).endOf('quarter').format('DD-MM-YYYY');

                return lastDay;
            }

            formatNumber = (price, decimals) => {
                const priceFormatted = parseFloat(price).toFixed(decimals);
                    
                return priceFormatted;
            }

            formatDate = (date) => {
                const dateFormatted = moment(date.date).format(this.storeUi.format_date_simple)

                return dateFormatted;
            }

            calcTotalCommissionsOpen = () => {
                let total = 0;
                let commissions;
                let payouts;
                
                commissions = this.storePartners.calcTotalCommissions(this.props.partner, this.props.sales);
                commissions = commissions.replace(',','.');
                commissions = parseFloat(commissions);

                payouts = this.storePartners.calcTotalPayouts(this.props.payouts);
                payouts = payouts.replace(',','.');
                payouts = parseFloat(payouts);

                total = commissions - payouts;
                total = this.formatNumber(total, 2);
                
                return total;
            }

            calcTotalOpenUntilSettleDate = () => {
                let total = 0;
                let commission = 0;
                let settleDate = this.state.formControls.settleDate.value;
                let settleDateLast = this.props.settleDateLast;

                if (!settleDateLast) {
                    this.props.sales && this.props.sales.map((sale) => {
                        if (moment(sale.date.date).isSameOrBefore(moment(settleDate, 'DD-MM-YYYY'))) {
                            commission = this.storePartners.handleCommission(this.props.partner, sale, 6);
                            commission = commission.replace(',','.');
                            commission = parseFloat(commission);

                            total = total + commission;
                        }
                    })
                }

                if (settleDateLast) {
                    settleDateLast && this.props.sales && this.props.sales.map((sale) => {
                        let saleDateFormatted = moment(sale.date.date).format('YYYY-MM-DD');
                        let settleDateFormatted = moment(settleDate,'DD-MM-YYYY').format('YYYY-MM-DD');
                        if (moment(sale.date.date).isAfter(moment(settleDateLast.date)) && moment(saleDateFormatted).isSameOrBefore(settleDateFormatted)) {
                            commission = this.storePartners.handleCommission(this.props.partner, sale, 6);
                            commission = commission.replace(',','.');
                            commission = parseFloat(commission);

                            total = total + commission;
                        }
                    })
                }

                //SbC in case payout less than open
                // let totalOpen = this.props.sales && this.calcTotalCommissionsOpen();
                // total = total + totalOpen;

                total = this.formatNumber(total, 2);
                total = total.replace('.',',');
                
                return total;
            }

            handleChange = (event) => {     
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            submitData = (e) => {
                e.preventDefault();
                this.storePartners.addPartnerPayout(this.props.partnerId, this.state.formControls)
            }

            render() {
                return (  
                    <div className="content-section--report__section">
                        {Object.keys(this.state.formControls).map((key) => (
                            <React.Fragment>
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column">
                                    {this.state.formControls[key].label}
                                </div>
                                <div className="content-section--report__column">
                                    <TextInput 
                                        name = {key} 
                                        value = {this.state.formControls[key].value}
                                        onChange = {(e) => this.handleChange(e)}
                                        touched = {this.state.formControls[key].touched}
                                    />
                                </div>
                            </div>
                            {key === 'settleDate' &&
                                <div className="content-section--report__columns --open">
                                    <div className="content-section--report__column">
                                        Openstaand van 'Verrekend tot' tot 'Uitbetalen t/m'
                                    </div>
                                    <div className="content-section--report__column">
                                        {this.calcTotalOpenUntilSettleDate()}
                                    </div>
                                </div>
                            }
                            </React.Fragment>
                        ))}

                        <div className="content-section--report__columns">
                            <div className="content-section--report__column">
                                <div className="content--section__form-actions">
                                    <button className="btn btn--primary" onClick={(e) => this.submitData(e)}>Opslaan</button>
                                </div>
                            </div>
                        </div>

                        {this.storePartners.form_feedback && this.storePartners.form_feedback.msg && this.storePartners.form_feedback.msg.success &&
                            <div className="form__feedback">
                                <span className="form__feedback--success">{this.storePartners.form_feedback.msg.success}</span>
                            </div>
                        }
                        
                    </div>
                )
            }
        }
    )
)




export default withTranslation()(withRouter(ReportOverviewPartnerPayout));
