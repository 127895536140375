import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Link } from 'react-router-dom';

const ReportOverviewPartnerSummary = inject("stores") (
    observer (
        class ReportOverviewPartnerSummary extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storeUi = this.props.stores.storeUi;
                this.storeReports = this.props.stores.storeReports;
                this.storePartners = this.props.stores.storePartners;

                this.state = {
                    partner: [],
                    hasOpen: false
                }
            }

            componentDidMount = async() => {
                const partner = await this.storePartners.getPartnerSummary(this.props.id);

                this.setState({
                    partner: partner
                }, () => this.checkHasOpen())
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.id !== this.props.id) {
                    const partner = await this.storePartners.getPartnerSummary(this.props.id);

                    this.setState({
                        partner: partner
                    }, () => this.checkHasOpen())
                }
            }

            checkHasOpen = () => {
                const value = this.state.partner.commissions - this.state.partner.amountPayedOut;

                this.setState({
                    hasOpen: value > 0 ? true : false
                })
            }

            calcOpenCommission = () => {
                const value = this.state.partner.commissions - this.state.partner.amountPayedOut;
                const openCommission = this.formatNumber(value, 2);

                return openCommission;
            }

            formatNumber = (price, decimals) => {
                let priceDisplay = parseFloat(price).toFixed(decimals);
                priceDisplay = priceDisplay.toString().replace('.',',');
                
                return priceDisplay;
            }

            formatDate = (date) => {
                const dateFormatted = date && moment(date.date)
                    ?   moment(date.date).format(this.storeUi.format_date_simple)
                    :   ''

                return dateFormatted;
            }

            render() {
                return (  
                    <div className={"content-section--report__columns content-section--report__columns--zebra" + (this.state.hasOpen ? " --open" : "")}>
                        <div className="content-section--report__column">
                            <Link to={"/admin-partner/" + this.state.partner.accountId}>{this.state.partner.accountId}</Link>
                        </div>
                        <div className="content-section--report__column">
                            {this.state.partner.lastname}, {this.state.partner.middlename !== '' && this.state.partner.middlename !== null ? this.state.partner.middlename + ',' : ''} {this.state.partner.firstname}
                        </div>
                        <div className="content-section--report__column">
                            {this.state.partner.company}
                        </div>
                        <div className="content-section--report__column">
                            {this.state.partner.vatNumber}
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {/* {this.state.partner.vatNumber && this.state.partner.total !== null */}
                            {this.state.partner.total !== null
                                ?   this.formatNumber(this.state.partner.total, 2)
                                :   '-'
                            }
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {/* {!this.state.partner.vatNumber && this.state.partner.totalExVat !== null */}
                            {this.state.partner.totalExVat !== null
                                ?   this.formatNumber(this.state.partner.totalExVat, 2)
                                :   '-'
                            }
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {this.state.partner.commissions !== null
                                ?   this.formatNumber(this.state.partner.commissions, 2)
                                :   '-'
                            }
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {this.state.partner.settledDate !== null
                                ?   this.formatDate(this.state.partner.settledDate)
                                :   '-'
                            }
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {this.state.partner.amountPayedOut !== null
                                ?   this.formatNumber(this.state.partner.amountPayedOut, 2)
                                :   '-'
                            }
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {this.calcOpenCommission()}
                        </div>
                        
                    </div>
                )
            }
        }
    )
)




export default withTranslation()(withRouter(ReportOverviewPartnerSummary));
