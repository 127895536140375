import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';

import AccountMutations from '../../components/analytics/account-mutations';

const ReportAccounts = inject("stores") (
    observer (
        class ReportAccounts extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeAnalytics = this.props.stores.storeAnalytics;
                
                this.state = {
                    year: 2021
                }
            }

            componentDidMount = async() => {
                this.selectYear(2021);
            }

            componentDidUpdate = async() => {
            }

            selectYear = (year) => {
                this.setState({
                    year: year
                })
                this.storeAnalytics.setWeeks(year);
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Rapport Accounts</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Wijzigingen in accounts {this.state.year}</h2>
                                    <div className="content--section__form-actions">
                                        <button className="btn btn--secundary" onClick={() => this.selectYear(2020)}>2020</button>
                                        <button className="btn btn--secundary" onClick={() => this.selectYear(2021)}>2021</button>
                                    </div>
                                </div>
                               
                                <div className="content--section__columns">
                                    <div className="content--section__table table--counters table--zebra-custom">
                                        <div className="table--counters__row table--counters__row--header">
                                            <div className="table--counters__column table--counters__column--date">datum</div>
                                            <div className="table--counters__column">totaal accounts</div>
                                            <div className="table--counters__column">nieuwe accounts pw</div>
                                            <div className="table--counters__column">totaal subscriptions<br/>t/m datum</div>
                                            <div className="table--counters__column">aanmeldingen nieuwsbrief<br/>per week</div>
                                            <div className="table--counters__column">afmeldingen nieuwsbrief<br/>per week</div>
                                        </div>
                                        {this.storeAnalytics.weeks && this.storeAnalytics.weeks.map((week, idx) => (
                                            <React.Fragment>
                                            {idx === 0 &&
                                                <AccountMutations
                                                    year = {this.state.year}
                                                    week = {week}
                                                    view = {'counter'} 
                                                />
                                            }
                                            {idx !== 0 &&
                                                <AccountMutations
                                                    year = {this.state.year}
                                                    week = {week}
                                                    view = {'number'} 
                                                />
                                             }
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(ReportAccounts));
