import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import TextInput from '../../elements/form-input-text';

const FormSearchClient = inject("stores") (
    observer (
        class FormSearchClient extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeCustomers = this.props.stores.storeCustomers;
                
                this.state = {
                    formControls: {
                        query: {
                            value: ''
                        }
                    }
                }

                this.filters = [
                    {
                        filter: 'all',
                        display_value: 'Alle klanten',
                        title: 'Alle klanten'
                    }
                ];
            }

            handleSearch = (event) => {
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            submitSearch = async() => {
                this.props.submitSearch(this.state.formControls.query.value);
            }

            showAllClients = async() => {
                if (this.props.form) {
                    this.props.showAllClients();
                } else {
                    const path = '/admin-customers/';
                    this.props.history.push(path);
                }
            }

            render() {
                return (                  
                    <div className="content--section__form-actions">
                        {this.props.form &&
                            <React.Fragment>
                                <span>Zoek klant:</span>
                                {Object.keys(this.state.formControls).map((key) => (
                                    <li className="block block-edit__listitem">
                                        <span className="block block-edit__listitem--field">
                                            <TextInput 
                                                name = {key} 
                                                value = {this.state.formControls[key].value}
                                                onChange = {(e) => this.handleSearch(e)}
                                                touched = {this.state.formControls[key].touched}
                                            />
                                        </span>       
                                    </li>
                                ))}
                                <button className="btn btn--secundary" onClick={() => this.submitSearch()}>Zoek</button>
                            </React.Fragment>
                        }
                        <button className="btn btn--secundary" onClick={() => this.showAllClients(this.filters[0].filter)}>{this.filters[0].display_value}</button>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(FormSearchClient));
