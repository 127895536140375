import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import NewsletterCreate from '../../components/newsletter/newsletter-create';
import NewsletterShow from '../../components/newsletter/newsletter-show';
import NewsletterSend from '../../components/newsletter/newsletter-send';

const Newsletters = inject("stores") (
    observer (
        class Newsletters extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeDocs = this.props.stores.storeDocs;
            }

            componentDidMount = async() => {
                
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'mailings'
                        feature = 'nlsend'
                        title = 'Nieuwsbrief versturen'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Nieuwsbrief maken & versturen</h1>
                            </div>
                              
                            <NewsletterCreate

                            />

                            <NewsletterShow

                            />

                            <NewsletterSend

                            />
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Newsletters));
