import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import TextInput from '../../elements/form-input-text';

const ToolSrtToVtt = inject("stores") (
    observer (
        class ToolTToolSrtToVttranslation extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;

                this.storeContent = this.props.stores.storeContent;
                
                this.state = {
                    content: '',
                    formControls: {
                        filename: {
                            value: '',
                            placeholder: "Vul eventueel filename in"
                        }
                    }
                }                   
            }

            componentDidMount = () => {
        
            }

            handleChange = (event, cname, cvalue) => { 
                let name;
                let value;
                    
                if (cname) {
                    name = cname;
                } else {
                    name = event.target.name;
                }
                
                if (cvalue || cvalue === '') {
                    value = cvalue;
                } else {
                    value = event.target.value;
                }

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            handleFileChosen = (e) => {
                const file = e.target.files[0];
                const filename = file.name.replace(/\.[^/.]+$/, "");

                let fileReader;

                const handleFileRead = (e) => {
                    const content = fileReader.result;
                    this.setState({
                        filename: filename,
                        content: content
                    })
                }               
                
                fileReader = new FileReader();
                fileReader.onloadend = handleFileRead;
                fileReader.readAsText(file);
            }

            convertFile = () => {
                let content = this.state.content;
                content = this.removeWhiteLines(content);
                content = this.convertCues(content);

                this.setState({
                    content: content
                })

                console.log('SbC content converted')
            }

            saveFile = (e) => {
                const data = this.state.content;
                let filename = this.state.filename;

                if (this.state.formControls['filename'].value !== '') {
                    filename = this.state.formControls['filename'].value;
                }
                
                this.storeContent.saveVtt(filename, data);
            }

            removeWhiteLines = (content) => {
                // remove newlines
                let srt = content.replace(/\r+/g, '');
                // trim white space start and end
                srt = srt.replace(/^\s+|\s+$/g, '');

                //console.log('SbC content', srt)
                return srt;
            }

            convertCues = (content) => {
                // SbC get cues
                const cuelist = content.split('\n\n');
                let result = "";

                if (cuelist.length > 0) {
                    result += "WEBVTT\n\n";
                    for (let i = 0; i < cuelist.length; i=i+1) {
                        result += this.convertSrtCue(cuelist[i]);
                    }
                }

                return result;
            }

            convertSrtCue(caption) {
                // remove all html tags for security reasons
                //srt = srt.replace(/<[a-zA-Z\/][^>]*>/g, '');
      
                var cue = "";
                var s = caption.split(/\n/);
      
                // concatenate muilt-line string separated in array into one
                while (s.length > 3) {
                    for (var i = 3; i < s.length; i++) {
                        s[2] += "\n" + s[i]
                    }
                    s.splice(3, s.length - 3);
                }
      
                var line = 0;
      
                // detect identifier
                if (!s[0].match(/\d+:\d+:\d+/) && s[1].match(/\d+:\d+:\d+/)) {
                  cue += s[0].match(/\w+/) + "\n";
                  line += 1;
                }
      
                // get time strings
                if (s[line].match(/\d+:\d+:\d+/)) {
                  // convert time string
                  var m = s[1].match(/(\d+):(\d+):(\d+)(?:,(\d+))?\s*--?>\s*(\d+):(\d+):(\d+)(?:,(\d+))?/);
                  if (m) {
                    cue += m[1]+":"+m[2]+":"+m[3]+"."+m[4]+" --> "
                          +m[5]+":"+m[6]+":"+m[7]+"."+m[8]+"\n";
                    line += 1;
                  } else {
                    // Unrecognized timestring
                    return "";
                  }
                } else {
                  // file format error or comment lines
                  return "";
                }
      
                // get cue text
                if (s[line]) {
                  cue += s[line] + "\n\n";
                }
      
                return cue;
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'tools'
                        feature = 'subtitles'
                        title = 'Ondertiteling'
                    >   
                        <div className="wrapper--content__header">
                            <h1>Ondertiteling SRT naar VTT</h1>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Converteer SRT-file</h2>
                            </div>
                            <div className="content--section__columns">
                                <div className="content--section__column">
                                    <h2>Selecteer SRT-file</h2>
                                    <input 
                                        type="file" 
                                        onChange = {(e) => this.handleFileChosen(e)}
                                    />
                                    <div className="content--section__form-actions">
                                        <button className="btn btn--primary" onClick={(e) => this.convertFile(e)}>Converteer file naar VTT</button>
                                    </div>
                                </div>
                            </div>
                            <div className="content--section__columns">
                                <div className="content--section__column">
                                    <h2>Geef eventueel nieuwe naam VTT-file</h2>
                                    <p>
                                        Indien niet ingevuld wordt de originele naam gebruikt<br/>
                                        De file wordt opgeslagen in: api/public/videos/cc
                                    </p>
                                    <div 
                                        className="content--section__form-field"
                                    >
                                        <TextInput 
                                            name = {'filename'} 
                                            value = {this.state.formControls['filename'].value}
                                            onChange = {(e) => this.handleChange(e)}
                                            touched = {this.state.formControls['filename'].touched}
                                        />
                                    </div>
                                    <div 
                                        className="content--section__form-actions"
                                    >
                                        <button className="btn btn--primary" onClick={(e) => this.saveFile(e)}>Save VTT file</button>
                                    </div>
                                </div>
                            </div>
                        </div>                     
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(ToolSrtToVtt));
