import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Link } from 'react-router-dom';
import localization from 'moment/locale/nl';

const MyParcelRow = inject("stores") (
    observer (
        class MyParcelRow extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                    items: {}
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
            }

            componentDidMount = async() => {
                let items = await this.storeOrders.getTrackTraceOrder(this.props.order.order_id);
                this.setState({
                    items: items
                });
            }

            // refreshBarcodes = async() => {
            //     let items = await this.storeOrders.getTrackTraceOrder(this.props.order.order_id);
            //     this.setState({
            //         items: items
            //     });
            // }

            render() {
                return (                  
                    <tr>
                        <td>
                            <span className="table--orders__cell-label">{moment(this.props.order.order_date.date).format("DD-MM-YYYY")}</span>
                            <span className="table--orders__cell-value">
                                <Link to={"/myparcel/" + this.props.order.order_id}>
                                    {this.props.order.order_id}
                                </Link>
                            </span>
                            {/* <span className="table--orders__cell-button"onClick={() => this.refreshBarcodes()}>ververs</span> */}
                        </td>
                        <td>
                            <span className="table--orders__cell-value">{this.props.order.order_name}</span>
                            <span className="table--orders__cell-value">{this.props.order.order_email}</span>
                        </td>
                        <td>
                            {/* <span className="table--orders__cell-label">single:</span> */}
                            <span className="table--orders__cell-value">{this.props.order.barcode}</span>
                            {/* <span className="table--orders__cell-label">multiple:</span> */}
                            {/* {this.state.items.length > 0 && this.state.items.map((item) => ( 
                                <span className="table--orders__cell-value">{item.barcode}</span>
                            ))} */}
                        </td>
                    </tr>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(MyParcelRow));
