import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const ListNURs = inject("stores") (
    observer (
        class ListNURs extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                    nav_catnurs: []
                }

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = async() => {
                let list_nurs = await this.storeUi.getListNURs();
                console.log('Sbc list NURS::', list_nurs)
                this.setState({
                    nav_catnurs: list_nurs
                });
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.nurs !== this.props.nurs) {
                    this.setState({
                        nav_catnurs: this.storeUi.nav_catnurs
                    })
                }
            }

            checkIfMainCat(cat) {
                const idx = this.storeUi.nav_categories
                    .findIndex(nav_cat => nav_cat.id === cat && nav_cat.is_main_category === 1)
                return idx
            }

            render() {
                return (                  
                    <div>
                        <ul className="list--bullets list--nurs">
                            {this.storeUi.list_nurs.map((nur) => (
                                <li>
                                    <span>{nur.id}</span>
                                    <span>{nur.description} ({nur.count})</span>
                                    {this.state.nav_catnurs.map((catnur) => (
                                        catnur.nur === nur.id &&
                                            <span className={"list__id list__id" + (this.checkIfMainCat(catnur.cat_sb) !== -1 && '--cat')}>
                                                {catnur.cat_sb}
                                            </span>
                                    ))}
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(ListNURs));
