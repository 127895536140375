import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const NavigationCategories = inject("stores") (
    observer (
        class NavigationCategories extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                    nav_cats: [],
                    nav_catnurs: []
                }

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.categories !== this.props.categories) {
                    this.setState({
                        nav_cats: this.storeUi.nav_categories
                    })
                }

                if (prevProps.nurs !== this.props.nurs) {
                    this.setState({
                        nav_catnurs: this.storeUi.nav_catnurs
                    })
                }
            }

            deleteCategory = (is_main_category, cat, subcat) => {
                this.storeUi.deleteCategory(is_main_category, cat, subcat);
            }

            deleteNUR = (cat, nur) => {
                this.storeUi.deleteNUR(cat, nur);
            }

            render() {
                return (                  
                    <div>
                        <ul className="list--preview">
                            {this.state.nav_cats.map((cat) => (
                                cat.is_main_category === 1 &&
                                    <li>
                                        <span className="list__id list__id--cat">
                                            <span>
                                                <span dangerouslySetInnerHTML={{ __html: cat.id}} />
                                                <span className="list__id--delete --link" onClick={() => this.deleteCategory(1, cat.id)}>x</span>
                                            </span>
                                        </span>
                                        <span>{cat.display_value}</span>
                                        {this.state.nav_catnurs.map((catnur) => (
                                            parseInt(catnur.cat_sb) === parseInt(cat.id) &&
                                                <span className="list__id list__id--nur">
                                                    <span>
                                                        {catnur.nur}<span className="list__id--delete --link" onClick={() => this.deleteNUR(cat.id, catnur.nur)}>x</span>
                                                    </span>
                                                </span>
                                        ))}
                                        <ul className="list--preview">
                                            {this.state.nav_cats.map((subcat) => (
                                                subcat.is_main_category !== 1 && parseInt(subcat.main_category) === parseInt(cat.id) &&
                                                    <li>
                                                        <span className="list__id list__id--subcat">
                                                            <span>
                                                            {subcat.id}<span className="list__id--delete --link" onClick={() => this.deleteCategory(0, subcat.main_category, subcat.id)}>x</span>
                                                            </span>
                                                        </span>
                                                        <span>{subcat.display_value}</span>
                                                        {this.state.nav_catnurs.map((catnur) => (
                                                            parseInt(catnur.cat_sb) === parseInt(subcat.id) &&
                                                                <span className="list__id list__id--nur">
                                                                    <span>
                                                                        {catnur.nur}<span className="list__id--delete --link" onClick={() => this.deleteNUR(subcat.id, catnur.nur)}>x</span>
                                                                    </span>
                                                                </span>
                                                        ))}
                                                    </li>
                                            ))}
                                        </ul>
                                    </li>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(NavigationCategories));
