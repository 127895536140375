import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';

const TemplateForm = inject("stores") (
    observer (
        class TemplateForm extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                }

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount() {
            }
      
            render() {
                return (
                    <div className="wrapper--form">
                        <form>
                            {this.props.children}
                        </form>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(TemplateForm);
