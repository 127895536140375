import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Link } from 'react-router-dom';

import TextInput from '../../elements/form-input-text';

const ReportOverviewPartnerChecksSummary = inject("stores") (
    observer (
        class ReportOverviewPartnerChecksSummary extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storeUi = this.props.stores.storeUi;
                this.storeReports = this.props.stores.storeReports;
                this.storePartners = this.props.stores.storePartners;

                this.state = {
                    formControls: {
                        code: {
                            label: 'Code',
                            value: '',
                            placeholder: ''
                        },
                        comment: {
                            label: 'Opmerking',
                            value: '',
                            placeholder: 'Opmerking'
                        }
                    },
                    partner: [],
                    hasOpen: false
                }
            }

            componentDidMount = async() => {
                const partner = await this.storePartners.getPartnerSummary(this.props.id);
                const checks = await this.storePartners.getPartnerChecks(this.props.id);

                this.setState({
                    partner: partner,
                    checks: checks
                }, () => this.initCode())
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.id !== this.props.id) {
                    const partner = await this.storePartners.getPartnerSummary(this.props.id);
                    const checks = await this.storePartners.getPartnerChecks(this.props.id);

                    this.setState({
                        partner: partner,
                        checks: checks
                    }, () => this.initCode())
                }
            }

            formatDate = (date) => {
                const dateFormatted = date && moment(date.date)
                    ?   moment(date.date).format(this.storeUi.format_date_simple)
                    :   ''

                return dateFormatted;
            }

            initCode = () => {     
                const name = 'code';
                const value = this.state.checks && this.state.checks.checked;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            handleChange = (event) => {     
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            handleUpdate = (key) => {
                const value = this.state.formControls[key].value;
                this.updatePartnerChecks(key, value);
            }

            updatePartnerChecks = async(key, value) => {
                const id = this.state.partner.accountId;
                const fb = await this.storePartners.updatePartnerChecks(id, key, value);

                const checks = await this.storePartners.getPartnerChecks(this.props.id);

                this.setState({
                    checks: checks
                })
            }

            render() {
                return (  
                    <div className={"content-section--report__columns content-section--report__columns--checks content-section--report__columns--zebra" + " --error-" + this.state.formControls.code.value}>
                        <div className="content-section--report__column">
                            <Link to={"/admin-partner/" + this.state.partner.accountId}>{this.state.partner.accountId}</Link>
                        </div>
                        <div>
                           
                        </div>
                        <div className="content-section--report__column">
                            {this.state.partner.lastname}, {this.state.partner.middlename !== '' && this.state.partner.middlename !== null ? this.state.partner.middlename + ',' : ''} {this.state.partner.firstname}
                        </div>
                        <div className="content-section--report__column">
                            {this.state.partner.company}
                        </div>
                        <div>
                            {this.state.checks && this.state.checks.dateCheck !== null
                                ?   this.formatDate(this.state.checks.dateCheck)
                                :   '-'
                            }
                        </div>
                        <div>
                            {this.state.checks && this.state.checks.checked 
                            ?   this.state.checks.checked
                            : '-'
                            }
                            <div className="content--section__form-actions">
                                <TextInput 
                                    name = {'code'} 
                                    value = {this.state.formControls['code'].value}
                                    onChange = {(e) => this.handleChange(e)}
                                    touched = {this.state.formControls['code'].touched}
                                />
                                <button className="btn btn--primary" onClick={(e) => this.handleUpdate('code')}>Update</button>
                            </div>
                        </div>
                        <div>
                            {this.state.checks && this.state.checks.comment
                            ?   this.state.checks.comment
                            :   '-'
                            }
                            <div className="content--section__form-actions">
                                <TextInput 
                                    name = {'comment'} 
                                    value = {this.state.formControls['comment'].value}
                                    onChange = {(e) => this.handleChange(e)}
                                    touched = {this.state.formControls['comment'].touched}
                                />
                                <button className="btn btn--primary" onClick={(e) => this.handleUpdate('comment')}>Update</button>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)




export default withTranslation()(withRouter(ReportOverviewPartnerChecksSummary));
