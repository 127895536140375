export async function sbFetch(endPoint, method, data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndPoint = urlAPI + endPoint;
    let params;

    if (method == 'DELETE') {
        params = {
            method: method,
            headers: new Headers({

            })
        }
    }

    if (method == 'GET') {
        params = {
            method: method,
            headers: new Headers({

            })
        }
    } 

    if (method == 'POST') {
        const postData = data;
        params = {
            method: method,
            body: JSON.stringify(data),
            headers: new Headers({

            })
        }
    } 

    if (method == 'PUT') {
        const postData = data;
        params = {
            method: method,
            body: JSON.stringify(data),
            headers: new Headers({

            })
        }
    } 

    try {
        let response = await fetch(urlEndPoint, params);
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error: ', error.message, urlEndPoint, data);
    }
}

export function abortFetch(endPoint, method, data, controller, signal) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndPoint = urlAPI + endPoint;
    
    const promise = new Promise(async (resolve) => {
        try {
            const response = await fetch(urlEndPoint, {
                method: method,
                body: JSON.stringify(data),
                signal
            });
            const data2 = await response.json();
            //assertIsCharacter(data);
            resolve(data2);
        } catch (error) {
            console.log('SbC fetch abort error')
        }
    });

    promise.cancel = () => controller.abort();

    return promise;
}