import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const BlockBookCategoriesSB = inject("stores") (
    observer (
        class BlockBookCategoriesSB extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    cats: []
                }
            }

            componentDidMount = async() => {
                const cats = await this.storeBooks.getCategories();
                this.setState({
                    cats: cats
                })
            }

            handleClickCat = (e, name, value) => {
                this.props.handleClickCat(e, name, value.name);
            }

            render() {
                return (                  
                    <ul className="list list--bullets">
                        {this.state.cats && this.state.cats.map((cat) => (
                            <li
                                className = "--link"
                                onClick = {(e) => this.handleClickCat(e, 'category', cat)}
                            >
                                <span>{cat.name}</span>
                            </li>
                        ))}
                    </ul>                               
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockBookCategoriesSB));
