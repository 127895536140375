import * as apifetch from './fetch.js';

export async function getReportPartners() {
    const urlEndpoint = '/v2/admin/reports/partners2';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function initPartners() {
    const urlEndpoint = '/v2/admin/reports/init-partners';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getReportPartnersOverview(sortValue, sortOrder) {
    let urlEndpoint;

    if (!sortValue) {
        urlEndpoint = '/v2/admin/reports/partners';

        return apifetch.sbFetch(urlEndpoint, 'GET');
    } else {
        urlEndpoint = '/v2/admin/reports/partners/sort';

        const postData = {
            sortValue: sortValue,
            sortOrder: sortOrder
        };

        return apifetch.sbFetch(urlEndpoint, 'POST', postData);
    }
}

export async function getReportPartnerChecksOverview(sortValue, sortOrder) {
    let urlEndpoint;

    if (!sortValue) {
        urlEndpoint = '/v2/admin/reports/partnerchecks';

        return apifetch.sbFetch(urlEndpoint, 'GET');
    } else {
        urlEndpoint = '/v2/admin/reports/partnerchecks/sort';

        const postData = {
            sortValue: sortValue,
            sortOrder: sortOrder
        };

        return apifetch.sbFetch(urlEndpoint, 'POST', postData);
    }
}

export async function getPartnerSummary(id) {
    const urlEndpoint = '/v2/admin/reports/partners/' + id + '/summary';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getPartnerChecks(id) {
    const urlEndpoint = '/v2/admin/reports/partners/' + id + '/checks';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function updatePartnerChecks(id, key, value) {
    const urlEndpoint = '/v2/admin/reports/partners/' + id + '/checks';

    const postData = {
        id: id,
        key: key,
        value: value
    }

    console.log('SbC check', postData)

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getPartner(id) {
    const urlEndpoint = '/v2/admin/reports/partners/' + id;

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getPartnerOpenCommissions(id) {
    const urlEndpoint = '/v2/admin/reports/partners/' + id + '/open';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getPartnerFees() {
    const urlEndpoint = '/v2/admin/reports/fees';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getPartnerAllSales(id) {
    const urlEndpoint = '/v2/admin/reports/partners/' + id + '/sales';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export function getPartnerAllSalesWithAbort(id, controller, signal) {
    const urlEndpoint = '/v2/admin/reports/partners/' + id + '/sales';

    return apifetch.abortFetch(urlEndpoint, 'GET', controller, signal);
}

export async function getPartnerPayouts(id) {
    const urlEndpoint = '/v2/admin/reports/partners/' + id + '/payouts';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function addPartnerPayout(id, data) {
    const urlEndpoint = '/v2/admin/reports/partners/' + id + '/payout';

    const postData = data

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}
