import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import BlockPromoBook from './block-promobook';

const BlockPromoBooks = inject("stores") (
    observer (
        class BlockPromoBooks extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                
                this.state = {
                    promoBooks: []
                }
            }

            componentDidMount = async() => {
                let promoBooks = await this.storeContent.getPromoBooks(); 

                this.setState({
                    promoBooks: promoBooks
                })
            }

            updatePromoBooks = (id, formControls) => {
                let promoBooks = this.state.promoBooks;

                const sku = formControls.sku.value;
                const title = formControls.title.value;

                let idx = promoBooks.findIndex(item => item.id === id);
                promoBooks[idx].sku = sku;
                promoBooks[idx].title = title;

                this.setState({
                    promoBooks: promoBooks
                })
            }

            submitData = (e) => {
                this.storeContent.updatePromoBooks(this.state.promoBooks)
            }

            render() {

                return (                  
                    <React.Fragment>
                        {this.state.promoBooks && this.state.promoBooks.map((promoBook) => (
                            promoBook.location === 'Title' && 
                                <BlockPromoBook
                                    promoBook = {promoBook}
                                    updatePromoBooks = {(id, e) => this.updatePromoBooks(id, e)}
                                    title = {true}
                                />
                        ))}
                        <ul className="block block-edit__list">
                            {this.state.promoBooks && this.state.promoBooks.map((promoBook) => (
                                promoBook.location !== 'Title' && 
                                    <li className="block block-edit__listitem">
                                        <BlockPromoBook
                                            promoBook = {promoBook}
                                            updatePromoBooks = {(id, e) => this.updatePromoBooks(id, e)}
                                        />
                                    </li>
                            ))}
                        </ul>

                        <div className="content--section__form-actions">
                            <button className="btn btn--primary" onClick={(e) => this.submitData(e)}>Update promo books</button>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockPromoBooks));
