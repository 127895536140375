import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import TextInput from '../../elements/form-input-text';
import Counter from '../ui/counter';
import GraphBars from '../ui/graph-bars';

const NewsletterViews = inject("stores") (
    observer (
        class NewsletterViews extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeAnalytics = this.props.stores.storeAnalytics;
                
                this.state = {
                    mailings: [],
                    tag: '',
                    views: {
                        count_pdf_email: 0,
                        count_php_email: 0,
                        count_pdf_site: 0,
                        count_php_site: 0
                    }
                }
            }

            componentDidMount = async() => {
                const views = await this.storeAnalytics.checkViewsNewsletter(this.props.tag);
                const count = views.count_pdf_email + views.count_php_email + views.count_pdf_site + views.count_php_site;
                this.setState({
                    tag: this.props.tag,
                    views: views,
                    count: count
                })
            }

            componentDidUpdate = async() => {

            }

            render() {
                return (                  
                    <div className="content--section__table-row">
                        <div className="table--counters__column">
                            {this.props.tag}
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.count}
                                    title = {''}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.count
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.views.count_pdf_email}
                                    title = {'email<br/>Pdf'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.views.count_pdf_email
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.views.count_php_email}
                                    title = {'email<br/>PHP'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.views.count_php_email
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.views.count_pdf_site}
                                    title = {'site<br/>Pdf'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.views.count_pdf_site
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.views.count_php_site}
                                    title = {'site<br/>PHP'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.views.count_php_site
                            }
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(NewsletterViews));
