import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';

const TemplateCard = inject("stores") (
    observer (
        class TemplateCard extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    class_card: '--collapsed'
                }

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount() {
            }

            toggleCard = () => {
                this.setState({
                    class_card: (this.state.class_card === '--collapsed') ? '--expanded' : '--collapsed'
                })
            }
      
            render() {
                return (
                    <div className="container--block">
                        <div className="container--block__title" onClick={(e) => this.toggleCard()}>
                            {Object.keys(this.props.card).map((key, i) => (
                                <React.Fragment>
                                    {key == 'title' &&
                                        <dd className="dd--title">
                                            <span dangerouslySetInnerHTML={{ __html: this.props.card[key]}} />
                                        </dd>
                                    }
                                </React.Fragment>
                            ))}
                        </div>
                        <div className={"container--block__list " + this.state.class_card}>
                            {this.props.children}
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(TemplateCard);
