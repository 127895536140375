import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import TemplateForm from '../../templates/template-form';
import TextInput from '../../elements/form-input-text';

const FormNewsletterSelect = inject("stores") (
    observer (
        class FormNewsletterSelect extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                
                this.state = {
                    formControls: {
                        day: {
                            label: 'Dag',
                            value: '',
                            placeholder: 'Vul dag in',
                        }
                    },
                    week_dir: '',
                    week_file: '',
                    week_tag: '',
                    mailing_exists: false,
                    newsletter: {}
                }
            }

            handleChange = (event, action) => {     
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            submitData = (e) => {
                e.preventDefault();
                this.setPublicationsTags(this.state.formControls.day.value);
            }

            setPublicationsTags = (value) => {
                const current_date = moment(value, "DD MMM").format("DD MMM");
                const day = value.split(' ');
                const year = moment(current_date,"DD MMM").format('GG');
                let week_nr = moment(current_date,"DD MMM").format('WW');
                const month_nr = moment(current_date, "DD MMM").format('M');

                let date = year + '-'+ week_nr;
                let friday_nr = moment(date, "YYYY-w").weekday(4).format("LL");
                let day_in_week = moment(current_date,"DD MMM" ).weekday(); 

                let friday_nr2;
                let friday;
                let strDir;  
                let strFile;
                let strWeek;             
                let month_idx;
                let month_name;

                if (day_in_week > 4) {
                    //SbC friday or saturday, next publication day is next weeks friday
                    week_nr = moment(current_date,"DD MMM").add(7, 'days').format('WW');
                    friday_nr2 = moment(friday_nr, 'LL').add(7, 'days');
                    month_idx = moment(friday_nr2, 'LL').format("MM");
                    month_name = moment.monthsShort('-MMM-', month_idx - 1);
                    strDir = 'wk' + year + week_nr + '-' + moment(friday_nr2, 'LL').format("D") + month_name; 
                    strFile = 'wk' + year + week_nr + '-' + moment(friday_nr2, 'LL').format("D") + month_name + '/wk' + year + week_nr;
                    friday = moment(friday_nr2, 'LL').format("D") + ' ' + month_name;
                } else {
                    //SbC taking into account that publication friday could be in next month
                    month_idx = moment(friday_nr, 'LL').format("MM");
                    month_name = moment.monthsShort('-MMM-', month_idx - 1);
                    strDir = 'wk' + year + week_nr + '-' + moment(friday_nr, 'LL').format("D") + month_name; 
                    strFile = 'wk' + year + week_nr + '-' + moment(friday_nr, 'LL').format("D") + month_name + '/wk' + year + week_nr; 
                    strWeek = 'wk' + year + week_nr; 
                    friday = moment(friday_nr, 'LL').format("D") + ' ' + month_name;
                }
                
                //SbC set mailing tag
                const mailing = 'mailing';
                let strTag = mailing + year + week_nr;

                this.setState({
                    wk: year + week_nr,
                    week_dir: strDir,
                    week_file: strFile,
                    week_str: strWeek,
                    week_tag: strTag,
                    friday: friday
                }, () => this.checkMailingExists())
            }

            setTitleField = () => {
                const name = 'title';
                const value = this.state.newsletter.title;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
                
                this.setState({
                    formControls: updatedControls
                })
            }

            checkMailingExists = async() => {
                let exists = true;
                let newsletter = {};
                exists = await this.storeUi.checkMailingExists(this.state.week_dir);
                
                if (exists) {
                    newsletter = await this.storeUi.getNewsletter(this.state.week_tag);
                    this.setTitleField();

                }
                this.setState({
                    newsletter: newsletter,
                    mailing_exists: exists
                })

                this.props.setSectionValue(this.props.section, 'wk', this.state.wk);
                this.props.setSectionValue(this.props.section, 'week_dir', this.state.week_dir);
                this.props.setSectionValue(this.props.section, 'week_str', this.state.week_str);
                this.props.setSectionValue(this.props.section, 'week_tag', this.state.week_tag);
                this.props.setSectionValue(this.props.section, 'newsletter', newsletter);
            }

            render() {
                return (                  
                    <TemplateForm
                        type = 'default'
                    >   
                        <div className="content--section__form">
                            <h3>Selecteer een datum</h3>
                            <div>Vul datum in (bijv 4 aug):</div>
                            <input 
                                type = "text" 
                                name = "day"
                                onChange = {this.handleChange}
                            />

                            <div className="content--section__form-actions">
                                <button class="btn btn--primary" onClick={(e) => this.submitData(e)}>Gegevens ophalen</button>
                            </div>

                            Eerstvolgende vrijdag van publicatie:<br/>
                            {this.state.friday}<br/>
                            <br/>
                            Bijbehorende nieuwsbrief:<br/>
                            Titel: <strong>{this.state.newsletter && this.state.newsletter.title}</strong><br/>
                            File name: {this.state.week_file} (pdf en html)<br/>
                            Tag mailing: {this.state.week_tag}

                            {this.state.mailing_exists
                            ?   <div className="content-text --success">
                                    Deze nieuwsbrief bestaat reeds.
                                </div>
                            :   <div className="content-text --error">
                                    {this.state.formControls.day.value === ''
                                        ?   "Vul een datum in"
                                        :   "Deze nieuwsbrief bestaat nog niet"
                                    }
                                </div>
                            }
                        </div>
                    </TemplateForm>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(FormNewsletterSelect));
