import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Redirect } from 'react-router';

import Branding from './branding';

const Header = inject("stores") (
    observer (
        class Header extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    redirect: false,
                    sb_env: process.env.REACT_APP_CUSTOM_NODE_ENV
                } 

                this.storeUi = this.props.stores.storeUi;
            }

            handleLogout = (e, path) => {
                this.storeUi.setLoginState(false);

                const url = "/";
                this.props.history.push(url);
                this.setState({
                    redirect: true
                })
            }

            goto = () => {
                 //SbC goto ADMIN ABANDA
                let url;
                if (this.state.sb_env === 'DEV') {
                    url = "https://localhost:3002/"
                }
                if (this.state.sb_env === 'STAGING') {
                    url = "http://staging-admin-abanda.succesboeken.info"
                }
                if (this.state.sb_env === 'PROD') {
                    url = "http://admin-abanda.succesboeken.info"
                }
                window.open(url);
            }

            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/login"} 
                    />;
                }

                return (
                    <div className="wrapper-header">
                        <Branding />
                        <div className="header__specs">
                            <div>ADMIN SUCCESBOEKEN.NL</div>
                            <div>OMGEVING: {this.state.sb_env}</div>
                            <div className="--link" onClick={(e)=>this.handleLogout()}>Logout</div>
                            <div className="--link" onClick={(e)=>this.goto()}>Naar admin Abanda</div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(Header);
