import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as orders from '../communicator/orders';

class StoreOrders {
    form_feedback = '';
    orders = [];

    order_states = [
        {
            id: 1,
            sku: 'WOB',
            display_value: 'Wacht op betaling'
        },{
            id: 2,
            sku: 'WOO',
            display_value: 'Wacht op overschijving'
        },{
            id: 3,
            sku: 'WOBCB',
            display_value: 'Wacht op bestelling CB'
        },{
            id: 4,
            sku: 'WOLCB',
            display_value: 'Wacht op levering CB'
        },{
            id: 5,
            sku: 'WOS',
            display_value: 'Wacht op verzending'
        },{
            id: 6,
            sku: 'SHIPPED',
            display_value: 'Verzonden'
        }
    ]

    payment_states = [
        {
            id: 1,
            sku: 'PAYED',
            display_value: 'Betaald'
        },{
            id: 2,
            sku: 'PAYEDVIABANKTRANSFER',
            display_value: 'Betaald via overschijving'
        }
    ]

    constructor() {
        
    }

    async getAllOrders(client_id) {
        let orders_all;
        if (!client_id) {
            client_id = 0;
        } 
        try {
            let returnData = await orders.getAllOrders(client_id);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.orders = returnData.data;
                    orders_all = returnData.data;
                } else {

                } 
                return orders_all;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return orders_all;
    }

    async getOrders(scope) {
        let orders_all;

        try {
            let returnData = await orders.getOrders(scope);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.orders = returnData.data;
                    orders_all = returnData.data;
                } else {

                } 
                return orders_all;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return orders_all;
    }

    async getOrder(order_id) {
        let order;

        try {
            let returnData = await orders.getOrder(order_id);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    order = returnData.data;
                } else {

                } 
                return order;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return order;
    }

    async getOrderErrors(orderId) {
        let result = [];

        try {
            let returnData = await orders.getOrderErrors(orderId);
            runInAction(() => {
                if (!('error' in returnData.data)) {
                    result = returnData.data.errors;
                } else {
                } 
                return result;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return result;
    }

    async getOrderItemsData(order_id) {
        let order_items = {};
        try {
            let returnData = await orders.getOrderItemsData(order_id);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    order_items = returnData.data;
                    return order_items;
                } else {

                } 
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return order_items;
    }

    async submitOrderToMyParcel(order_id, colli, packaging) {
        const data = {
            order_id: order_id,
            colli: colli,
            packaging: packaging
        }
        try {
            let returnData = await orders.submitOrderToMyParcel(data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {

                } else {

                } 
            })
        } catch (error) {
            runInAction(() => {

            })
        }
    }

    async getTrackTraceOrders() {
        try {
            let returnData = await orders.getTrackTraceOrders();
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.orders = returnData.data;
                } else {

                } 
            })
        } catch (error) {
            runInAction(() => {

            })
        }
    }

    async getTrackTraceOrder(order_id) {
        let order = {};
        const data = {
            order_id: order_id
        }
        try {
            let returnData = await orders.getTrackTraceOrder(data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    order = returnData.data;
                } else {

                } 
                return order;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return order;
    }

    async getAddressData(order_id) {
        let address = {};
        const data = {
            order_id: order_id
        }
        try {
            let returnData = await orders.getAddressData(data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                   address = returnData.data;
                } else {

                } 
                return address;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return address;
    }

    async getAfterDeliveryOrders() {
        let orders_after_delivery;
        try {
            let returnData = await orders.getAfterDeliveryOrders();
            runInAction(() => {
                orders_after_delivery = returnData.data;
                return orders_after_delivery;
            })
        } catch (error) {
            runInAction(() => {
            })
        }
        return orders_after_delivery;
    }
    
    async setAfterDelivery(action, order_id, orderline_id) {
        const data = {
            action: action,
            order_id: order_id,
            orderline_id: orderline_id
        }
        try {
            let returnData = await orders.setAfterDelivery(data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                } else {

                } 
            })
        } catch (error) {
            runInAction(() => {
            })
        }
    }

    async setOrderStatus(order_id, status, date) {
        let value_date = '';
        if (date) {
            value_date = date;
        } 
        const data = {
            order_id: order_id,
            status: status,
            date: value_date
        }
        try {
            let returnData = await orders.setOrderStatus(data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                } else {

                } 
            })
        } catch (error) {
            runInAction(() => {
            })
        }
    }

    async submitTrackTraceToDB(order_id, tracktrace_number) {
        const data = {
            order_id: order_id,
            tracktrace: tracktrace_number
        }
        try {
            let returnData = await orders.setTrackTrace(data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {

                } else {

                } 
            })
        } catch (error) {
            runInAction(() => {

            })
        }
    }

}

decorate(StoreOrders, {
    form_feedback: observable,
    orders: observable,
    getAddressData: action,
    getAllOrders: action,
    getOrderErrors: action,
    getTrackTraceOrder: action,
    getTrackTraceOrders: action,
    getAfterDeliveryOrders: action,
    setAfterDelivery: action,
    setOrderStatus: action,
    submitOrderToMyParcel: action,
    submitTrackTraceToDB: action
})

export default StoreOrders;