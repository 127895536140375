import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import ReportOverviewPartnerSalesRow from './report-overview-partner-sales-row';

const ReportOverviewPartnerSales = inject("stores") (
    observer (
        class ReportOverviewPartnerSales extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storeUi = this.props.stores.storeUi;
                this.storePartners = this.props.stores.storePartners;
                
                this.state = {
                    partner: [],
                    sales: []
                }

                this.columns = [
                    {
                        'id': 1,
                        'name': '',
                        'displayValue': 'Datum',
                        'bem': ''
                    },{
                        'id': 2,
                        'name': '',
                        'displayValue': 'Omschrijving',
                        'bem': ''
                    },{
                        'id': 3,
                        'name': '',
                        'displayValue': 'Factuur',
                        'bem': ''
                    },{
                        'id': 4,
                        'name': '',
                        'displayValue': 'Shop',
                        'bem': ''
                    },{
                        'id': 5,
                        'name': '',
                        'displayValue': 'Aantal',
                        'bem': 'amount'
                    },{
                        'id': 6,
                        'name': '',
                        'displayValue': 'Bedrag',
                        'bem': 'amount'
                    },{
                        'id': 7,
                        'name': '',
                        'displayValue': '%',
                        'bem': 'amount'
                    },{
                        'id': 8,
                        'name': '',
                        'displayValue': 'Vergoeding',
                        'bem': 'amount'
                    }]
            }

            componentDidMount = async() => {
                this.setState({
                    partner: this.props.partner
                })
            }

            componentDidUpdate = async(prevProps) => {
                let settleDate;

                if (prevProps.id !== this.props.id) {
                    const sales = await this.storePartners.getPartnerAllSales(this.props.id);
                    const payouts = await this.storePartners.getPartnerPayouts(this.props.id);
                    const partnerFees = await this.storePartners.getPartnerFees();

                    if (payouts && payouts[0] && payouts[0].settled_date) {
                        settleDate = payouts[0].settled_date;
                    } else {
                        settleDate = '';
                    }

                    this.setState({
                        id: this.props.id,
                        partner: this.props.partner,
                        sales: sales,
                        settleDate: settleDate,
                        partnerFees: partnerFees
                    })
                } 
            }

            formatNumber = (price, decimals) => {
                const priceFormatted = parseFloat(price).toFixed(decimals);
                    
                return priceFormatted;
            }

            formatDate = (date) => {
                const dateFormatted = moment(date.date).format(this.storeUi.format_date_simple)

                return dateFormatted;
            }

            calcTotalNr = () => {
                let total = 0;
                this.state.sales.map((sale) => {
                    total = total + sale.amount;
                })

                return total;
            }

            calcTotalSales = () => {
                let total = 0;
                total = this.storePartners.calcTotalSales(this.state.partner, this.state.sales);
                
                return total;
            }

            calcTotalCommissions = () => {
                let total = 0;
                total = this.storePartners.calcTotalCommissions(this.state.partner, this.state.sales);
                
                return total;
            }

            checkSettleDate = (date) => {
                let classOpen = '';

                if (this.state.settleDate !== '') {
                    if (moment(date.date).isAfter(moment(this.state.settleDate.date))) {
                        classOpen = ' --open';
                    }
                } else {
                    classOpen = ' --open';
                }

                return classOpen;
            }

            render() {
                return (  
                    <div className="content-section--report">     
                        <div className="content-section--report__header">
                            <div className="content-section--report__columns">
                                {this.columns.map((column) => (
                                    <div className={"content-section--report__column" + (column.bem !== '' ? " content-section--report__column--" + column.bem : "")}>
                                        {column.displayValue}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="content-section--report__section">
                            {this.state.sales.map((sale) => (
                                <ReportOverviewPartnerSalesRow
                                    id = {this.props.id}
                                    partner = {this.props.partner}
                                    partnerFees = {this.state.partnerFees}
                                    sale = {sale}
                                    settleDate = {this.state.settleDate}
                                />
                                // <div className={"content-section--report__columns content-section--report__columns--zebra" + this.checkSettleDate(sale.date)}>
                                //     <div className="content-section--report__column">
                                //         {this.formatDate(sale.date)}
                                //     </div>
                                //     <div className="content-section--report__column">
                                //         {sale.description}
                                //     </div>
                                //     <div className="content-section--report__column">
                                //         {sale.invoice}
                                //     </div>
                                //     <div className="content-section--report__column">
                                //         {parseInt(sale.bookId) > 200000 ? 2 : 1}
                                //     </div>
                                //     <div className="content-section--report__column content-section--report__column--amount">
                                //         {sale.amount}
                                //     </div>
                                //     <div className="content-section--report__column content-section--report__column--amount">
                                //         {this.storePartners.handleSale(this.props.partner, sale)}&nbsp;
                                //     </div>
                                //     <div className="content-section--report__column content-section--report__column--amount">
                                //         {parseInt(sale.commissionSB) === 1
                                //             ?   sale.bookId === '4925'
                                //                 //SbC custom perc for event entry Naomi Wolf
                                //                 ?   <span>20 %</span>
                                //                 :   (sale.commission_current !== '' && sale.commission_current !== null)
                                //                     ?   <span>{this.formatNumber(sale.commission_current, 0)} %</span>
                                //                     :   <span>{this.formatNumber(this.props.partner.commission, 0)} %</span>
                                //             :   (sale.commission_3party_current !== '' && sale.commission_3party_current !== null)
                                //                 ?   <span>{this.formatNumber(sale.commission_3party_current, 0)} %</span>
                                //                 :   <span>{this.formatNumber(this.props.partner.commission_3party, 0)} %</span>
                                //         }
                                //     </div>
                                //     <div className="content-section--report__column content-section--report__column--amount">
                                //         {this.storePartners.handleCommission(this.props.partner, sale, 2)}
                                //     </div>
                                // </div>
                             ))}
                        </div>
                        <div className="content-section--report__section">
                            <div className="content-section--report__columns">
                                <div className="content-section--report__column" />
                                <div className="content-section--report__column" />
                                <div className="content-section--report__column" />
                                <div className="content-section--report__column">
                                    Totaal
                                </div>
                                <div className="content-section--report__column content-section--report__column--amount">
                                    {this.calcTotalNr()}
                                </div>
                                <div className="content-section--report__column content-section--report__column--amount">
                                    {this.calcTotalSales()}
                                </div>
                                <div className="content-section--report__column" />
                                <div className="content-section--report__column content-section--report__column--amount">
                                    {this.calcTotalCommissions()}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)




export default withTranslation()(withRouter(ReportOverviewPartnerSales));
