import * as apifetch from './fetch.js';

export async function sendLinkDownload(data) {
    const urlEndpoint = '/email/sendLinkDownload.php';

    const postData = {
        data: data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function sendConfirmPartner(data) {
    const urlEndpoint = '/email/sendConfirmPartner.php';

    const postData = {
        data: data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function sendTestEmail(data) {
    const urlEndpoint = data.url;

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

