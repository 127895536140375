import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Link } from 'react-router-dom';

import CellOrderAddress from './cell-order-address';
import CellOrderSummary from './cell-order-summary';
import CellOrderStatus from './cell-order-status';

const CustomersRow = inject("stores") (
    observer (
        class CustomersRow extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                    items: []
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeCustomer = this.props.stores.storeCustomers;
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = async(prevProps) => {

            }

            render() {
                return (       
                    <tr className="table--orders__row">
                        <td colspan="5">
                            <Link 
                                to = {{ 
                                    pathname: "/admin-customer/" + this.props.customer.client_id,
                                    client_id: this.props.customer.client_id
                                }}
                            >
                                {this.props.customer.client_id}
                            </Link>
                        </td>
                        <td>
                            {this.props.customer.firstname} {this.props.customer.lastname}
                        </td>
                        <td>
                            {this.props.customer.email}
                        </td>
                    </tr>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CustomersRow));
