import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Link } from 'react-router-dom';

import Template from '../../templates/template';
import NewsletterDelivery from '../../components/analytics/newsletter-delivery';
import NewsletterViews from '../../components/analytics/newsletter-views';
import NewsletterLinks from '../../components/analytics/newsletter-links';
import NewsletterLinksUrls from '../../components/analytics/newsletter-links-urls';
import NewsletterLinksVideos from '../../components/analytics/newsletter-links-videos';

const ReportNewsletterLinks = inject("stores") (
    observer (
        class ReportNewsletterLinks extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeAnalytics = this.props.stores.storeAnalytics;
                
                this.state = {
                    tag: '',
                }
            }

            componentDidMount = async() => {
                const mailings = await this.storeAnalytics.getMailings();

                const tag = this.storeUi.settings_list['tagLatestNewsletter'];
                if (this.state.tag === '' && tag !== null && this.state.tag !== tag) {
                    //const views = await this.storeAnalytics.checkViewsNewsletter(tag);
                    //const delivery = await this.storeAnalytics.checkDeliveryNewsletter(tag);
                    this.setState({
                        tag: tag
                    })
                }
            }

            componentDidUpdate = async() => {
                const tag = this.storeUi.settings_list['tagLatestNewsletter'];
                if (this.state.tag === '' && tag !== null && this.state.tag !== tag) {
                    //const views = await this.storeAnalytics.checkViewsNewsletter(tag);
                    //const delivery = await this.storeAnalytics.checkDeliveryNewsletter(tag);
                    this.setState({
                        tag: tag
                    })
                }

                if (this.storeAnalytics.mailings && this.storeAnalytics.mailings.length === 0) {
                    const mailings = await this.storeAnalytics.getMailings();
                }
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'analytics'
                        feature = 'newsletterlinks'
                        title = 'Externe links in nieuwsbrieven'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Rapport Nieuwsbrief</h1>
                                <ul>
                                    <li><Link to="/report-newsletter">Views</Link></li>
                                    <li><Link to="/report-newsletter-links">Link tellers</Link></li>
                                </ul>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Kliks op externe links in nieuwsbrief</h2>
                                </div>

                                <div className="content--section__columns">
                                    <div className="content--section__table table--counters table--zebra">
                                        <div className="table--counters__row table--counters__row--header">
                                            <div className="table--counters__column">mailing</div>
                                            <div className="table--counters__column">url</div>
                                            <div className="table--counters__column">totaal</div>
                                        </div>
                                        <NewsletterLinksUrls
                                            view = {'number'} 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Kliks op links in voorpagina nieuwsbrief</h2>
                                </div>

                                <div className="content--section__columns">
                                    <div className="content--section__table table--counters table--zebra">
                                        <div className="table--counters__row table--counters__row--header">
                                            <div className="table--counters__column">mailing</div>
                                            <div className="table--counters__column">aanbevolen boek</div>
                                            <div className="table--counters__column">home</div>
                                            <div className="table--counters__column">partner worden</div>
                                            <div className="table--counters__column">recent</div>
                                            <div className="table--counters__column">ebooks</div>
                                            <div className="table--counters__column">facebook</div>
                                        </div>
                                        {this.storeAnalytics.mailings && this.storeAnalytics.mailings.map((mailing, idx) => (
                                            <React.Fragment>
                                            {idx === 0 &&
                                                <NewsletterLinks
                                                    tag = {mailing.tag}
                                                    view = {'counter'} 
                                                />
                                            }
                                            {idx !== 0 &&
                                                <NewsletterLinks
                                                    tag = {mailing.tag}
                                                    view = {'number'} 
                                                />
                                             }
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Kliks op video-links in nieuwsbrief</h2>
                                </div>

                                <div className="content--section__columns">
                                    <div className="content--section__table table--counters table--zebra">
                                        <div className="table--counters__row table--counters__row--header">
                                            <div className="table--counters__column">mailing</div>
                                            <div className="table--counters__column">video</div>
                                        </div>
                                        {this.storeAnalytics.mailings && this.storeAnalytics.mailings.map((mailing, idx) => (
                                            <React.Fragment>
                                            {idx === 0 &&
                                                <NewsletterLinksVideos
                                                    tag = {mailing.tag}
                                                    view = {'counter'} 
                                                />
                                            }
                                            {idx !== 0 &&
                                                <NewsletterLinksVideos
                                                    tag = {mailing.tag}
                                                    view = {'number'} 
                                                />
                                             }
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>                            
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(ReportNewsletterLinks));
