import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const CellOrderHistory = inject("stores") (
    observer (
        class CellOrderHistory extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                    order_cb: '',
                    order_paid: '',
                    order_onaccount: '',
                    order_fulfilled: ''
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
            }

            componentDidMount = async() => {
                // this.setState({
                //     order_cb: this.props.order.order_cb && this.props.order.order_cb.date,
                //     order_paid: this.props.order.order_paid && this.props.order.order_paid.date,
                //     order_onaccount: this.props.order.order_onaccount && this.props.order.order_onaccount.date,
                //     order_banktransfer: this.props.order.order_banktransfer && this.props.order.order_banktransfer.date,
                //     order_fulfilled: this.props.order.order_fulfilled && this.props.order.order_fulfilled.date
                // })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.order_paid !== this.props.order_paid) {
                    this.setState({
                        order_paid: this.props.order_paid,
                    })
                }
                if (prevProps.order_onaccount !== this.props.order_onaccount) {
                    this.setState({
                        order_onaccount: this.props.order_onaccount,
                    })
                }
                if (prevProps.order_banktransfer !== this.props.order_banktransfer) {
                    this.setState({
                        order_banktransfer: this.props.order_banktransfer
                    })
                }
                if (prevProps.order_fulfilled !== this.props.order_fulfilled) {
                    this.setState({
                        order_fulfilled: this.props.order_fulfilled
                    })
                }
                if (prevProps.order_cb !== this.props.order_cb) {
                    this.setState({
                        order_cb: this.props.order_cb
                    })
                }
            }

            render() {
                return (                  
                    <React.Fragment>
                        <span className="table--orders__cell-label">Besteld</span>
                        <span className="table--orders__cell-value">{moment(this.props.order.order_date.date).format(this.storeUi.format_date)}</span>
                        <span className="table--orders__cell-label">Betaald</span>
                        {this.state.order_paid !== null
                        ?   <span className="table--orders__cell-value">{this.state.order_paid && moment(this.state.order_paid.date).format(this.storeUi.format_date)}</span>
                        :   <span className="table--orders__cell-value">-</span>
                        }
                        <span className="table--orders__cell-label">Bankoverschrijving</span>
                        {this.state.order_banktransfer !== null
                        ?   <span className="table--orders__cell-value">{this.state.order_banktransfer && moment(this.state.order_banktransfer.date).format(this.storeUi.format_date)}</span>
                        :   <span className="table--orders__cell-value">-</span>
                        }
                        <span className="table--orders__cell-label">Besteld CB</span>
                        {this.state.order_cb !== null
                        ?   <span className="table--orders__cell-value">{this.state.order_cb && moment(this.state.order_cb.date).format(this.storeUi.format_date)}</span>
                        :   <span className="table--orders__cell-value">-</span>
                        }
                        <span className="table--orders__cell-label">Afgehandeld</span>
                        {this.state.order_fulfilled !== null
                        ?   <span className="table--orders__cell-value">{this.state.order_fulfilled && moment(this.state.order_fulfilled.date).format(this.storeUi.format_date)}</span>
                        :   <span className="table--orders__cell-value">-</span>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CellOrderHistory));
