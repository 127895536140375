import * as apifetch from './fetch.js';

export async function getNotifications() {
    const urlEndpoint = '/admin/notifications/getNotifications.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function updateNotifications(form_data) {
    const urlEndpoint = '/admin/notifications/updateNotifications.php';

    const postData = {
        form_data: form_data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}
