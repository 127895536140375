import * as apifetch from './fetch.js';

export async function getCategories() {
    const urlEndpoint = '/catalog/getCategories.php';  

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getOrdersByCategory(year, category) {
    const urlEndpoint = '/admin/reports/getOrdersByCategory.php';  

    const postData = {
        year: year,
        category: category
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getOrdersByMonth(year, month) {
    const urlEndpoint = '/admin/reports/getOrdersByMonth.php';  

    const postData = {
        year: year,
        month: month
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function checkOutOfStock() {
    const urlEndpoint = '/v2/admin/catalog/outofstock';  

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function removeFromSite(sku) {
    const urlEndpoint = '/v2/admin/catalog/' + sku + '/hide';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function setAlternative(data) {
    const urlEndpoint = '/v2/admin/catalog/' + data.sku + '/alternative';  
    
    const postData = {
        data: data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

