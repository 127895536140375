import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import BlockDownload from '../../components/blocks/block-download';

const LinkDownload = inject("stores") (
    observer (
        class LinkDownload extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeEmail = this.props.stores.storeEmail;

            }

            componentDidMount = () => {
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <BlockDownload
                                link = {"placebo"}
                            />
                            <BlockDownload
                                link = {"doorbreek"}
                            />
                        </React.Fragment> 
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(LinkDownload));
