import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import TextInput from '../../elements/form-input-text';

const SettingsAdmin = inject("stores") (
    observer (
        class SettingsAdmin extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                
                this.state = {
                    formControls: {
                        
                    }
                }
            }

            componentDidMount = async() => {
                let settings = await this.storeUi.getSettingsAdmin(); 
                this.setSettings();
            }

            setSettings = () => {
                let settings = this.storeUi.settingsAdmin_list;
                let setting = {};

                const updatedControls = {
                    ...this.state.formControls
                };

                console.log('SbC settings SB to state::', settings)
                Object.keys(settings).map((key) => {
                    updatedControls[key] = {
                        value: this.storeUi.settingsAdmin_list[key]
                    }
                })

                this.setState({
                    formControls: updatedControls
                })                
            }

            handleChangeSetting = (event) => {     
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            submitData = (e) => {
                this.storeUi.updateSettingsAdmin(this.state.formControls)
            }

            render() {

                return (                  
                    <React.Fragment>
                        <ul className="block block-edit__list">
                            {Object.keys(this.state.formControls).map((key) => (
                                <li className="block block-edit__listitem">
                                    <span className="block block-edit__listitem--label">{key}</span>
                                    <span className="block block-edit__listitem--field">
                                        <TextInput 
                                            name = {key} 
                                            value = {this.state.formControls[key].value}
                                            onChange = {(e) => this.handleChangeSetting(e)}
                                            touched = {this.state.formControls[key].touched}
                                        />
                                    </span>
                                </li>
                            ))}
                        </ul>

                        <div className="content--section__form-actions">
                            <button className="btn btn--primary" onClick={(e) => this.submitData(e)}>Update settings</button>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(SettingsAdmin));
