import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import ReportOverviewPartnerSummary2 from './report-overview-partner-summary2';

const ReportOverviewPartners2 = inject("stores") (
    observer (
        class ReportOverviewPartners2 extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storePartners = this.props.stores.storePartners;
                
                this.state = {
                    partners: []
                }

                this.columns = [
                    {
                        'id': 1,
                        'name': '',
                        'displayValue': 'ID',
                        'bem': ''
                    },{
                        'id': 2,
                        'name': '',
                        'displayValue': 'Naam',
                        'bem': ''
                    },{
                        'id': 3,
                        'name': '',
                        'displayValue': 'Bedrijfsnaam',
                        'bem': ''
                    },{
                        'id': 4,
                        'name': '',
                        'displayValue': 'Verrekend tot',
                        'bem': ''
                    },{
                        'id': 5,
                        'name': '',
                        'displayValue': 'Uitbetaald',
                        'bem': 'amount'
                    },{
                        'id': 6,
                        'name': '',
                        'displayValue': 'Commissie',
                        'bem': 'amount'
                    },{
                        'id': 7,
                        'name': '',
                        'displayValue': 'Totaal',
                        'bem': 'amount'
                    }]
            }

            componentDidMount = async() => {
                const partners = await this.storePartners.getReportPartners2();

                this.setState({
                    partners: partners
                })
            }

            componentDidUpdate = async(prevProps) => {
                
            }

            render() {
                return (
                    <div className="content-section--report">
                        <div className="content-section--report__header">
                            <div className="content-section--report__columns">
                                {this.columns.map((column) => (
                                    <div className={"content-section--report__column" + (column.bem !== '' ? " content-section--report__column--" + column.bem : "")}>
                                        {column.displayValue}
                                    </div>
                                ))}
                            </div>
                        </div>
                        
                        {this.state.partners.map((partner) => (
                            <ReportOverviewPartnerSummary2 
                                id = {partner.account_id}
                                partner = {partner}
                            />
                        ))}
                    </div>
                )
            }
        }
    )
)




export default withTranslation()(withRouter(ReportOverviewPartners2));
