import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';
import ReportOverviewPartner from '../components/partners/report-overview-partner';
import ReportOverviewPartnerSales from '../components/partners/report-overview-partner-sales';
import ReportOverviewPartnerPayouts from '../components/partners/report-overview-partner-payouts';

const AdminPartner = inject("stores") (
    observer (
        class AdminPartner extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storePartners = this.props.stores.storePartners;
                
                this.state = {
                    formControls: {
                        
                    },
                    id: 0,
                    partner: {}
                }
            }

            componentDidMount = async() => {
                window.scrollTo(0, 0);

                const id = this.props.match.params.id;
                const partner = await this.storePartners.getPartner(id);

                this.setState({
                    id: id,
                    partner: partner
                })
            }

            formatDate = (date) => {
                const dateFormatted = moment(date.date).format(this.storeUi.format_date_simple)

                return dateFormatted;
            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Overzicht partner: {this.state.partner.account_id}</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Overzicht</h2>

                                    <ReportOverviewPartner 
                                        id = {this.state.id}
                                        partner = {this.state.partner}
                                    />
                                    
                                    <div className="content--section__form-actions">
                                       
                                    </div>
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Overzicht alle verkopen & openstaande commissies</h2>
                                    {this.state.partner.vat_nr && this.state.partner.vat_nr !== null && this.state.partner.vat_nr !== undefined && this.state.partner.vat_nr !== ''
                                    ?   <React.Fragment>
                                            <span>Bedragen incl. Btw</span>
                                            {this.state.partner.vatNumberFrom &&
                                                <span> vanaf: {this.formatDate(this.state.partner.vatNumberFrom)}</span>
                                            }
                                        </React.Fragment>
                                    :   <React.Fragment>
                                        <span>Bedragen excl. Btw</span><br/>
                                        {this.state.partner.vatNumberUntil &&
                                            <span>Bedragen incl. Btw tot: {this.formatDate(this.state.partner.vatNumberUntil)}</span>
                                        }
                                        </React.Fragment>
                                    }

                                    <ReportOverviewPartnerSales 
                                        id = {this.state.id}
                                        partner = {this.state.partner}
                                    />
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Overzicht uitbetaalde vergoedingen</h2>
                                    {this.state.partner.vat_nr !== undefined && this.state.partner.vat_nr !== ''
                                    ?   <span>(bedragen incl. Btw)</span>
                                    :   <span>(bedragen excl. Btw)</span>
                                    }

                                    <ReportOverviewPartnerPayouts 
                                        id = {this.state.id}
                                        partner = {this.state.partner}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminPartner));
