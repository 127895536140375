import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as wiki from '../communicator/wiki';

class StoreWiki {

    activeSection = ''
    activeFeature = ''
    activeFeatureTitle = ''

    constructor() {
    }

    async getSections() {
        let result;

        try {
            const returnData = await wiki.getSections();
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getFeatures(section) {
        let result;

        try {
            const returnData = await wiki.getFeatures(section);
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getFeature(feature) {
        let result;

        try {
            const returnData = await wiki.getFeature(feature);
            runInAction(() => {
                result = returnData.data[0];
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getFeatureBlocks(feature) {
        let result;

        try {
            const returnData = await wiki.getFeatureBlocks(feature);
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getFeatureProcedures(feature) {
        let result;

        try {
            const returnData = await wiki.getFeatureProcedures(feature);
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getFeatureDevelopment(feature) {
        let result;

        try {
            const returnData = await wiki.getFeatureDevelopment(feature);
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getFeatureRemarks(feature) {
        let result;

        try {
            const returnData = await wiki.getFeatureRemarks(feature);
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    setActiveSection(value) {
        this.activeSection = value;
    }

    setActiveFeature(value) {
        this.activeFeature = value;
    }

    setActiveFeatureTitle(value) {
        this.activeFeatureTitle = value;
    }

}

decorate(StoreWiki, {
    activeFeature: observable,
    activeFeatureTitle: observable,
    activeSection: observable,
    getFeatureDevelopment: action,
    getSections: action,
    setActiveSection: action,
    setActiveFeature: action,
    setActiveFeatureTitle: action
})

export default StoreWiki;