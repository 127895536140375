import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const CellOrderStatus = inject("stores") (
    observer (
        class CellOrderStatus extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                    order_cb: '',
                    order_paid: '',
                    order_onaccount: '',
                    order_fulfilled: ''
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
            }

            componentDidMount = async() => {
                this.setState({
                    order_cb: this.props.order.order_cb && this.props.order.order_cb.date,
                    order_paid: this.props.order.order_paid && this.props.order.order_paid.date,
                    order_onaccount: this.props.order.order_onaccount && this.props.order.order_onaccount.date,
                    order_banktransfer: this.props.order.order_banktransfer && this.props.order.order_banktransfer.date,
                    order_fulfilled: this.props.order.order_fulfilled && this.props.order.order_fulfilled.date
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.order_paid !== this.props.order_paid) {
                    this.setState({
                        order_paid: this.props.order_paid && this.props.order_paid.date,
                    })
                }
                if (prevProps.order_onaccount !== this.props.order_onaccount) {
                    this.setState({
                        order_onaccount: this.props.order_onaccount && this.props.order_onaccount.date,
                    })
                }
                if (prevProps.order_banktransfer !== this.props.order_banktransfer) {
                    this.setState({
                        order_banktransfer: this.props.order_banktransfer && this.props.order_banktransfer.date
                    })
                }
                if (prevProps.order_fulfilled !== this.props.order_fulfilled) {
                    this.setState({
                        order_fulfilled: this.props.order_fulfilled && this.props.order_fulfilled.date
                    })
                }
                if (prevProps.order_cb !== this.props.order_cb) {
                    this.setState({
                        order_cb: this.props.order_cb && this.props.order_cb.date
                    })
                }
            }

            render() {
                return (                  
                    <React.Fragment>
                        {(this.state.order_paid === null && this.state.order_fulfilled === null && this.state.order_onaccount === null && this.state.order_banktransfer === null) &&
                            <span className="table--orders__cell-value --unpaid">Deze order is door de klant (nog) niet afgemaakt</span>
                        }

                        {/* {(this.state.order_paid === null && this.state.order_fulfilled === null && this.state.order_onaccount === null) &&
                            <span className="table--orders__cell-value --unpaid">Deze order wacht op betaling</span>
                        } */}

                        {/* SbC banktransfer */}
                        {(this.state.order_paid === null && this.state.order_banktransfer !== null  && this.state.order_fulfilled === null) &&
                            <span className="table--orders__cell-value --unpaid">Deze order wacht op betaling</span>
                        }

                        {(this.state.order_paid !== null && this.state.order_fulfilled === null) &&
                            <div>
                                <span className="table--orders__cell-value --fulfilled">Betaald</span>
                                <span className="table--orders__cell-value --unpaid">Deze order wacht op verzending</span>
                            </div>
                        }

                        {(this.state.order_paid === null && this.state.order_fulfilled !== null && this.state.order_onaccount === null) &&
                            <div>
                                <span className="table--orders__cell-value --unpaid">Deze order wacht op betaling</span>
                                <span className="table--orders__cell-value --fulfilled">Verzonden</span>
                            </div>
                        }

                        {(this.state.order_paid === null && this.state.order_fulfilled !== null && this.state.order_onaccount !== null) &&
                            <div>
                                <span className="table--orders__cell-value --unpaid">Deze order wacht op betaling</span>
                                <span className="table--orders__cell-value --fulfilled">Verzonden (op rekening)</span>
                            </div>
                        }

                        {(this.state.order_paid === null && this.state.order_fulfilled === null && this.state.order_onaccount !== null) &&
                            <div>
                                <span className="table--orders__cell-value --unpaid">Deze order wacht op betaling</span>
                                <span className="table--orders__cell-value --onaccount">Deze order mag verzonden worden (op rekening)</span>
                            </div>
                        }

                        {(this.state.order_paid !== null && this.state.order_fulfilled !== null) &&
                            <div>
                                <span className="table--orders__cell-value --fulfilled">Betaald</span>
                                <span className="table--orders__cell-value --fulfilled">Verzonden</span>
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CellOrderStatus));
