import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import ReportOverviewPartnerSummary from './report-overview-partner-summary';

const ReportOverviewPartners = inject("stores") (
    observer (
        class ReportOverviewPartners extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storePartners = this.props.stores.storePartners;
                
                this.state = {
                    sortOrder: 'ASC',
                    partners: []
                }

                this.columns = [
                    {
                        'id': 1,
                        'name': '',
                        'displayValue': 'Account&nbsp;ID',
                        'bem': '',
                        'sortValue': 'accountId'
                    },{
                        'id': 2,
                        'name': '',
                        'displayValue': 'Naam',
                        'bem': '',
                        'sortValue': 'Lastname'
                    },{
                        'id': 3,
                        'name': '',
                        'displayValue': 'Bedrijfsnaam',
                        'bem': '',
                        'sortValue': 'Company'
                    },{
                        'id': 4,
                        'name': '',
                        'displayValue': 'Btw-nummer',
                        'bem': '',
                        'sortValue': 'VATNumber'
                    },{
                        'id': 6,
                        'name': '',
                        'displayValue': 'Totale omzet<br/>(incl Btw)',
                        'bem': 'amount',
                        'sortValue': 'Total'
                    },{
                        'id': 7,
                        'name': '',
                        'displayValue': 'Totale omzet<br/>(excl Btw)',
                        'bem': 'amount',
                        'sortValue': 'Total'
                    },{
                        'id': 8,
                        'name': '',
                        'displayValue': 'Totaal commissies',
                        'bem': 'amount',
                        'sortValue': 'Commissions'
                    },{
                        'id': 9,
                        'name': '',
                        'displayValue': 'Uitbetaald tot',
                        'bem': 'amount',
                        'sortValue': 'SettledDate'
                    },{
                        'id': 8,
                        'name': '',
                        'displayValue': 'Uitbetaald',
                        'bem': 'amount',
                        'sortValue': 'AmountPayedOut'
                    },{
                        'id': 10,
                        'name': '',
                        'displayValue': 'Openstaand',
                        'bem': 'amount',
                        'sortValue': 'OpenCommissions'
                    }]
            }

            componentDidMount = async() => {
                const partners = await this.storePartners.getReportPartnersOverview();

                this.setState({
                    partners: partners
                })
            }

            componentDidUpdate = async(prevProps) => {

            }

            handleSortColumn = async(sortValue) => {
                let sortOrder = this.state.sortOrder;
                if (this.state.sortOrder === 'ASC') {
                    sortOrder = 'DESC';
                } else {
                    sortOrder = 'ASC';
                }
                this.setState({
                    sortOrder: sortOrder
                })

                const partners = await this.storePartners.getReportPartnersOverview(sortValue, sortOrder);

                this.setState({
                    partners: partners
                })
            }

            render() {
                return (
                    <div className="content-section--report">
                        <div className="content-section--report__header">
                            <div className="content-section--report__columns">
                                {this.columns.map((column) => (
                                    <div 
                                        className={"content-section--report__column" + (column.bem !== '' ? " content-section--report__column--" + column.bem : "") + " --link"}
                                        onClick = {() => this.handleSortColumn(column.sortValue)}
                                        dangerouslySetInnerHTML={{ __html: column.displayValue}}
                                    />
                                ))}
                            </div>
                        </div>
                        
                        {this.state.partners && this.state.partners.map((partner) => (
                            <ReportOverviewPartnerSummary 
                                id = {partner.accountId}
                                partner = {partner}
                            />
                        ))}
                    </div>
                )
            }
        }
    )
)




export default withTranslation()(withRouter(ReportOverviewPartners));
