import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import * as ui from '../utilities/ui';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';
import Radio from '../elements/form-input-radio';

import MyParcelRow from '../components/tables/myparcel-row';
import { reaction } from 'mobx';

const MyParcel = inject("stores") (
    observer (
        class MyParcel extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
                
                this.state = {
                    formControls: {
                        order_id: {
                            label: 'Bestelnummer',
                            value: this.props.match.params.id ? this.props.match.params.id : '',
                            placeholder: 'Vul bestelnummer in'
                        },
                        tracktrace_number: {
                            label: 'Track&Trace nummer',
                            value: '',
                            placeholder: 'Vul Track&Trace nummer in'
                        },
                        packaging: {
                            label: 'Verpakking',
                            value: 0,
                            placeholder: 'Kies verpakking',
                            defaultValue: 0,
                            options: [
                                { value: 0, displayValue: 'Briefpost' },
                                { value: 1, displayValue: 'Pakket' }
                            ]
                        },
                        colli: {
                            label: 'Aantal pakketten',
                            value: '',
                            placeholder: 'Vul aantal in'                        
                        }
                    },
                    address: {},
                    tracktrace: '-',
                    class_feedback: ''
                }
            }

            componentDidMount = () => {
                this.storeOrders.getTrackTraceOrders();
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.match.params.id !== this.props.match.params.id) {
                    console.log('SbC update::', this.props.match.params.id)
                    const name = 'order_id';
                    const value = this.props.match.params.id;

                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls[name]
                    };
                    updatedFormElement.value = value;
    
                    updatedControls[name] = updatedFormElement;
    
                    this.setState({
                        formControls: updatedControls
                    })

                    ui.handleScroll('top');
                }
            }

            handleChange = (event, action) => {     
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;

                this.setState({
                    formControls: updatedControls
                })
            }

            getAddressData = async() => {
                let address = await this.storeOrders.getAddressData(this.state.formControls.order_id.value);
                let tt = this.getOrderTrackTrace();
                let class_feedback = '';

                if (address[0].delivery_housenr === '' || 
                    address[0].delivery_housenr === null ||
                    (address[0].delivery_addition !== null && address[0].delivery_addition.length > 8)) {
                    class_feedback = ' --error';
                }

                this.setState({
                    address: address[0],
                    tracktrace: tt,
                    class_feedback: class_feedback
                })
            }

            submitOrderToMyParcel = async() => {
                let value_colli = this.state.formControls.colli.value !== '' ? this.state.formControls.colli.value : 1;
                let tt_order = await this.storeOrders.submitOrderToMyParcel(this.state.formControls.order_id.value, value_colli, this.state.formControls.packaging.value);
                let orders = await this.storeOrders.getTrackTraceOrders();

                let tt = this.getOrderTrackTrace();
                this.setState({
                    tracktrace: tt
                })
            }

            submitTrackTraceToDB = async() => {
                let orders = await this.storeOrders.submitTrackTraceToDB(this.state.formControls.order_id.value, this.state.formControls.tracktrace_number.value);
                this.storeOrders.getTrackTraceOrders();
            }

            getOrderTrackTrace() {
                let tt = '-';
                if (this.state.formControls.order_id.value !== '') {
                    let idx = this.storeOrders.orders
                        .findIndex((order) => order.order_id === parseInt(this.state.formControls.order_id.value));
                    if (idx !== -1) {
                        if (this.storeOrders.orders[idx].barcode !== '' && this.storeOrders.orders[idx].barcode !== null) {
                            tt = this.storeOrders.orders[idx].barcode;
                        }
                    }
                }

                return tt;
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'myparcel'
                        feature = 'tracktrace'
                        title = 'Track & trace'
                    >   
                        <React.Fragment>
                            <div>
                                <h1>MyParcel</h1>
                            </div>  

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Maak een Track&Trace nummer aan</h2>
                                </div>
                                <div className="content--section__columns">
                                    <div className="content--section__columns-header">
                                        <span className="content--section__columns-subheader">Bestelnummer</span>
                                        <div className="content--section__columns-input">
                                            {Object.keys(this.state.formControls).map((key) => (
                                                key === 'order_id' && (
                                                    <React.Fragment>
                                                        <span className="block block-edit__listitem--field">
                                                            <TextInput 
                                                                name = {key} 
                                                                placeholder = {this.state.formControls[key].placeholder}
                                                                value = {this.state.formControls[key].value}
                                                                onChange = {(e) => this.handleChange(e)}
                                                                touched = {this.state.formControls[key].touched}
                                                            />
                                                        </span>
                                                    </React.Fragment>
                                                )
                                            ))}
                                            <button className="btn" onClick={(e) => this.getAddressData()}>Haal adresgegevens en t&t op</button>
                                        </div>
                                        <div>
                                            <span className="content--section__columns-subheader">Track&trace nummer</span>
                                            <span>{this.state.tracktrace}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="content--section__columns">
                                    <div className="content--section__column">
                                        <h2>Adresgegevens voor MyParcel</h2>
                                        <div>
                                            <div className={"feedback__check--address" + this.state.class_feedback}>
                                                Controleer het huisnummer en/of toevoeging
                                            </div>
                                            <ul>
                                                {this.state.address.delivery_company !== '' && this.state.address.delivery_company !== undefined &&
                                                    <li>{this.state.address.delivery_company}</li>
                                                }
                                                <li>{this.state.address.delivery_name}</li>
                                                <li>{this.state.address.delivery_address} {this.state.address.delivery_housenr} {this.state.address.delivery_addition}</li>
                                                <li>{this.state.address.delivery_zipcode}</li>
                                                <li>{this.state.address.delivery_city}</li>
                                                <li>{this.state.address.delivery_country}</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="content--section__column">
                                        <h2>Creeer automatisch t&t in MyParcel & database</h2>
                                        <div>
                                        {Object.keys(this.state.formControls).map((key) => (
                                            <React.Fragment>
                                                {key === 'packaging' &&
                                                    <div>
                                                        <label>{this.state.formControls[key].label}</label>
                                                        <Radio 
                                                            name = "packaging" 
                                                            placeholder = {this.state.formControls.packaging.placeholder}                                                
                                                            value = {this.state.formControls.packaging.value}
                                                            options = {this.state.formControls.packaging.options}
                                                            direction = {"vertical"}
                                                            onChange = {(e) => this.handleChange(e)}
                                                            defaultValue = {this.state.formControls.packaging.defaultValue}
                                                        />
                                                    </div>
                                                }

                                                {(key === 'colli') && 
                                                    <div>
                                                        <label>{this.state.formControls[key].label}</label>
                                                        <span className="block block-edit__listitem--field">
                                                            <TextInput 
                                                                name = {key} 
                                                                placeholder = {this.state.formControls[key].placeholder}
                                                                value = {this.state.formControls[key].value}
                                                                onChange = {(e) => this.handleChange(e)}
                                                                touched = {this.state.formControls[key].touched}
                                                            />
                                                        </span>  
                                                    </div>     
                                                }
                                            </React.Fragment>
                                        ))}  
                                        </div>
                                        <div class="content--section__form-actions">
                                            <button className="btn btn--primary" onClick={(e) => this.submitOrderToMyParcel()}>Creeer Track & Trace</button>
                                        </div> 
                                    </div>

                                    <div className="content--section__column">
                                        <h2 className="column__title">Copieer t&t uit MyParcel naar database</h2>
                                        <p className="column__subtitle">Indien automatisch genereren niet lukt</p>
                                        {Object.keys(this.state.formControls).map((key) => (
                                            key === 'tracktrace_number' && (
                                                <div>
                                                    <label>{this.state.formControls[key].label}</label>
                                                    <span className="block block-edit__listitem--field">
                                                        <TextInput 
                                                            name = {key} 
                                                            placeholder = {this.state.formControls[key].placeholder}
                                                            value = {this.state.formControls[key].value}
                                                            onChange = {(e) => this.handleChange(e)}
                                                            touched = {this.state.formControls[key].touched}
                                                        />
                                                    </span>  
                                                </div> 
                                            )  
                                        ))}  
                                        <div class="content--section__form-actions">
                                            <button className="btn" onClick={(e) => this.submitTrackTraceToDB()}>Bewaar Track & Trace in database</button>
                                        </div> 
                                    </div>
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Overzicht Track & Trace nummers</h2>
                                </div>
                                <div className="content--section__columns">
                                    <table className="table table--orders">
                                    <thead>
                                        <tr>
                                            <th>Ordernummer</th>
                                            <th>Order data</th>
                                            <th>Barcode</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.storeOrders.orders.length > 0 && this.storeOrders.orders.map((order) => ( 
                                        <MyParcelRow 
                                            order = {order}
                                        />
                                    ))}
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                                                 
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(MyParcel));
