import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';

import FormAddCategory from '../components/forms/form-add-category';
import FormAddNUR from '../components/forms/form-add-nur';
import ListNURs from '../components/ui/list-nurs';
import NavigationCategories from '../components/ui/navigation-categories';

const AdminCategories = inject("stores") (
    observer (
        class AdminCategories extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                
                this.state = {

                }
            }

            componentDidMount = async() => {
                const list_nurs = await this.storeUi.getListNURs();
                const nav_cats = await this.storeUi.getNavigationCategories();
                const cat_nurs = await this.storeUi.getListCatNURs();
            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                        section = 'catalog'
                        feature = 'categories'
                        title = 'Categorie indeling'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Categorieën</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Indeling categorieën Succesboeken.nl</h2>
                                </div>
                                <div className="content--section__columns">
                                    <div className="content--section__column">
                                        <h3>Voeg categorie toe</h3>
                                        <FormAddCategory />
                                        
                                        <h3>Voeg NUR aan (sub)categorie toe</h3>
                                        <FormAddNUR />
                                    </div>
                                    <div className="content--section__column">
                                        <h3>Navigatie op website</h3>
                                        <NavigationCategories 
                                            categories = {this.storeUi.nav_categories.length}
                                            nurs = {this.storeUi.nav_catnurs.length}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Indeling categorieën NUR</h2>
                                </div>
                                <div className="content--section__columns">
                                    <div className="content--section__column">
                                        <h3>Beschikbare NUR-nummers</h3>
                                        <ListNURs 
                                            nurs = {this.storeUi.nav_catnurs.length}
                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminCategories));
