import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as ebooks from '../communicator/ebooks';

class StoreEBooks{
    form_feedback = '';

    constructor() {
        
    }

    clearFormFeedback() {
        this.form_feedback = {};
    }

    setFormFeedback(type, fb) {
        this.form_feedback[type] = fb;
    }

    async getEBooks() {
        let listEbooks;

        try {
            const returnData = await ebooks.getEBooks();
            runInAction(() => {
                listEbooks = returnData.data;
                return listEbooks;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return listEbooks;
    }

    async deleteEBook(id) {
        try {
            const returnData = await ebooks.deleteEBook(id);
            runInAction(() => {
                this.form_feedback = {'success': "Het e-book is verwijderd"};
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async saveEBook(id, formControls) {
        let data = {};

        Object.keys(formControls).map((key) => (
            data[key] = formControls[key].value
        ))

        try {
            const returnData = await ebooks.saveEBook(id, data);
            runInAction(() => {
                //this.form_feedback = returnData.msg[0];
                //this.form_feedback = {'success': "Het e-book is toegevoegd"};
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

}

decorate(StoreEBooks, {
    form_feedback: observable,
    clearFormFeedback: action,
    deleteEBook: action,
    getEBooks: action,
    setFormFeedback: action,
    saveEBook: action
})

export default StoreEBooks;