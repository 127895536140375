import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const CellOrderContact = inject("stores") (
    observer (
        class CellOrderContact extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                    order: {}
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.nr !== this.props.nr) {
                    this.setState({
                        order: this.props.order
                    })
                }

                if (prevProps.order_id !== this.props.order_id) {
                    this.setState({
                        order: this.props.order
                    })
                }
            }

            render() {
                return (                  
                    <React.Fragment>
                        <span className="table--orders__cell-label">Contactgegevens</span>
                        <span className="table--orders__cell-value">{this.state.order.order_email}</span>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CellOrderContact));
