import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';

import CustomersRow from '../components/tables/customers-row';
import FormSearchClient from '../components/forms/form-search-client';

const AdminCustomers = inject("stores") (
    observer (
        class AdminCustomers extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeCustomers = this.props.stores.storeCustomers;
                this.storeOrders = this.props.stores.storeOrders;
                
                this.state = {
                    customers: [],
                    title: '',
                }
            }

            componentDidMount = async() => {
                const customers = await this.storeCustomers.getAllCustomers();

                this.setState({
                    customers: customers
                })
            }

            submitSearch = async(value) => {
                const customers = await this.storeCustomers.searchCustomers(value);

                this.setState({
                    customers: customers
                })
            }

            showAllClients = async() => {
                const customers = await this.storeCustomers.getAllCustomers();

                this.setState({
                    customers: customers
                })

                const path = '/admin-customers/';
                this.props.history.push(path);
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'shop'
                        feature = 'customers'
                        title = 'Klanten'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Beheer klanten</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>{this.state.title}</h2>
                                    <FormSearchClient 
                                        form = {true}
                                        submitSearch = {(e) => this.submitSearch(e)} 
                                        showAllClients = {(e) => this.showAllClients()}
                                    />
                                </div>
                                <div className="content--section__columns">
                                    <table className="table table--orders-overview">
                                    <thead>
                                        <tr>
                                            <th>Klantnummer</th>
                                            <th>Klant</th>
                                            <th>Email</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.customers && this.state.customers.length > 0 && this.state.customers.map((customer) => ( 
                                       <CustomersRow 
                                            client_id = {customer.client_id}
                                            customer = {customer}
                                        />
                                    ))}
                                    {this.state.customers && this.state.customers.length == 0 &&
                                        <tr>
                                            <td colspan="5" className="table--orders-overview__cell-feedback">Er zijn geen accounts gevonden</td>
                                        </tr>
                                    }
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminCustomers));
