import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import moment from "moment";

import Loader from '../../elements/loader';

const CBReportInitLine = inject("stores") (
    observer (
        class CBReportInitLine extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeCb = this.props.stores.storeCb;

                this.state = {
                    loading: false,
                    status: this.props.data.status,
                    filename: '',
                    items_added:  0,
                    items_changed: 0
                }
            }

            componentDidMount = () => {
                //this.checkResources(this.props.filename);
            }

            componentDidUpdate = (prevProps) => {
                //this.checkResources(this.props.filename);
                
                if (prevProps.loading_file !== this.props.loading_file) {
                    let status = '';
                    const idx = this.storeCb.files_init
                        .findIndex((file) => file.filename === this.props.filename);
                    if (idx !== -1) {
                        status = this.storeCb.files_init[idx].status;
                    
                        this.setState({
                            loading: this.props.filename == this.props.loading_file ? true : false,
                            status: status,
                            items_added:  this.storeCb.files_init[idx].items_added,
                            items_changed:  this.storeCb.files_init[idx].items_changed
                        })
                    }
                }

                if (prevProps.items_added !== this.props.items_added) {
                    this.setState({
                        items_added: this.props.items_added
                    })
                }

                if (prevProps.items_changed !== this.props.items_changed) {
                    this.setState({
                        items_changed: this.props.items_changed
                    })
                }

                if (prevProps.status !== this.props.status) {
                    this.setState({
                        status: this.props.status
                    })
                }
            }

            checkResources = async(filename) => {
                const update = "INIT";
                const date = "20210000";

                const skus = await this.storeCb.checkResourcesMissing(update, filename, date);
                const amount = this.storeCb.resources_missing.length;

                this.setState({
                    feedback: 'success',
                    amount: amount
                })
            }

            getResources = async(filename) => {
                console.log('SbC get resources')
                this.setState({
                    feedback: ''
                })

                const update = "INIT";
                const date = "20210000";

                const skus = await this.storeCb.getResourcesMissing(update, filename, date);
                this.checkResources(filename);
            }

            render() {
                return (
                    <tr>
                        <td>
                            {this.props.filename}
                        </td>
                        <td>
                            {this.props.data !== ''
                            ?   this.state.items_added
                            :   0
                            }
                        </td>
                        <td>
                            {this.state.items_changed}
                        </td>
                        <td>
                            {this.state.loading
                            ?   <div className="status">
                                     <Loader />
                                     <span>loading...</span>
                                </div>
                            :   <div className="status">
                                    <span>
                                        {this.state.status}
                                    </span>  
                                    <span className="btn btn--primary" onClick={() => this.props.startCbInit(this.props.filename)}>Start initialisation</span>
                                    {this.state.status === 'ok' &&
                                    <React.Fragment>
                                        <button className="btn btn--secundary" onClick={() => this.checkResources(this.props.filename)}>Controleer covers</button>
                                        {this.state.amount}
                                        <button className="btn btn--primary" onClick={() => this.getResources(this.props.filename)}>Download ontbrekende covers</button>
                                        {/* <span className="btn btn--secundary" onClick={() => this.props.startInitResources(this.props.filename)}>Download resources</span> */}
                                    </React.Fragment>
                                    }
                                </div>                            
                            }
                        </td>
                    </tr>
                )
            }
        }
    )
)

const CBReportInit = inject("stores") (
    observer (
        class CBReportInit extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_CUSTOM_NODE_ENV;

                this.storeUi = this.props.stores.storeUi;
                this.storeCb = this.props.stores.storeCb;

                this.state = {
                    loading: null,
                    files_init: []
                }
            }

            componentDidMount = async() => {
                const files_init = await this.storeCb.listFilesInit();
                
                this.setState({
                    files_init: files_init
                })
                const status = await this.storeCb.getInitReports('INIT');
            }

            clearCatalog = async() => {
                const cleared = await this.storeCb.clearCatalog();
                this.setState({
                    files_init: []
                })
            }

            getListFiles = async() => {
                const files_init = await this.storeCb.listFilesInit();
                this.setState({
                    files_init: files_init
                })
            }

            getInitReports = async() => {
                const status = await this.storeCb.getInitReports('INIT');
                this.setState({
                    files_init: this.storeCb.files_init
                })
            }

            startCbInit = async(filename) => {
                let loaded;
                this.storeCb.clearFeedback();
                const date = '20210000';

                if (filename == 'all') {
                    for (let file of this.storeCb.files_init) {
                        if (file.status !== 'ok') {
                            loaded = await this.storeCb.initCatalog('INIT', file.filename, date);
                        }
                    }
                } else {
                    loaded = await this.storeCb.initCatalog('INIT', filename, date);
                }
            }

            startInitResources = async(filename) => {
                const date = '20210000';
                let resources = await this.storeCb.initResources('INIT', filename, date);
            }

            render() {
                return (
                    <div className="content--section">
                        <div className="content--section__header">
                            <h2>Initiele installatie catalogus BoekenLijst2021</h2>
                        </div>

                        <div className="wrapper--content__columns">
                            <div className="content-column">
                                <div className="content--section__columns">
                                    <table className="table table--reports">
                                    <thead>
                                        <tr>
                                            <th>Filename</th>
                                            <th>Toegevoegd</th>
                                            <th>Gewijzigd</th>
                                            <th>Status</th>
                                            <th>Ontbrekende covers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.files_init && this.state.files_init.map((file) => (
                                            <CBReportInitLine
                                                filename = {file.filename}
                                                data = {file}
                                                loading_file = {this.storeCb.loading_file_init}
                                                startCbInit = {this.startCbInit}
                                                startInitResources = {this.startInitResources}
                                                items_added = {file.items_added}
                                                items_changed = {file.items_changed}
                                                status = {file.status}
                                            />
                                        ))}
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {this.storeCb.feedback_init !== '' &&
                            <div className="wrapper--content__columns">
                                {/* <div className="content--section__actions">
                                    {this.storeCb.feedback_init[0].success}
                                </div> */}
                            </div>
                        }

                        <div className="wrapper--content__columns">
                            <div className="content--section__actions">
                                {/* {this.env === 'local' && */}
                                    <React.Fragment>
                                        <span className="btn btn--secundary" onClick={() => this.clearCatalog()}>Maak lijst & CB-catalogus leeg</span>
                                    </React.Fragment>
                                {/* } */}
                                <span className="btn btn--secundary" onClick={() => this.getListFiles()}>Haal init files op</span>
                                <span className="btn btn--secundary" onClick={() => this.getInitReports()}>Haal init reports op</span>
                                <span className="btn btn--primary" onClick={() => this.startCbInit('all')}>Start initialisation</span>
                            </div>
                        </div>
                    </div> 
                )
            }
        }
    )
)

export default withRouter(CBReportInit);
