import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

const CellOrderDetails = inject("stores") (
    observer (
        class CellOrderDetails extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                
                this.state = {
                    order_invoice: '-',
                    order_tracktrace: '-'
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
            }

            componentDidMount = async() => {
                const order = await this.storeOrders.getOrder(this.props.order_id);
                this.setState({
                    order_invoice: order[0].invoice_id,
                    order_tracktrace: order[0].order_tracktrace
                })
            }

            render() {
                return (                  
                    <React.Fragment>
                        <span className="table--orders__cell-label">Factuurnummer</span>
                        <span className="table--orders__cell-value">
                            {this.state.order_invoice !== null 
                            ?   this.state.order_invoice
                            :   '-'
                            }
                        </span>
                        <span className="table--orders__cell-label">Track & Tracenummer</span>
                        <span className="table--orders__cell-value">
                            {this.state.order_tracktrace !== null 
                            ?   this.state.order_tracktrace
                            :   '-'
                            }
                            </span>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CellOrderDetails));
