import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import TextInput from '../../elements/form-input-text';
import Counter from '../ui/counter';
import GraphBars from '../ui/graph-bars';

const NewsletterDelivery = inject("stores") (
    observer (
        class NewsletterDelivery extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeAnalytics = this.props.stores.storeAnalytics;
                
                this.state = {
                    tag: '',
                    delivery: {
                        count_total: 0,
                        count_desktop: 0,
                        count_windows: 0,
                        count_apple: 0,
                        count_mobile: 0,
                        count_android: 0,
                        count_ios: 0
                    }
                }
            }

            componentDidMount = async() => {
                let counted = false;
                let count_desktop = 0;
                let count_windows = 0;
                let count_apple = 0;
                let count_mobile = 0;
                let count_android = 0;
                let count_ios = 0;

                const arr_desktop = ['windows', 'macintosh', 'linux x86_64', 'cros'];
                const arr_windows = ['windows'];
                const arr_apple = ['macintosh'];
                const arr_mobile = ['android', 'iphone', 'ipad'];
                const arr_android = ['android'];
                const arr_ios = ['iphone', 'ipad'];

                const delivery = await this.storeAnalytics.checkDeliveryNewsletter(this.props.tag);
                const count = delivery.length;

                delivery.map((item) => { 
                    counted = false;
                    arr_desktop.map((str_device) => {
                        if (item.ua !== null && item.ua.indexOf(str_device) !== -1 && counted == false) {
                            count_desktop++;
                            counted = true;
                        }
                    })
                });
                
                delivery.map((item) => {
                    counted = false;
                    arr_windows.map((str_device) => {
                        if (item.ua !== null && item.ua.indexOf(str_device) !== -1 && counted == false) {
                            count_windows++;
                            counted = true;
                        }
                    })
                });
                
                delivery.map((item) => {
                    counted = false;
                    arr_apple.map((str_device) => {
                        if (item.ua !== null && item.ua.indexOf(str_device) !== -1 && counted == false) {
                            count_apple++;
                            counted = true;
                        }
                    })
                });

                delivery.map((item) => {
                    counted = false;
                    arr_mobile.map((str_device) => {
                        if (item.ua !== null && item.ua.indexOf(str_device) !== -1 && counted == false) {
                            count_mobile++;
                            counted = true;
                        }
                    })
                });
                
                delivery.map((item) => {
                    counted = false;
                    arr_android.map((str_device) => {
                        if (item.ua !== null && item.ua.indexOf(str_device) !== -1 && counted == false) {
                            count_android++;
                            counted = true;
                        }
                    })
                });
                
                delivery.map((item) => {
                    counted = false;
                    arr_ios.map((str_device) => {
                        if (item.ua !== null && item.ua.indexOf(str_device) !== -1 && counted == false) {
                            count_ios++;
                            counted = true;
                        }
                    })
                });
               
                this.setState({
                    tag: this.props.tag,
                    delivery: {
                        count_total: count,
                        count_desktop: count_desktop,
                        count_windows: count_windows,
                        count_apple: count_apple,
                        count_mobile: count_mobile,
                        count_android: count_android,
                        count_ios: count_ios
                    }
                })
            }

            componentDidUpdate = async() => {

            }

            render() {
                return (                  
                    <div className="content--section__table-row">
                        <div className="table--counters__column">
                            <span className="table--counters__column--header">
                                {this.props.tag}
                            </span>
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.delivery.count_total}
                                    title = {'email intro<br/>getoond'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.delivery.count_total
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.delivery.count_desktop}
                                    title = {'desktop'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.delivery.count_desktop
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.delivery.count_windows}
                                    title = {'windows'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.delivery.count_windows
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.delivery.count_apple}
                                    title = {'apple'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.delivery.count_apple
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.delivery.count_mobile}
                                    title = {'mobile'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.delivery.count_mobile
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.delivery.count_android}
                                    title = {'android'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.delivery.count_android
                            }
                        </div>
                        <div className="table--counters__column">
                            {this.props.view === 'counter' &&
                                <Counter
                                    value = {this.state.delivery.count_ios}
                                    title = {'ios'}
                                    value_alert = {20}
                                />
                            }
                            {this.props.view === 'number' &&
                                this.state.delivery.count_ios
                            }
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(NewsletterDelivery));
