import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Link } from 'react-router-dom';

import TextInput from '../../elements/form-input-text';

import CellOrderAddress from './cell-order-address';
import CellOrderSummary from './cell-order-summary';
import CellOrderStatus from './cell-order-status';
import CellOrderHistory from './cell-order-history';
import CellOrderErrors from './cell-order-errors';

const OrdersRow = inject("stores") (
    observer (
        class OrdersRow extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlAPI = process.env.REACT_APP_URL_API;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;

                this.state = {
                    hasErrors: true
                }

                
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = async(prevProps) => {
                
            }

            setErrorCount = (count) => {
                this.setState({
                    hasErrors: count === 0 ? false : true
                })
            }

            render() {
                return (       
                    this.state.hasErrors &&
                        <React.Fragment>
                            <tr className="table--orders__row-header">
                                <td colspan="5">
                                    <span className="table--orders__cell-value">
                                        <Link 
                                            to = {{ 
                                                pathname: "/admin-order/" + this.props.order.order_id,
                                                order_id: this.props.order.order_id,
                                                order: this.props.order
                                            }}
                                        >
                                            {this.props.order.order_id}
                                        </Link>
                                    </span>
                                </td>
                                <td>
                                    <Link 
                                        to = {{ 
                                            pathname: "/admin-customer/" + this.props.order.client_id,
                                            client_id: this.props.order.client_id,
                                            client: this.props.client
                                        }}
                                    >
                                        {this.props.order.client_id}
                                    </Link>
                                </td>
                            </tr>          
                            <tr>
                                <td>
                                </td>
                                <td>
                                
                                </td>
                                <td 
                                    className = "table--orders-overview__cell-errors"
                                >
                                    <CellOrderErrors 
                                        orderId = {this.props.order.order_id}
                                        setErrorCount = {(count) => this.setErrorCount(count)}
                                    />
                                </td>
                            </tr>
                        </React.Fragment> 
                )
            }
        }
    )
)

export default withTranslation()(withRouter(OrdersRow));
