import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';

const AdminQuotes = inject("stores") (
    observer (
        class AdminQuotes extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                
                this.state = {
                    formControls: {
                        id: {
                            value: '',
                            placeholder: "Vul id van quote in"
                        }
                    }
                }
            }

            componentDidMount = () => {
            }

            handleChangeSetting = (event) => {     
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            viewQuote = (e) => {
                this.storeContent.viewQuote(this.state.formControls.id.value);
            }

            viewQuoteOnSite = async(e) => {
                this.storeContent.viewQuote(this.state.formControls.id.value);
                const url = this.urlSB + "?quote=" + this.state.formControls.id.value;
                window.open(url, "_blank");
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'content'
                        feature = 'quotes'
                        title = 'Quotes'
                    >   
                        <div className="wrapper--content__header">
                            <h1>Quotes</h1>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Bekijk quote</h2>
                            </div>

                            <div className="wrapper--content__columns">
                                <div className="quote--preview">
                                    <span className="quote--preview__header">Quote preview</span>
                                    <div className="quote--preview__quote">
                                        <div className="quote--preview__title" dangerouslySetInnerHTML={{ __html: this.storeContent.quote.text}} />
                                        <div className="quote--preview__author">{this.storeContent.quote.author}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="wrapper--content__columns">
                                <div className="content--section__actions">
                                    <ul className="block block-edit__list">
                                        {Object.keys(this.state.formControls).map((key) => (
                                            <li className="block block-edit__listitem">
                                                <span className="block block-edit__listitem--field">
                                                    <TextInput 
                                                        name = {key} 
                                                        placeholder = {this.state.formControls[key].placeholder}
                                                        value = {this.state.formControls[key].value}
                                                        onChange = {(e) => this.handleChangeSetting(e)}
                                                        touched = {this.state.formControls[key].touched}
                                                    />
                                                </span>       
                                            </li>
                                        ))}
                                    </ul>
                                    <span className="btn btn--primary" onClick={(e) => this.viewQuote(e)}>Bekijk quote</span>
                                    <span className="btn btn--secundary" onClick={(e) => this.viewQuoteOnSite(e)}>Bekijk quote op website</span>
                                </div>
                            </div> 
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Voeg quote toe</h2>
                            </div>

                            <div className="wrapper--content__columns">
                                <div className="quote--preview">
                                    Nog niet geimplementeerd
                                </div>
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminQuotes));
