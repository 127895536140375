import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import CBReportDaily from '../cb/cb-report-daily';
import CBReportInit from '../cb/cb-report-init';
import CBReportOverview from '../cb/cb-report-overview';
import CheckCovers from './check-covers';
import CheckPdfs from './check-pdfs';

import Select from '../../elements/form-input-select';

const CBCheckResources = inject("stores") (
    observer (
        class CBCheckResources extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeCb = this.props.stores.storeCb;
                
                this.state = {
                    files: [],
                    formControls: {
                        file_select: {
                            label: 'Kies init file',
                            value: 1,
                            placeholder: 'Kies init file',
                            touched: false
                        }
                    },
                    feedback: ''
                }
            }

            componentDidMount = async() => {
                const result = await this.storeCb.listFilesInit();
                let files = [];

                result.map((file) => {
                    files.push(file.filename);
                })

                this.setState({
                    files: files
                })
               
            }

            checkResources = async() => {
                this.setState({
                    resources: [],
                    feedback: ''
                })

                const filename = this.state.formControls.file_select.value == 1 ? this.state.files[0] : this.state.formControls.file_select.value;
                const update = "INIT";
                const date = "20210000";

                //onst skus = await this.storeCb.checkResourcesMissing(update, filename, date);
                // const amount = this.storeCb.resources_missing.length;

                // this.setState({
                //     skus: skus, 
                //     feedback: 'success',
                //     amount: amount
                // })
            }

            getResources = async() => {
                this.setState({
                    feedback: ''
                })

                const filename = this.state.formControls.file_select.value == 1 ? this.state.files[0] : this.state.formControls.file_select.value;
                const update = "INIT";
                const date = "20210000";

                const skus = await this.storeCb.getResourcesMissing(update, filename, date);

                this.setState({
                    feedback: 'success'
                })
            }

            handleSelect= (event, action) => {  
                const name = event.target.name;
                let value = event.target.value;
         
                let updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedControls[name] = updatedFormElement;

                this.setState({
                    formControls: updatedControls
                })
            }

            render() {
                return (                  
                        <React.Fragment>
                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Overzicht ontbrekende covers tov ONIX files</h2>
                                </div>

                                <div className="wrapper--content__columns">
                                    <div className="content-column">
                                        <h2>Kies init file</h2>

                                        {this.state.files &&
                                            <Select 
                                                name="file_select"
                                                value = {this.state.formControls.file_select.value}
                                                options = {this.state.files}
                                                touched = {this.state.formControls.file_select.touched}
                                                onChange = {this.handleSelect}
                                                valueSelected = {this.state.formControls.file_select.value}
                                            />
                                        }

                                        {this.state.feedback !== '' &&
                                            <div>
                                                Aantal: {this.state.amount}
                                            </div>
                                        }
                                    
                                        <div className="content--section__actions">
                                            <button className="btn btn--secundary" onClick={() => this.checkResources()}>Controleer covers</button>
                                            <button className="btn btn--primary" onClick={() => this.getResources()}>Download ontbrekende covers</button>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            
                            {/* <CheckCovers 
                                title = "Wel download link in db, geen image"
                                subtitle = "Toon ISBNs waarvan wel URLCoverFront-download link in database staat, maar geen png file in /public/images/CoversVKg-all aanwezig is"
                                url_cover_front = {true}
                            /> */}

                            {/* <CheckCovers 
                                title = "Geen download link in db, geen image"
                                subtitle = "Toon ISBNs zonder URLCoverFront-download link, en geen png file in /public/images/CoversVKg-all"
                                url_cover_front = {false}
                            /> */}

                            {/* <CheckPdfs 
                                title = "Wel pdf-preview link in db, geen pdf"
                                subtitle = "Toon ISBNs met URLPreviewPdf-download link, maar geen pdf file in /public/preview-pdf"
                                url_cover_front = {false}
                            /> */}
                                             
                        </React.Fragment>
                   
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CBCheckResources));
