import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import FormLogin from '../components/forms/form-login';

const Login = inject("stores") (
    observer (
        class Login extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    redirect: this.storeUi.admin_loggedin ? true : false
                }
            }

            componentDidMount = () => {
                window.scrollTo(0, 0);
            }

            onNextAction = () => {
                let url;
                if (this.storeUi.admin_loggedin) {
                    url = "/start";
                    this.props.history.push(url);
                }
            }

            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/"} 
                    />;
                }

                return (
                    <div className="wrapper--content">
                        <div className="wrapper--content">
                            <h1 className="content-header content-header--form">Admin login</h1>
                            <FormLogin 
                                onNext = {this.onNextAction}
                            />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Login));
