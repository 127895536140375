import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as books from '../communicator/books';

class StoreBooks{
    form_feedback = '';

    constructor() {
        
    }

    clearFormFeedback() {
        this.form_feedback = {};
    }

    async copyBook(book, formControls) {
        let book_updated = book;
        book_updated['category'] = formControls['category'].value;
        book_updated['subcategory'] = formControls['subcategory'].value;
        book_updated['category1'] = formControls['category1'].value;
        book_updated['subcategory1'] = formControls['subcategory1'].value;
        book_updated['category2'] = formControls['category2'].value;
        book_updated['subcategory2'] = formControls['subcategory2'].value;
        book_updated['category3'] = formControls['category3'].value;
        book_updated['subcategory3'] = formControls['subcategory3'].value;
        book_updated['media'] = formControls['media'].value;
        book_updated['priority'] = formControls['priority'].value;
        book_updated['deliveryTime'] = formControls['deliveryTime'].value;
        book_updated['remarks'] = formControls['remarks'].value;
        book_updated['tags'] = formControls['tags'].value;

        const data = {
            book: book_updated
        }

        try {
            const returnData = await books.copyBook(data);
            runInAction(() => {
                this.form_feedback = returnData.msg[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async searchBook(sku) {  
        let result;
        const data = {
            sku: sku
        }

        try {
            const returnData = await books.searchBook(data);
            runInAction(() => {
                if ("msg" in returnData && returnData.msg !== null) {
                    this.form_feedback = returnData.msg[0];
                } else {
                    this.form_feedback = '';
                }
                result = returnData.data.body[0];
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return result;
    }

    async getCategories() {
        const shop = 'SHOP_SB';
        let cats;

        try {
            const returnData = await books.getCategories(shop);
            runInAction(() => {
                cats = returnData.data;
                //this.categoriesList = returnData.data;
                return cats;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cats;
    }

}

decorate(StoreBooks, {
    form_feedback: observable,
    clearFormFeedback: action,
    copyBook: action,
    searchBook: action
})

export default StoreBooks;