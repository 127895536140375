import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import TextInput from '../../elements/form-input-text';

const BlockPromoBook = inject("stores") (
    observer (
        class BlockPromoBook extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                
                this.state = {
                    formControls: {
                        sku: {
                            label: 'ISBN13',
                            value: this.props.promoBook.sku,
                            placeholder: 'Vul category in',
                        },
                        title: {
                            label: 'Titel',
                            value: this.props.promoBook.title,
                            placeholder: 'Vul titel in',
                        },
                        isActive: {
                            label: 'Is actief',
                            value: this.props.promoBook.isActive,
                            placeholder: 'Is actief?',
                        }
                    }
                }
            }

            componentDidMount = async() => {
                console.log('SbC promo', this.props.promoBook)
            }

            handleChangePromo = (event) => {     
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                }, () => this.props.updatePromoBooks(this.props.promoBook.id, this.state.formControls))
            }

            render() {

                return (                  
                    <React.Fragment>
                        <ul className="block block-edit__list">
                            {Object.keys(this.state.formControls).map((key) => (
                                (this.props.title && key !== 'sku' && key !== 'isActive' || !this.props.title) &&
                                    <li className="block block-edit__listitem">
                                        <span className="block block-edit__listitem--label">{this.state.formControls[key].label}</span>
                                        <span className="block block-edit__listitem--field">
                                            <TextInput 
                                                name = {key} 
                                                value = {this.state.formControls[key].value}
                                                onChange = {(e) => this.handleChangePromo(e)}
                                                touched = {this.state.formControls[key].touched}
                                            />
                                        </span>
                                    </li>
                            ))}
                        </ul>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockPromoBook));
