import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Link } from 'react-router-dom';

import Template from '../../templates/template';

const ReportOverviewPartnerSummary = inject("stores") (
    observer (
        class ReportOverviewPartnerSummary extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storeUi = this.props.stores.storeUi;
                this.storeReports = this.props.stores.storeReports;
                this.storePartners = this.props.stores.storePartners;
                
                this.controller = new AbortController();
                this.signal = this.controller.signal;

                this.state = {
                    partner: [],
                    hasOpen: false
                }

                this.columns = [
                    {
                        'id': 1,
                        'name': '',
                        'displayValue': 'Key'
                    },{
                        'id': 2,
                        'name': '',
                        'displayValue': 'Value'
                    }]
            }

            componentDidMount = async() => {
                const partner = await this.storePartners.getPartner(this.props.id);
                const payouts = await this.storePartners.getPartnerPayouts(this.props.id);
                //const sales = await this.storePartners.getPartnerAllSales(this.props.id);
                //const payouts = [];
                const sales = [];
                const sales2 = [];

                //const sales2 = this.storePartners.getPartnerAllSalesWithAbort(this.props.id, this.controller, this.signal);

                this.setState({
                    query: sales2
                })
                // sales.then((result) => {  
                //     //this.storeBooks.setBookListSb(result.data);
                //     // this.setState({
                //     //     bookList: result.data
                //     // })\
                //     console.log('SbC with abort result', result)
                // });

                this.setState({
                    partner: partner,
                    payouts: payouts,
                    sales: sales
                }, () => this.checkCommissionsOpen())
            }

            componentDidUpdate = async(prevProps) => {
            }

            calcTotalPayouts = () => {
                let total = 0;
                total = this.storePartners.calcTotalPayouts(this.state.payouts);

                return total;
            }

            calcTotalSales = () => {
                let total = 0;
                total = this.storePartners.calcTotalSales(this.state.partner, this.state.sales);
                
                return total;
            }

            calcTotalCommissions = () => {
                let total = 0;
                total = this.storePartners.calcTotalCommissions(this.state.partner, this.state.sales);
                
                return total;
            }

            formatNumber = (price, decimals) => {
                let priceDisplay = parseFloat(price).toFixed(decimals);
                priceDisplay = priceDisplay.toString().replace('.',',');
                
                return priceDisplay;
            }

            formatDate = (date) => {
                const dateFormatted = moment(date.date).format(this.storeUi.format_date_simple)

                return dateFormatted;
            }

            calcTotalPayouts = () => {
                let total = 0;
                total = this.storePartners.calcTotalPayouts(this.state.payouts);

                return total;
            }

            calcTotalSales = () => {
                let total = 0;
                total = this.storePartners.calcTotalSales(this.state.partner, this.state.sales);
                
                return total;
            }

            calcTotalCommissions = () => {
                let total = 0;
                total = this.storePartners.calcTotalCommissions(this.state.partner, this.state.sales);
                
                return total;
            }

            checkCommissionsOpen = () => {
                let hasOpen = false;

                let commissions = this.calcTotalCommissions();
                commissions = commissions.replace(',','.');

                let payouts = this.calcTotalPayouts();
                payouts = payouts.replace(',','.');
                
                let openCommissions = commissions - payouts;

                if (openCommissions > 0.05) {
                    hasOpen = true;
                } 
                
                this.setState({
                    hasOpen: hasOpen
                })
            }

            render() {
                return (  
                    <div className={"content-section--report__columns content-section--report__columns--zebra" + (this.state.hasOpen ? " --open" : "")}>
                        <div className="content-section--report__column">
                            <Link to={"/admin-partner/" + this.state.partner.account_id}>{this.state.partner.account_id}</Link>
                        </div>
                        <div className="content-section--report__column">
                            {this.state.partner.lastname}, {this.state.partner.firstname}
                        </div>
                        <div className="content-section--report__column">
                            {this.state.partner.company}
                        </div>
                        <div className="content-section--report__column">
                            {this.state.payouts && this.state.payouts[0] && this.formatDate(this.state.payouts[0].settled_date)}
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {this.state.payouts && this.calcTotalPayouts()}
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {this.state.sales && this.calcTotalCommissions()}
                        </div>
                        <div className="content-section--report__column content-section--report__column--amount">
                            {this.state.sales && this.calcTotalSales()}
                        </div>
                    </div>
                )
            }
        }
    )
)




export default withTranslation()(withRouter(ReportOverviewPartnerSummary));
