import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Link } from 'react-router-dom';

import Template from '../templates/template';

const AdminVideos = inject("stores") (
    observer (
        class AdminVideos extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;

                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;
                
                this.state = {
                }
            }

            componentDidMount = () => {

            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'content'
                        feature = 'videos'
                        title = 'Videos'
                    >   
                        <div className="wrapper--content__header">
                            <h1>Videos</h1>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Overzichtspagina</h2>
                            </div>

                            <div className="content--section__columns">
                                <div className="content--section__column">
                                    <p>Definieer videos op de overzichtspagina in de database tabel 'Videos'.</p>
                                </div>
                            </div>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Ondertiteling videos</h2>
                            </div>

                            <div className="content--section__columns">
                                <div className="content--section__column">
                                    <p>Videos kunnen alleen een ondertiteling dmv een VTT-file hebben.</p>
                                    <p>Converteer een SRT-file via <Link to="/srt-to-vtt">deze tool</Link>.</p>
                                </div>
                            </div>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Directe link naar video op video-pagina (bijv vanuit nieuwsbrief)</h2>
                            </div>

                            <div className="content--section__columns">
                                <div className="content--section__column">
                                    <p>Gebruik deze link als voorbeeld:</p>
                                    <ul>
                                        <li>https://www.succesboeken.nl/api/newsletters/newsletter-link.php?wk=2332&link=video&vid=interview</li>
                                    </ul>
                                    <p>Door gebruik van deze link wordt geteld hoe vaak deze videopagina bezocht wordt.<br/>
                                    Hierbij is:</p>
                                    <ul>
                                        <li>wk=2332: weeknummer van nieuwsbrief</li>
                                        <li>vid=interview: 'interview' is de referentie zoals opgegeven in de database tabel 'Videos'.<br/>
                                        <strong>Let op:</strong> gebruik geen nummers aan einde: dus niet 'illusion2', maar bijv 'illusiontwee'.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminVideos));
