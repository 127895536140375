import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import validate from '../../utilities/validation-rules';
import * as login from '../../communicator/login';

import TextInput from '../../elements/form-input-text';

const FormLogin = inject("stores") (
    observer (
        class FormLogin extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    formControls: {          
                        email: {
                            label: i18n.t("forms.label.email"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.email"),
                            touched: false,
                            validationRules: {
                                isRequired: false,
                                isEmail: false
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            required: true
                        },
                        password: {
                            label: i18n.t("forms.label.password"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.password"),
                            touched: false,
                            validationRules: {
                                isRequired: false
                            },
                            required: true,
                            is_secret: true,
                            secret: true
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled',
                    error: false
                }

                this.storeUi = this.props.stores.storeUi;
            }

            async loginAdmin(e) {
                e.preventDefault();
                if (this.state.formIsValid) {
                    try {
                        let returnData = await login.checkLoginAdmin(this.state.formControls.email.value, this.state.formControls.password.value);
                        runInAction(() => {
                            if (!('error' in returnData.data[0])) {
                                this.storeUi.checkLoginAdmin();
                            } else {
                                this.storeUi.setLoginState(false);
                                this.setState({
                                    error: true
                                })
                            } 
                        })
                    } catch (error) {
                        runInAction(() => {
                            this.storeUi.setLoginState(false);
                        })
                    }
                }
                this.props.onNext();
            }

            handleChange = (event, action) => {     
                const name = event.target.name;
                const value = event.target.value;
          
                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;
                if (this.state.formControls[name].value === "") {
                    updatedFormElement.touched = false;
                }
                
                if (name == 'email' && !updatedFormElement.touched && action !== 'blur') {
                    updatedControls[name] = updatedFormElement;
                } else {
                    updatedFormElement.touched = true;
                    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
                
                    updatedControls[name] = updatedFormElement; 
                }

                //SbC check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                    }
                }

                this.setState({
                    formControls: updatedControls,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                }); 
            }

            showSecret = (key) => {
                const name = key;
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.secret = !this.state.formControls[name].secret;
                updatedControls[name] = updatedFormElement;
               
                this.setState({
                    formControls: updatedControls
                }); 
            }

            render() {

                return (
                    <div className="wrapper--form --centered">
                        <div className="wrapper--form__help">
                            <span className="wrapper--form__help--required">{i18n.t("form_feedback.required")}</span>
                        </div>
                        
                        <div className="form-fieldset">
                            <form className="form">
                                {Object.keys(this.state.formControls).map((key, i) => (
                                    <div className="form-row form-row--input" key={i}>
                                        <label className={"form-label" + (this.state.formControls[key].required ? ' --required' : '')}>{this.state.formControls[key].label}</label>
                                        <TextInput 
                                            name = {key} 
                                            placeholder = {this.state.formControls[key].placeholder}
                                            value = {this.state.formControls[key].value}
                                            touched = {this.state.formControls[key].touched}
                                            onChange = {this.handleChange}
                                            onBlur = {(e) => this.handleChange(e, 'blur')}
                                            valid = {this.state.formControls[key].valid}
                                            msg = {this.state.formControls[key].validationMsg}
                                            is_secret = {this.state.formControls[key].is_secret}
                                            secret = {this.state.formControls[key].secret ? true: false} 
                                            showSecret = {() => this.showSecret(key)}
                                        />
                                    </div>
                                ))}
                            </form>
                            
                            <div className="form-row form-row--buttons">
                                <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.loginAdmin(e)}>{i18n.t("button.login")}</button>
                            </div>
                            {this.state.error &&
                                <div className="form-row form-row--error">
                                    <p>{i18n.t("errors.error_login")}</p>
                                </div>
                            }
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(FormLogin);
