import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import TextInput from '../../elements/form-input-text';
import FormNewsletterSelect from '../../components/forms/form-newsletter-select';
import FormNewsletterTags from '../../components/forms/form-newsletter-tags';
import Card from '../../components/documentation/card';

const NewsletterSend = inject("stores") (
    observer (
        class NewsletterSend extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeDocs = this.props.stores.storeDocs;
                
                this.state = {
                    formControls: {
                        email: {
                            label: 'E-mail',
                            value: '',
                            placeholder: 'Vul e-mail in',
                        },
                        firstname: {
                            label: 'Voornaam',
                            value: '',
                            placeholder: 'Vul voornaam in',
                        }
                    },
                    wk: '',
                    week_dir: '',
                    week_file: '',
                    week_tag: '',
                    week_str: ''
                }
            }

            componentDidMount = async() => {
                const file = 'features';
                const result = await this.storeDocs.getDocumentation(file);

                const doc_send = this.storeDocs.docs_features
                    .filter((doc) => doc.id == "NEWSLETTER_SEND")
                this.setState({
                    doc_send: doc_send[0]
                })
                
            }

            handleChange = (event, action) => {     
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            setSectionValue = (section, key, value) => {
                const updatedControls = {
                    ...this.state
                };

                updatedControls[key] = value;
          
                this.setState({
                    ...updatedControls
                })
            }
          
            submitMailingEmail = () => {
                let wk = this.state.wk;
                let week_dir = this.state.week_dir;
                //let mailing = '../mailings/' + week_dir + '/' + week_dir + '.htm';
                let to_firstname = this.state.formControls.firstname.value;
                let to_email = this.state.formControls.email.value;

                this.storeUi.sendMailingNew('email', wk, week_dir, to_firstname, to_email);
            }

            submitMailingTest = () => {
                let wk = this.state.wk;
                let week_dir = this.state.week_dir;
                let mailing = '../mailings/' + week_dir + '/' + week_dir + '.htm';
                let title = this.state.newsletter.title;    

                this.storeUi.sendMailing('test', wk, week_dir, mailing, title, 'x', 'x');
            }

            submitMailingTestNew = () => {
                let wk = this.state.wk;
                let week_dir = this.state.week_dir;
                //let mailing = '../mailings/' + week_dir + '/' + week_dir + '.htm';

                this.storeUi.sendMailingNew('test', wk, week_dir, 'x', 'x');
            }

            submitMailing = () => {
                let wk = this.state.wk;
                let week_dir = this.state.week_dir;
                let mailing = '../mailings/' + week_dir + '/' + week_dir + '.htm';
                let title = this.storeUi.active_mailing_title;

                this.storeUi.openDialogMailing(wk, week_dir, mailing, title);
            }

            render() {
                return (                  
                    <div className="content--section">
                        <div className="content--section__header">
                            <h2>Verstuur nieuwsbrief</h2>
                        </div>
                        <div className="content--section__columns">
                            <div className="content--section__column content--section">
                                <FormNewsletterSelect 
                                    section = 'SEND'
                                    setSectionValue = {this.setSectionValue}
                                />
                            </div>

                            <div className="content--section__column">
                                {/* <h3>Test nieuwsbrief</h3>
                                <p>Verstuur nieuwsbrief aan test-mailinglist</p>
                                <div className="content--section__column--section">
                                    <strong>mailingtest@succesboeken.nl</strong>
                                    <div className="content--section__form-actions">
                                        {this.state.week_tag === ''
                                            ?   <button className="btn btn--primary --disabled">Verstuur testmailing</button>
                                            :   <button className="btn btn--primary" onClick={(e) => this.submitMailingTest()}>Verstuur testmailing</button>
                                        }
                                    </div>
                                    {this.storeUi.mailing_sent_test &&
                                        <div className="--success">
                                            Test mailing is verstuurd
                                        </div>
                                    }
                                </div> */}

                                <h3>Test nieuwe layout nieuwsbrief</h3>
                                <p>Test nieuwe layout nieuwsbrief aan test-mailinglist</p>
                                <div className="content--section__column--section">
                                    <strong>mailingtest@succesboeken.nl</strong>
                                    <div className="content--section__form-actions">
                                        {this.state.week_tag === ''
                                            ?   <button className="btn btn--primary --disabled">Verstuur testmailing</button>
                                            :   <button className="btn btn--primary" onClick={(e) => this.submitMailingTestNew()}>Verstuur testmailing</button>
                                        }
                                    </div>
                                    {this.storeUi.mailing_sent_test_new &&
                                        <div className="--success">
                                            Test mailing NIEUW is verstuurd
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="content--section__column">
                                <h3>Verstuur nieuwsbrief</h3>
                                <p>Aan een enkel e-mail adres.<br/>(de voornaam zal de aanhef worden)</p>
                                <div className="content--section__column--section">
                                    <div>
                                        {Object.keys(this.state.formControls).map((key) => (
                                            <div>
                                                <label className="form-label">{this.state.formControls[key].label}</label>
                                                <span className="block block-edit__listitem--field">
                                                    <TextInput 
                                                        name = {key} 
                                                        placeholder = {this.state.formControls[key].placeholder}
                                                        value = {this.state.formControls[key].value}
                                                        onChange = {(e) => this.handleChange(e)}
                                                        touched = {this.state.formControls[key].touched}
                                                    />
                                                </span>
                                            </div>       
                                        ))}
                                    </div>
                                    <div className="content--section__form-actions">
                                        {(this.state.week_tag === '' || this.state.formControls.email.value === '')
                                            ?   <button className="btn btn--primary --disabled">Verstuur nieuwsbrief</button>
                                            :   <button className="btn btn--primary" onClick={(e) => this.submitMailingEmail()}>Verstuur nieuwsbrief</button>
                                        }
                                    </div>
                                    {this.storeUi.mailing_sent_email &&
                                        <div className="--success">
                                            Nieuwsbrief is verstuurd naar enkel e-mail adres
                                        </div>
                                    }
                                </div>
                            
                                <h3>Verstuur nieuwsbrief</h3>
                                <p>Aan volledige mailinglist<br/>(er wordt hierna eerst nog om een bevestiging gevraagd)</p>
                                
                                <div className="content--section__column--section">
                                    <p><strong>mailingaccounts@succesboeken.nl</strong></p>
                                    <div className="content--section__form-actions">
                                        {this.state.week_tag === ''
                                            ?   <button className="btn btn--primary --disabled">Verstuur mailing</button>
                                            :   <button className="btn btn--primary" onClick={(e) => this.submitMailing()}>Verstuur mailing</button>
                                        }
                                    </div>
                                    {this.storeUi.mailing_sent_full &&
                                        <div className="--success">
                                            Mailing is verstuurd naar volledige mailinglijst
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(NewsletterSend));
