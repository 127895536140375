import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Switch, Route } from 'react-router-dom';
import i18n from "i18next";
import { withRouter } from "react-router-dom";
import './assets/scss/succesadmin.scss';

import Start from './pages/start';

import DialogFeedback from './components/dialogs/dialog-feedback';
import DialogMailing from './components/dialogs/dialog-mailing';

import AdminBooks from './pages/admin-books';
import AdminCategories from './pages/admin-categories';
import AdminCustomer from './pages/admin-customer';
import AdminCustomers from './pages/admin-customers';
import AdminContentBooks from './pages/admin-contentbooks';
import AdminEBooks from './pages/admin-ebooks';
import AdminNotifications from './pages/admin-notifications';
import AdminOrder from './pages/admin-order';
import AdminOrders from './pages/admin-orders';
import AdminOrderErrors from './pages/admin-order-errors';
import AdminOverviewSales from './pages/administration/admin-report-sales';
import AdminPartner from './pages/admin-partner';
import AdminPartners from './pages/admin-partners';
import AdminPartnerChecks from './pages/admin-partner-checks';
import AdminSettings from './pages/admin-settings';
import AdminQuotes from './pages/admin-quotes';
import AdminToplists from './pages/admin-toplists';
import AdminVideos from './pages/admin-videos';
import Analytics from './pages/analytics';
import CB from './pages/cb';
import CBChecks from './pages/content/cb-checks';
import CBCheckUpdates from './pages/content/cb-overview-updates';
import CBCompareStock from './pages/content/cb-compare-stock';
import CBReports from './pages/content/cb-overview-init';
import ConfirmPartner from './pages/content/confirm-partner';
import Documentation from './pages/content/documentation';
import Downloads from './pages/content/downloads';
import Emails from './pages/content/emails';
import MyParcel from './pages/myparcel';
import Newsletters from './pages/content/newsletters';
import Login from './pages/login';
import ReportAccounts from './pages/administration/admin-report-accounts';
import ReportNewsletter from './pages/analytics/report-newsletter';
import ReportNewsletterLinks from './pages/analytics/report-newsletter-links';
import ReportSalesSku from './pages/analytics/report-sales-sku';
import SMS from './pages/sms';
import ToolTranslation from './pages/tools/tool-translation';
import ToolSrtToVtt from './pages/tools/tool-srt-to-vtt';

import WikiStart from './pages/wiki/start';

const App = inject("stores") (
    observer (
        class App extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeEmail = this.props.stores.storeEmail;
            }

            render() {
                return (
                    <React.Fragment>
                        <div className="app-content">
                            <Switch>
                                <Route exact path='/' component={Start} />
                                <Route path='/admin-books' component={AdminBooks} />
                                <Route path='/admin-contentbooks' component={AdminContentBooks} />
                                <Route path='/admin-overview-sales' component={AdminOverviewSales} />
                                <Route path='/admin-categories' component={AdminCategories} />
                                <Route path='/admin-notifications' component={AdminNotifications} />
                                <Route 
                                    path='/admin-customer/:id'
                                    render={(routeProps) => (<AdminCustomer 
                                        {...routeProps} 
                                    />)}
                                />
                                <Route path='/admin-customers' component={AdminCustomers} />
                                <Route 
                                    path='/admin-order/:id'
                                    render={(routeProps) => (<AdminOrder 
                                        {...routeProps} 
                                    />)}
                                />
                                <Route path='/admin-orders' component={AdminOrders} />
                                <Route 
                                    path='/admin-order-errors' 
                                    component={AdminOrderErrors} 
                                />
                                <Route 
                                    path='/admin-partner/:id' 
                                    render={(routeProps) => (<AdminPartner 
                                        {...routeProps} 
                                    />)}
                                />
                                <Route path='/admin-partners' component={AdminPartners} />
                                <Route path='/admin-partner-checks' component={AdminPartnerChecks} />
                                <Route path='/admin-quotes' component={AdminQuotes} />
                                <Route path='/admin-settings' component={AdminSettings} />
                                <Route path='/admin-toplists' component={AdminToplists} />
                                <Route path='/analytics' component={Analytics} />
                                <Route path='/cb' component={CB} />
                                <Route path='/cb-check-updates' component={CBCheckUpdates} />
                                <Route path='/cb-compare-stock' component={CBCompareStock} />
                                <Route path='/cb-checks' component={CBChecks} />
                                <Route path='/cb-reports' component={CBReports} />
                                <Route path='/confirm-partner' component={ConfirmPartner} />
                                <Route path='/documentation' component={Documentation} />
                                <Route path='/wiki' component={WikiStart} />
                                <Route path='/admin-ebooks' component={AdminEBooks} />
                                <Route path='/emails' component={Emails} />
                                <Route 
                                    path={[
                                        '/link-download',
                                        '/downloads' 
                                    ]}
                                    component={Downloads} 
                                />
                                <Route path='/login' component={Login} />
                                <Route 
                                    path='/myparcel/:id?'
                                    render={(routeProps) => (<MyParcel 
                                        {...routeProps} 
                                    />)}
                                />
                                <Route path='/newsletters' component={Newsletters} />
                                <Route path='/report-accounts' component={ReportAccounts} />
                                <Route path='/report-sales-sku' component={ReportSalesSku} />
                                <Route path='/report-newsletter' component={ReportNewsletter} />
                                <Route path='/report-newsletter-links' component={ReportNewsletterLinks} />
                                <Route path='/sms' component={SMS} />
                                <Route path='/start' component={Start} />
                                <Route path='/translation' component={ToolTranslation} />
                                <Route path='/srt-to-vtt' component={ToolSrtToVtt} />
                                <Route path='/admin-videos' component={AdminVideos} />
                            </Switch>
                        </div>

                        {this.storeEmail.admin_interface.isDialogFeedbackOpen &&
                            <DialogFeedback
                                show = {this.storeEmail.admin_interface.isDialogFeedbackOpen}
                                onClose = {() => this.storeEmail.closeDialogFeedback()}
                                title = {'De e-mail is verstuurd'}
                                buttons = {
                                    [{
                                        text: "Ok",
                                        onClick: () => {
                                            this.storeEmail.closeDialogFeedback();
                                        },
                                        btnClass: "--primary"
                                    }]
                                }
                            >
                            </DialogFeedback>
                        }

                        {this.storeUi.admin_interface.isDialogMailingOpen &&
                            <DialogMailing
                                show = {this.storeUi.admin_interface.isDialogMailingOpen}
                                onClose = {() => this.storeUi.closeDialogMailing()}
                                title = {i18n.t("popups.confirmation-mailing.title")}
                                buttons = {
                                    [{
                                        text: "Annuleer",
                                        type: 'cancel',
                                        onClick: () => this.storeUi.closeDialogMailing(),
                                        btnClass: "--secundary"
                                    },{
                                        text: "Ok, verstuur mailing",
                                        type: 'submit',
                                        onClick: () => {
                                            //this.storeUi.sendMailing('full', this.storeUi.active_mailing_wk, this.storeUi.active_mailing_week_dir, this.storeUi.active_mailing, this.storeUi.active_mailing_title, 'x', 'x');
                                            this.storeUi.sendMailingNew('full', this.storeUi.active_mailing_wk, this.storeUi.active_mailing_week_dir, 'x', 'x');
                                            this.storeUi.closeDialogMailing();
                                        },
                                        btnClass: "--primary"
                                    }]
                                }
                            >
                            </DialogMailing>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(App);