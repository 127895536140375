import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Loader from '../elements/loader';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';
import ReportOverviewPartnerChecks from '../components/partners/report-overview-partner-checks';

const AdminPartnerChecks = inject("stores") (
    observer (
        class AdminPartnerChecks extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storePartners = this.props.stores.storePartners;
                
                this.state = {
                    loading: false,
                    feedback: '',
                    formControls: {
                        
                    }
                }
            }

            componentDidMount = async() => {
            }

            recalcPartnerOverview = async() => {
                this.setState({
                    loading: true,
                    feedback: ''
                }, () => this.loadPartnerOverview())
            }

            loadPartnerOverview = async() => {
                const partners = await this.storePartners.initPartners();

                this.setState({
                    loading: false,
                    feedback: "Het overzicht is vernieuwd."
                })
            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                        section = 'shop'
                        feature = 'partnerchecks'
                        title = 'Controle partners'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Controle partners</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Overzicht</h2>
                                    <ul>
                                        <li>0 - geen check of nog te doen</li>
                                        <li>100 - geen fouten/onduidelijkheden (groen)</li>
                                        <li>200 - technische fouten/onduidelijkheden - controle/actie techniek nodig (rood)</li>
                                        <li>300 - admin fouten/onduidelijkheden - controle/actie admin nodig (geel)</li>
                                    </ul>
                                    <ReportOverviewPartnerChecks 
                                    
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminPartnerChecks));
