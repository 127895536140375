import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import SettingsWebsite from '../components/settings/settings-website';
import SettingsAdmin from '../components/settings/settings-admin';

const AdminSettings = inject("stores") (
    observer (
        class AdminSettings extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = async() => {

            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'general'
                        feature = 'settings'
                        title = 'Settings'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Settings</h1>
                            </div>

                            <div className="content--section__columns">
                                <div className="content--section__column">
                                    <div className="content--section">
                                        <div className="content--section__header">
                                            <h2>Settings website</h2>
                                        </div>

                                        <SettingsWebsite 
                                        
                                        />
                                    </div>
                                </div>
                                <div className="content--section__column">
                                    <div className="content--section">
                                        <div className="content--section__header">
                                            <h2>Settings admin</h2>
                                        </div>

                                        <SettingsAdmin

                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminSettings));
