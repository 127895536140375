import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import TextInput from '../../elements/form-input-text';
import Counter from '../ui/counter';
import GraphBars from '../ui/graph-bars';

const NewsletterLinks = inject("stores") (
    observer (
        class NewsletterLinks extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                this.storeAnalytics = this.props.stores.storeAnalytics;
                
                this.state = {
                    tag: '',
                    count_home: 0,
                    count_book: 0,
                    count_partner: 0,
                    count_recent: 0,
                    count_ebooks: 0,
                    count_facebook: 0                        
                }

                this.arr_links = ['book', 'home', 'partner', 'recent', 'ebooks', 'facebook'];
            }

            componentDidMount = async() => {
                let count;
                let counter;
                const links = await this.storeAnalytics.checkLinksNewsletter(this.props.tag);

                this.arr_links.map((str_link, idx) => {
                    count = 0;
                    links.map((item) => { 
                        if (item.link == str_link && this.props.tag == item.tag) {
                            count++;
                        }
                    })

                    let newState = {};
                    counter = "count_" + this.arr_links[idx];
                    newState[counter] = count;
                    this.setState(newState);
                });

                
            }

            componentDidUpdate = async() => {

            }

            render() {
                return (                  
                    <div className="content--section__table-row">
                        <div className="table--counters__column">
                            {this.props.tag}
                        </div>
                        {this.arr_links.map((counter) => ( 
                            <div className="table--counters__column">
                                {this.props.view === 'counter' &&
                                    <Counter
                                        value = {this.state["count_" + counter]}
                                        title = {''}
                                        value_alert = {20}
                                    />
                                }
                                {this.props.view === 'number' &&
                                   this.state["count_" + counter]
                                }
                            </div>
                        ))}
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(NewsletterLinks));
