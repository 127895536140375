import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import moment from "moment";
import * as sort from '../../utilities/sort';

import Loader from '../../elements/loader';

const CBReportDailyLine = inject("stores") (
    observer (
        class CBReportDailyLine extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeCb = this.props.stores.storeCb;

                this.state = {
                    expanded: false,
                    view: 5
                }
            }

            componentDidMount = () => {
            }

            componentDidUpdate = (prevProps) => {

            }

            handleToggle = () => {
                this.setState({
                    expanded: !this.state.expanded
                })
            }

            render() {
                const arrDay = this.storeCb.files_daily
                    .filter((file) => file && file.date == this.props.day);

                const count = arrDay.length;

                let status = 'ok';
                if (count > 0) { 
                    status = 'ok';
                    arrDay.forEach((file) => {return file.status !== 'ok' ? status = 'todo' : status = status})
                } else {
                    status = 'no files';
                }

                return (
                    <React.Fragment>
                        <tr className="table--reports__row--header">
                            <td colSpan={3}>
                                {count > 0
                                    ?   <span onClick={() => this.handleToggle()} className="--link">{this.props.day} {this.state.expanded ? 'Verberg files' : 'Toon files'}</span>
                                    :   <span className="">{this.props.day}</span>
                                }
                            </td>
                            <td>
                                <div className="table--reports__cell table--reports__cell--status">
                                    {(status === 'no files' && this.props.idx === 0) 
                                    ?   <span className="btn btn--primary --restart" onClick={(e) => this.props.restartCbDailyUpdate()}>Restart&nbsp;CB&nbsp;FTP&nbsp;&&nbsp;db</span>
                                    :   <React.Fragment>
                                            <span className={"--" + status}>{status}</span>
                                            {status !== 'no files' &&
                                                <React.Fragment>
                                                    {status === 'ok' &&
                                                        <span className="btn btn--secundary" onClick={() => this.props.startCbDaily('day', this.props.day)}>Reload updates</span>
                                                    }
                                                    {status === 'todo' && 
                                                        <span className="btn btn--primary" onClick={() => this.props.startCbDaily('day', this.props.day)}>Start updates</span>
                                                    }
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                            </td>
                        </tr>
                        {this.state.expanded &&
                             <React.Fragment>                           
                                {arrDay && arrDay.map((file) => (
                                    <CBReportDailyFile
                                        filename = {file.filename}
                                        data = {file}
                                        loading_file = {this.storeCb.loading_file_daily}
                                        startCbDaily = {this.props.startCbDaily}
                                        startResourcesDaily = {this.props.startresourcesDaily}
                                        items_added = {file.items_added}
                                        items_changed = {file.items_changed}
                                        status = {file.status}
                                    />
                                ))}
                         </React.Fragment> 
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

const CBReportDailyFile = inject("stores") (
    observer (
        class CBReportDailyFile extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeCb = this.props.stores.storeCb;

                this.state = {
                    loading: false,
                    status: this.props.data.status,
                    filename: '',
                    items_added:  0,
                    items_changed: 0
                }
            }

            componentDidMount = () => {
                this.setState({
                    items_added: this.props.items_added,
                    items_changed: this.props.items_changed,
                    status: this.props.status
                })
            }

            componentDidUpdate = (prevProps) => {
               
                if (prevProps.loading_file !== this.props.loading_file) {
                    let status = '';
                    const idx = this.storeCb.files_daily
                        .findIndex((file) => file && file.filename === this.props.filename);
                    status = this.storeCb.files_daily[idx].status;
                    this.setState({
                        loading: this.props.filename == this.props.loading_file ? true : false,
                        status: status,
                        items_added:  this.storeCb.files_daily[idx].items_added,
                        items_changed:  this.storeCb.files_daily[idx].items_changed
                    })
                }

                if (prevProps.items_added !== this.props.items_added) {
                    this.setState({
                        items_added: this.props.items_added
                    })
                }

                if (prevProps.items_changed !== this.props.items_changed) {
                    this.setState({
                        items_changed: this.props.items_changed
                    })
                }

                if (prevProps.status !== this.props.status) {
                    this.setState({
                        status: this.props.status
                    })
                }
            }

            render() {
                return (
                    <React.Fragment>
                        <tr className="table--reports__row--data">
                            <td>{this.props.filename}</td>
                            <td>{this.state.items_added}</td>
                            <td>{this.state.items_changed}</td>
                            <td>
                                {this.state.loading
                                ?   <div className="status">
                                        <Loader />
                                        <span>loading...</span>
                                    </div>
                                :   <div className="status">
                                        <span>
                                            {this.state.status}
                                        </span>  
                                        {this.state.status === 'ok'
                                        ?   <React.Fragment>
                                                <span className="btn btn--secundary" onClick={() => this.props.startCbDaily(this.props.filename, this.props.data.date)}>Reload</span>
                                            </React.Fragment>
                                        :   <span className="btn btn--primary" onClick={() => this.props.startCbDaily(this.props.filename, this.props.data.date)}>Start update</span>
                                        }
                                    </div>                            
                                }
                            </td>
                        </tr>
                    </React.Fragment>
                )
            }
        }
    )
)

const CBReportDaily = inject("stores") (
    observer (
        class CBReportDaily extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_CUSTOM_NODE_ENV;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeCb = this.props.stores.storeCb;

                this.state = {
                    view: 5
                }
            }

            componentDidMount = async() => {
                const status = await this.storeCb.getInitReports('DAILY');
            }

            clearUpdates = async() => {
                const cleared = await this.storeCb.clearUpdates();
            }

            // createDates = () => {
            //     let day;
            //     let month;
            //     let year;
            //     let date;
            //     Array.from(Array(10), (e, i) => {
            //         date = moment
            //         day = moment().format(DD);  
            //         month = moment().format(MM);
            //         year = moment().format(YYYY);
            //         str_date = year + month + day;
            //         return str_date;
            //     })
            // }

            // getListFiles = async() => {
            //     const list = await this.storeCb.listFilesDaily();
            //     console.log('SbC list daily', list)
            // }

            getUpdateReports = async() => {
                const status = await this.storeCb.getInitReports('DAILY');
            }

            startCbDaily = async(filename, date) => {
                let loaded;
                this.storeCb.clearFeedback();

                if (filename == 'all') {
                    for (let file of this.storeCb.files_daily) {
                        if (file.status !== 'ok') {
                            loaded = await this.storeCb.initCatalog('INIT_DAILY', file.filename, file.date);
                        }
                    }
                }
                if (filename == 'day') {
                    for (let file of this.storeCb.files_daily) {
                        if (file.date ===  date) {
                            loaded = await this.storeCb.initCatalog('INIT_DAILY', file.filename, file.date);
                        }
                    }
                } 
                if  (filename !== 'all' && filename !== 'day') {
                    loaded = await this.storeCb.initCatalog('INIT_DAILY', filename, date);
                }
            }

            restartCbDailyUpdate = () => {
                //SbC start gulp-file -> download with FTP and initiate propagating database
                this.storeCb.restartCbDailyUpdate();
            }

            startResourcesDaily = async(filename, date) => {
                
            }

            toggleView = () => {
                this.setState({
                    view: this.state.view === this.storeCb.dates.length ? 5 : this.storeCb.dates.length
                })
            }

            render() {
                const arrDates = this.storeCb.dates.sort((a,b) => b - a);
                return (
                    <div className="content--section">
                        <div className="content--section__header">
                            <h2>Dagelijkse updates</h2>
                            <span onClick={() => this.toggleView()} className="--link">
                                {this.state.view !== this.storeCb.dates.length
                                ?   'Toon laatste 20 dagen'
                                :   'Toon alleen laatste 5 dagen'
                                }
                            </span>
                        </div>
                    
                        <div className="content--section__columns">
                            <table className="table--reports">
                            <thead>
                                <tr>
                                    <th>Datum</th>
                                    <th>Toegevoegd</th>
                                    <th>Gewijzigd</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {arrDates.map((day, index) => ( 
                                    index < this.state.view && 
                                        <CBReportDailyLine
                                            idx = {index}
                                            day = {day}
                                            view = {this.state.view}
                                            startCbDaily = {this.startCbDaily}
                                            restartCbDailyUpdate = {this.restartCbDailyUpdate}
                                        >
                                           
                                        </CBReportDailyLine>
                                ))}
                            </tbody>
                            </table>
                        </div>
                        
                        {this.env === 'DEV' &&
                            <div className="wrapper--content__columns">
                                <div className="content--section__actions">
                                    <span className="btn btn--secundary" onClick={() => this.clearUpdates()}>Maak lijst updates leeg</span>
                                    <span className="btn btn--secundary" onClick={() => this.getUpdateReports()}>Haal update reports op</span>
                                </div>
                            </div>
                        }
                    </div>
                )
            }
        }
    )
)

export default withRouter(CBReportDaily);
