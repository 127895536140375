import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import moment from "moment";
import * as sort from '../../utilities/sort';
import Loader from '../../elements/loader';
import TextInput from '../../elements/form-input-text';

const CBOverviewStockLine = inject("stores") (
    observer (
        class CBOverviewStockLine extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeCatalog = this.props.stores.storeCatalog;

                this.state = {
                    formControls: {
                        alternative: {
                            value: '',
                            placeholder: "Vul ISBN in"
                        },
                    }
                }
            }

            componentDidMount = async() => {
                console.log('SbC alt:', this.props.item.alternative)

            }

            componentDidUpdate = (prevProps) => {

            }

            handleChange = (event) => {     
                const name = 'alternative';
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            render() {
                return (
                    <React.Fragment>
                        <tr className="table--reports__row">
                            <td>
                                {this.props.item.sku}
                            </td>
                            <td>
                                {this.props.item.title}
                            </td>
                            <td>
                                {this.props.item.stock}
                            </td>
                            <td>
                                {(this.props.item.alternative === '' || !this.props.item.alternative)
                                ?   <TextInput 
                                        name = {'alternative'} 
                                        value = {this.state.formControls['alternative'].value}
                                        onChange = {(e) => this.handleChange(e)}
                                        touched = {this.state.formControls['alternative'].touched}
                                    />
                                :   this.props.item.alternative
                                }
                            </td>
                            <td>
                                <div className="content--section__actions content--section__actions--inline">
                                    {(this.props.item.alternative === '' || !this.props.item.alternative) &&
                                        <span className="btn btn--primary" onClick={() => this.props.setAlternative(this.props.item.sku, this.state.formControls['alternative'].value)}>Bewaar alternatief</span>
                                    }
                                    <span className="btn btn--primary" onClick={() => this.props.setStock(this.props.item.sku, 'inactive')}>Niet meer tonen</span>
                                </div>
                            </td>
                        </tr>
                        
                    </React.Fragment>
                )
            }
        }
    )
)

const CBOverviewStock = inject("stores") (
    observer (
        class CBOverviewStock extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.env = process.env.REACT_APP_CUSTOM_NODE_ENV;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeCb = this.props.stores.storeCb;
                this.storeCatalog = this.props.stores.storeCatalog;

                this.state = {
                    stock: []
                }
            }

            componentDidMount = async() => {
                const listOutOfStock = await this.storeCatalog.checkOutOfStock();
                const amountOutOfStock = listOutOfStock.length;
                this.setState({
                    stock: listOutOfStock,
                    amount: amountOutOfStock
                })
            }

            getUpdateStock = async() => {
                const listISBN = await this.storeCb.getOverviewStock();
            }

            setAlternative = async(sku, value) => {
                (async() => await this.storeCatalog.setAlternative(sku, value) )();
                const listOutOfStock = await this.storeCatalog.checkOutOfStock();
                const amountOutOfStock = listOutOfStock.length;
                this.setState({
                    stock: listOutOfStock,
                    amount: amountOutOfStock
                })
            }

            setStock = async(sku) => {
                (async() => await this.storeCatalog.removeFromSite(sku) )();
                const listOutOfStock = await this.storeCatalog.checkOutOfStock();
                const amountOutOfStock = listOutOfStock.length;
                this.setState({
                    stock: listOutOfStock,
                    amount: amountOutOfStock
                })
            }

            render() {
                return (
                    <div className="content--section">
                        <div className="content--section__header">
                            <h2>Voorraad (tijdelijk) niet leverbaar</h2>
                            <p>Aantal: {this.state.amount}</p>
                        </div>
                    
                        <div className="content--section__columns">
                            <table className="table--reports">
                            <thead>
                                <tr>
                                    <th>ISBN</th>
                                    <th>Titel</th>
                                    <th>SB Voorraad</th>
                                    <th>Alternatief</th>
                                    {/* <th>CB in stock</th>
                                    <th>CB stock code</th> */}
                                    {/* <th>CB Levertijd</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.stock.map((item, index) => ( 
                                    <CBOverviewStockLine
                                        item = {item}
                                        setStock = {this.setStock}
                                        setAlternative = {this.setAlternative}
                                    >
                                        
                                    </CBOverviewStockLine>
                                ))}
                            </tbody>
                            </table>
                        </div>
                        
                        <div className="wrapper--content__columns">
                            <div className="content--section__actions">
                                <span className="btn btn--secundary" onClick={() => this.getUpdateStock()}>Controleer voorraad</span>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(CBOverviewStock);
