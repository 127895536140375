import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';
import BlockBookDetails from '../components/blocks/block-book-details';
import BlockBookCategoriesSb from '../components/blocks/block-book-categories-sb';

const AdminBooks = inject("stores") (
    observer (
        class AdminBooks extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                
                this.state = {
                    book: {},
                    formControls: {
                        sku: {
                            value: '',
                            placeholder: "Vul ISBN in"
                        },
                        category: {
                            value: '',
                            placeholder: "Vul Categorie BoekenLijst2008 in"
                        },
                        subcategory: {
                            value: '',
                            placeholder: "Vul Subcategorie BoekenLijst2008 in"
                        },
                        category1: {
                            value: '',
                            placeholder: "Vul Categorie1 BoekenLijst2008 in"
                        },
                        subcategory1: {
                            value: '',
                            placeholder: "Vul Subcategorie1 BoekenLijst2008 in"
                        },
                        category2: {
                            value: '',
                            placeholder: "Vul Categorie2 BoekenLijst2008 in"
                        },
                        subcategory2: {
                            value: '',
                            placeholder: "Vul Subcategorie2 BoekenLijst2008 in"
                        },
                        category3: {
                            value: '',
                            placeholder: "Vul Categorie3 BoekenLijst2008 in"
                        },
                        subcategory3: {
                            value: '',
                            placeholder: "Vul Subcategorie3 BoekenLijst2008 in"
                        },
                        media: {
                            value: 'Boeken (Nederlands)',
                            placeholder: "Vul media type in"
                        },
                        priority: {
                            value: '',
                            placeholder: "Vul Volgorde in"
                        },
                        deliveryTime: {
                            value: '1-3 werkdagen',
                            placeholder: "Vul Levertijd in"
                        },
                        remarks: {
                            value: '',
                            placeholder: "Vul Opmerkingen in"
                        },
                        tags: {
                            value: '',
                            placeholder: "Vul Tags in"
                        }
                    }
                }
            }

            componentDidMount = async() => {
                const cats = await this.storeBooks.getCategories();
                this.setState({
                    cats: cats
                })
            }

            handleChange = (event, cname, cvalue) => { 
                this.storeBooks.clearFormFeedback();
                let name;
                let value;
                    
                if (cname) {
                    name = cname;
                } else {
                    name = event.target.name;
                }
                
                if (cvalue || cvalue === '') {
                    value = cvalue;
                } else {
                    value = event.target.value;
                }

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            handleClickCat = (e, name, value) => {
                if (this.state.formControls.category.value === '') {
                    this.handleChange(e, name, value);
                } else {
                    this.handleChange(e, 'category1', value);
                }
            }

            searchBook = async(e) => {
                const book = await this.storeBooks.searchBook(this.state.formControls['sku'].value);
                this.setState({
                    book: book
                })
            }

            clearField = (e, key) => {
                let value = '';
                this.handleChange(e, key, value);
            }

            copyBook = (e) => {
                this.storeBooks.copyBook(this.state.book, this.state.formControls)
            }

            countBooks = (e) => {
                this.storeUi.countBooks();
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        section = 'catalog'
                        feature = 'copybooks'
                        title = 'Boeken'
                    >   
                        <div className="wrapper--content__header">
                            <h1>Boeken</h1>
                        </div>

                        <div className="content--section">
                            <div className="content--section__header">
                                <h2>Kopieer boek van AlleBoeken naar Succesboeken.nl</h2>
                            </div>

                            <div className="content--section__columns">
                                <div className="content--section__column">
                                    <h4>Zoek boek in Succesboeken.nl (BoekenLijst2008)</h4>
                               
                                    <ul className="block block-edit__list list--clean">
                                        <li className="block block-edit__listitem">
                                            <span className="block block-edit__listitem--label">ISBN</span>
                                            <span className="block block-edit__listitem--field">
                                                <TextInput 
                                                    name = {'sku'} 
                                                    value = {this.state.formControls['sku'].value}
                                                    onChange = {(e) => this.handleChange(e)}
                                                    touched = {this.state.formControls['sku'].touched}
                                                />
                                            </span>
                                        </li>
                                    </ul>

                                    <div className="content--section__form-actions">
                                        <button className="btn btn--primary" onClick={(e) => this.searchBook(e)}>Zoek boek</button>
                                    </div>

                                    {Object.keys(this.storeBooks.form_feedback).length > 0 && 
                                        <div className="form__feedback">
                                            {'success' in this.storeBooks.form_feedback &&
                                                <React.Fragment>
                                                    <span 
                                                        className="form__feedback--success"
                                                        dangerouslySetInnerHTML={{ __html: this.storeBooks.form_feedback.success}}
                                                    />
                                                </React.Fragment>
                                            }
                                            {'error' in this.storeBooks.form_feedback &&
                                                <React.Fragment>
                                                    <span 
                                                        className="form__feedback--error"
                                                        dangerouslySetInnerHTML={{ __html: this.storeBooks.form_feedback.error}}
                                                    />
                                                </React.Fragment>
                                            }
                                        </div>
                                    }
                                </div>

                                <div className="content--section__column">
                                    <h4>Gevonden in AlleBoeken</h4>
                                
                                    <BlockBookDetails
                                        book = {this.state.book}
                                    />
                                </div>

                                <div className="content--section__column">
                                    <h4>Categorieen Succesboeken.nl</h4>
                                    
                                    <BlockBookCategoriesSb
                                        handleClickCat = {this.handleClickCat}
                                    />
                                
                                </div>

                                <div className="content--section__column">
                                    <h4>Kopieer boek naar Succesboeken.nl (BoekenLijst2008)</h4>

                                    <ul className="block block-edit__list list--vert">
                                        {Object.keys(this.state.formControls).map((key) => (
                                            key !== 'sku' &&
                                                <li className="block block-edit__listitem">
                                                    <span className="block block-edit__listitem--label">
                                                        {i18n.t("forms.label." + key)}
                                                    </span>
                                                    <span
                                                        className="block block-edit__listitem--delete --link"
                                                        onClick = {(e) => this.clearField(e, key)}
                                                    >
                                                        delete
                                                    </span>
                                                    <span className="block block-edit__listitem--field">
                                                        <TextInput 
                                                            name = {key} 
                                                            value = {this.state.formControls[key].value}
                                                            onChange = {(e) => this.handleChange(e)}
                                                            touched = {this.state.formControls[key].touched}
                                                        />
                                                    </span>
                                                </li>
                                        ))}
                                    </ul>

                                    <div className="content--section__form-actions">
                                        <button className="btn btn--primary" onClick={(e) => this.copyBook(e)}>Kopieer boek</button>
                                    </div>

                                    {Object.keys(this.storeBooks.form_feedback).length > 0 && 
                                        <div className="form__feedback">
                                            {'success' in this.storeBooks.form_feedback &&
                                                <React.Fragment>
                                                    <span className="form__feedback--success">{this.storeBooks.form_feedback.success}</span>
                                                </React.Fragment>
                                            }
                                            {/* {'error' in this.storeBooks.form_feedback &&
                                                <React.Fragment>
                                                    <span className="form__feedback--error">{this.storeBooks.form_feedback.error}</span>
                                                </React.Fragment>
                                            } */}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminBooks));
