import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

const GraphBars = inject("stores") (
    observer (
        class MyParcelRow extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
            }

            componentDidMount = () => {

            }

            render() {
                return ( 

                    <div className="container--graph graph--bars">
                        GRAPH
                    </div>
                )
            }
        }
    )
)

export default GraphBars;