import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

import WikiFeatures from '../../components/wiki/wiki-features';

const WikiSections = inject("stores") (
    observer (
        class WikiSections extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeWiki = this.props.stores.storeWiki;

                this.state = {
                    sections: []
                }
            }

            componentDidMount = async() => {
                const sections = await this.storeWiki.getSections();

                this.setState({
                    sections: sections
                })

            }

            handleSection = (sku) => {
                this.storeWiki.setActiveSection(sku);
                this.storeWiki.setActiveFeature('');
                this.storeWiki.setActiveFeatureTitle('');
            }

            render() {

                return (
                    <div className="container--card-wiki">
                        <ul
                            className="wiki-list--sections"
                        >
                            {this.state.sections && this.state.sections.map((section) => 
                                <li 
                                    className = {(this.storeWiki.activeSection === section.sku ? "wiki-list--section-active" : "") + " --link"}
                                >
                                    <div
                                        className="wiki-list--sections__title"
                                        onClick={() => this.handleSection(section.sku)}
                                    >
                                        {section.title}
                                    </div>

                                    <div
                                        className="wiki-list--sections__features"
                                    >
                                        <WikiFeatures 
                                            wikiSection = {section.sku}
                                        />
                                    </div>
                                </li>
                            )}
                       </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(WikiSections);
