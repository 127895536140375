import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import { Redirect } from 'react-router';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';

import OrderRowSummary from '../components/tables/order-row-summary';
import OrderRowDetails from '../components/tables/order-row-items';
import OrderRowProcess from '../components/tables/order-row-process';

const AdminOrder = inject("stores") (
    observer (
        class AdminOrder extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;
                
                this.state = {
                    order: {},
                    title: ''
                }

            }

            componentDidMount = async() => {
                const order_id = this.props.location.order_id;
                const order = await this.storeOrders.getOrder(order_id);
                this.setState({
                    order: order && order[0],
                    order_id: order_id
                })
            }

            createTrackTrace = async() => {
                let order_id = this.props.location.order_id;
                let url = '/myparcel/' + order_id;
                this.props.history.push(url);
            }

            viewInvoice = async(guid) => {
                
            }

            render() {
                const order = this.props.location.order;

                if (!order) {
                    return <Redirect 
                        push to={"/admin-orders"} 
                    />;
                }

                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Beheer order</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Order: {this.props.location.order_id}</h2>
                                    <div className="content--section__form-actions">
                                        {order.order_fulfilled !== null &&
                                            <a href={this.urlAPI + "/invoices/invoice.php?GUID=" + this.state.order.order_guid} className="btn btn--secundary" target="_blank">Bekijk of print factuur</a>
                                        }
                                        {order.order_tracktrace === null &&
                                            <button className="btn btn--secundary" onClick={(e) => this.createTrackTrace(e)}>Maak Track&Trace code</button>
                                        }
                                    </div>
                                </div>
                                <div className="content--section__columns">
                                    <table className="table table--orders-overview">
                                    <tbody>
                                        <OrderRowDetails 
                                            order_id = {this.props.location.order_id}
                                            order = {this.state.order}
                                            order_fulfilled = {this.state.order && this.state.order.order_fulfilled}
                                        />
                                    </tbody>
                                    </table>
                                </div>
                                <div className="content--section__columns">
                                    <table className="table table--orders-overview">
                                    <tbody>
                                        <OrderRowSummary 
                                            order_id = {this.state.order_id}
                                            order = {this.state.order}
                                        />
                                    </tbody>
                                    </table>
                                </div>
                                <div className="content--section__columns">
                                    <table className="table table--orders-overview">
                                    <tbody>
                                        <OrderRowProcess 
                                            order_id = {this.state.order_id}
                                            order = {this.state.order}
                                        />
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminOrder));
