import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import TemplateForm from '../../templates/template-form';
import TextInput from '../../elements/form-input-text';

const FormCategory = inject("stores") (
    observer (
        class FormCategory extends Component {

            constructor(props) {
                super(props);
                this.props = props;  

                this.storeUi = this.props.stores.storeUi;
                
                this.state = {
                    formControls: {
                        id: {
                            label: 'Nr',
                            value: '',
                            placeholder: 'Vul id in',
                        },
                        name: {
                            label: 'Naam',
                            value: '',
                            placeholder: 'Vul category in',
                        },
                        display_value: {
                            label: 'Getoonde waarde',
                            value: '',
                            placeholder: 'Display waarde',
                        },
                        is_main_category: {
                            label: 'Is hoofdcategorie',
                            value: '',
                            placeholder: 'Vul category in',
                        },
                        main_category: {
                            label: 'Nr hoofdcategorie',
                            value: '',
                            placeholder: 'Vul category in',
                        }
                    }
                }
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = async(prevProps) => {

            }

            handleChange = (event) => {     
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            submitData = (e) => {
                e.preventDefault();
                this.storeUi.addCategory(this.state.formControls)
            }

            render() {

                return (                  
                    <TemplateForm
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div className="wrapper--form__help">
                                <span className="wrapper--form__help--required">Verplichte velden</span>
                            </div>
                            <div className="form-fieldset">
                                {Object.keys(this.state.formControls).map((key) => (
                                    <li className="block block-edit__listitem">
                                        <span className="block block-edit__listitem--label">{this.state.formControls[key].label}</span>
                                        <span className="block block-edit__listitem--field">
                                            <TextInput 
                                                name = {key} 
                                                value = {this.state.formControls[key].value}
                                                onChange = {(e) => this.handleChange(e)}
                                                touched = {this.state.formControls[key].touched}
                                            />
                                        </span>
                                    </li>
                                ))}
                            </div>
                            <div className="content--section__form-actions">
                                <button className="btn btn--primary" onClick={(e) => this.submitData(e)}>Voeg categorie toe</button>
                            </div>
                        </React.Fragment>
                    </TemplateForm>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(FormCategory));
