import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import CBReportDaily from '../cb/cb-report-daily';
import CBReportInit from '../cb/cb-report-init';
import CBReportOverview from '../cb/cb-report-overview';

import Select from '../../elements/form-input-select';

const CheckCovers = inject("stores") (
    observer (
        class CheckCovers extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeCb = this.props.stores.storeCb;
                
                this.state = {
                    files: [],
                    formControls: {
                        file_select: {
                            label: 'Kies init file',
                            value: 1,
                            placeholder: 'Kies init file',
                            touched: false
                        }
                    },
                    feedback: ''
                }
            }

            componentDidMount = async() => {

            }

            checkCovers = async() => {
                const skus = await this.storeCb.checkCoversMissing();
                const amount = this.storeCb.covers_missing.length;

                this.setState({
                    skus_covers: skus, 
                    feedback: 'success',
                    amount_covers: amount
                })
            }

            render() {
                return (                  
                    <div className="content--section">
                        <div className="content--section__header">
                            <h2>{this.props.title}</h2>
                            <p>{this.props.subtitle}</p>
                        </div>

                        <div className="wrapper--content__columns">
                            <div className="content-column">
                                <div className="content--section__columns">
                                    <table className="table table--reports">
                                    <thead>
                                        <tr>
                                            <th>ISBN</th>
                                            <th>Cover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.skus_covers && this.state.skus_covers.map((sku) => (
                                            <tr>
                                                <td>
                                                    {sku}
                                                </td>
                                                <td>
                                                    xx
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                </div>

                                {this.state.feedback !== '' &&
                                    <div>
                                        Aantal: {this.state.amount_covers}
                                    </div>
                                }
                            
                                <div className="content--section__actions">
                                    <button className="btn btn--secundary" onClick={() => this.checkCovers()}>Controleer covers</button>
                                    {/* <button className="btn btn--primary" onClick={() => this.getResources()}>Haal covers op</button> */}
                                </div>
                            </div>
                        </div>
                    </div>                                                
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CheckCovers));
