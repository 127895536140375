import * as apifetch from './fetch.js';

export async function getAllCustomers() {
    const urlEndpoint = '/admin/accounts/getAllAccounts.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getCustomer(data) {
    const urlEndpoint = '/admin/accounts/getAccount.php';

    const postData = data;
    
    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function searchCustomers(data) {
    const urlEndpoint = '/v2/admin/accounts/search';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}