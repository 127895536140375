import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import ReportSalesShops from './report-sales-shops';

const AdminOverviewSalesTotals = inject("stores") (
    observer (
        class AdminOverviewSalesTotals extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storeCatalog = this.props.stores.storeCatalog;
                this.storeReports = this.props.stores.storeReports;

                this.state = {

                }
            }

            componentDidMount = async() => {
                let year = this.props.year;
                let month = this.props.month;

                // const list_totals = await this.storeCatalog.getOrdersByMonth(year, month);

                // this.setState({
                //     list_totals: list_totals
                // })

                let shop;

                if (this.props.shop === 'SHOP_SB') {
                    shop = 1;
                } else {
                    shop = 2;
                }

                const list_totals = await this.storeReports.getOrdersByShop(year, month, shop);
                console.log('SbC totals shop', list_totals)

                this.setState({
                    list_totals: list_totals
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.year !== this.props.year) {
                    let year = this.props.year;
                    let month = this.props.month;
                    let shop;

                    if (this.props.shop === 'SHOP_SB') {
                        shop = 1;
                    } else {
                        shop = 2;
                    }

                    const list_totals = await this.storeReports.getOrdersByShop(year, month, shop);

                    this.setState({
                        list_totals: list_totals
                    })
                }
            }

            render() {
                return (  
                    <td>
                        {/* <span>{this.state.list_totals}</span> */}
                    </td>
                )
            }
        }
    )
)

const AdminOverviewSalesRow = inject("stores") (
    observer (
        class AdminOverviewSalesRow extends Component {

            constructor(props) {
                super(props);
                this.props = props; 
                this.storeReports = this.props.stores.storeReports;
                
                this.state = {
                    list_cats: []
                }
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.has_totals !== this.props.has_totals || prevProps.year !== this.props.year) {
                    let year = this.props.year;
                    let list_cats = [];
                    let id;

                    if (this.props.shop === 'SHOP_SB') {
                        id = this.props.category.name;
                    } else {
                        id = this.props.category.category_id;
                    }

                    list_cats = await this.storeReports.getOrdersByCategory(year, id, this.props.shop);
                   
                    this.setState({
                        list_cats: list_cats
                    })                 
                }
            }

            render() {
                return (  
                    <tr>
                        <td dangerouslySetInnerHTML={{ __html: this.props.shop === 'SHOP_SB' ? this.props.category.name : this.props.category.display_value}} />
                        {[...Array(12)].map((e, i) => 
                            <td>
                                <span className={this.state.list_cats && this.state.list_cats[i] == 0 ? '--inactive': ''}>{this.state.list_cats && this.state.list_cats[i]}</span>
                            </td>
                        )}
                    </tr>
                )
            }
        }
    )
)


const ReportSalesCategories = inject("stores") (
    observer (
        class ReportSalesCategories extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.storeCatalog = this.props.stores.storeCatalog;
                this.storeReports = this.props.stores.storeReports;
                this.state = {
                    totals: [],
                    has_totals: false,
                    listCats: []
                }
            }

            componentDidMount = async() => {
                const cats = await this.storeReports.getCategories(this.props.shop);
                this.createYears();
                this.getOrdersByMonth();

                this.setState({
                    listCats: cats
                })
            }

            getOrdersByMonth = async() => {
                let year = this.state.year;
                let month;
                let totals = [];
                let list_totals = [];

                [...Array(12)].map(async(e, i) => {
                    month = i + 1;
                    list_totals[i] = await this.storeCatalog.getOrdersByMonth(year, month);
                    
                    if (i == 11) {
                        [...Array(12)].map(async(e, ii) => {
                            totals.push(list_totals[ii])
                        });
                        this.setState({
                            totals: totals,
                            has_totals: true
                        })
                    }
                })
            }

            createYears = () => {
                const startYear = 2020;
                const currentYear = new Date().getFullYear(); 
                let years = [];

                let i = startYear;
                while (i <= currentYear) {
                    years.push(i);
                    i++;
                }

                this.setState({
                    year: currentYear,
                    years: years
                })
            }

            selectYear = (year) => {
                this.setState({
                    year: year
                })
            }

            render() {
                return (                  
                    <div className="content--section">
                        <div className="content--section__header">
                            <h2>Maandelijkse verkopen {this.props.shop} per categorie in {this.state.year}</h2>
                            <p>Getallen zijn indicatief, aangezien een boek onder meerdere categorieen kan vallen</p>
                            <div class="content--section__form-actions">
                            {this.state.years && this.state.years.map(year => (
                                    <button 
                                        className={"btn btn--secundary" + (year === this.state.year ? " --selected" : "")}
                                        onClick={() => this.selectYear(year)}
                                    >
                                        {year}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="content--section__columns">
                            <table className="table table--sales">
                            <thead>
                                <tr>
                                    <th>Categorie</th>
                                    <th>Jan</th>
                                    <th>Feb</th>
                                    <th>Maa</th>
                                    <th>Apr</th>
                                    <th>Mei</th>
                                    <th>Jun</th>
                                    <th>Jul</th>
                                    <th>Aug</th>
                                    <th>Sep</th>
                                    <th>Okt</th>
                                    <th>Nov</th>
                                    <th>Dec</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    {[...Array(12)].map((e, i) => 
                                        <AdminOverviewSalesTotals
                                            shop = {this.props.shop}
                                            year = {this.state.year}
                                            month = {i + 1}
                                        />
                                    )}
                                </tr>
                            {this.state.listCats.length > 0 && this.state.listCats.map((category, idx) => ( 
                                <AdminOverviewSalesRow
                                    shop = {this.props.shop}
                                    category = {category}
                                    year = {this.state.year}
                                    has_totals = {this.state.has_totals}
                                />
                            ))}
                            </tbody>
                            </table>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(ReportSalesCategories));
