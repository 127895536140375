import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';

import Header from '../components/header/header';
import WikiFeature from '../components/wiki/wiki-feature';

const Template = inject("stores") (
    observer (
        class Template extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;


                this.state = {
                    redirect: false
                }

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount() {
                if (!this.storeUi.admin_loggedin) {
                    this.setState({
                        redirect: true
                    })
                }
            }
      
            render() {
                if (this.state.redirect) {
                    // return <Redirect 
                    //     push to={"/login"} 
                    // />;
                }

                return (
                    <React.Fragment>
                        <Header />
                        <div className="wrapper-navigation">
                            <div className="db-blocks db-blocks--navigation">  
                            <div className="db-block db-block--navigation">
                                    <h2>Orders & klanten</h2>
                                    <ul>
                                        <li><a href="/admin-orders">Beheer orders</a></li>
                                        {/* <li><a href="https://beheer.succesboeken.info/" target="_blank">Beheer orders (ASP)</a></li> */}
                                        <li><a href="/admin-customers">Beheer klanten</a></li>
                                        <li><a href="/admin-partners">Beheer partners</a></li>
                                        <li><a href="/admin-order-errors">Controle order errors</a></li>
                                        <li><a href="/admin-partner-checks">Controle partners</a></li>
                                    </ul>
                                </div>

                                <div className="db-block db-block--navigation">
                                    <h2>MyParcel</h2>
                                    <ul>
                                        <li><Link to="/myparcel">Track & Trace</Link></li>
                                    </ul>
                                </div>

                                <div className="db-block db-block--navigation">
                                    <h2>Catalogus beheer</h2>
                                    <ul>
                                        <li>
                                        <ul>
                                            <li><Link to="/cb-check-updates">Dagelijkse updates</Link></li>
                                            <li><Link to="/cb-compare-stock">Controle voorraad SB</Link></li>
                                            <li><Link to="/cb-reports">Initialisatie catalogus 2021</Link></li>
                                            <li><Link to="/admin-books">Boeken</Link></li>
                                            <li><Link to="/admin-ebooks">E-boeken</Link></li>
                                            <li><Link to="/admin-toplists">Toplists</Link></li>
                                        </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div className="db-block db-block--navigation">
                                    <h2>Website beheer</h2>
                                    <ul>
                                        <li><Link to="/admin-settings">Settings</Link></li>
                                        <li><Link to="/admin-categories">Categorie indeling</Link></li>
                                        <li><Link to="/admin-contentbooks">Boeken in content</Link></li>
                                        <li><Link to="/admin-quotes">Quotes</Link></li>
                                        <li><Link to="/admin-videos">Videos</Link></li>
                                        {/* <li><Link to="/admin-notifications">Notificaties</Link></li> */}
                                        {/* <li><Link to="/admin-settings">Content aanpassingen</Link></li> */}
                                    </ul>
                                </div>

                                <div className="db-block db-block--navigation">
                                    <h2>Nieuwsbrief</h2>
                                    <ul>
                                        <li><Link to="/newsletters">Nieuwsbrief maken & versturen</Link></li>
                                        {/* <li><a href={this.urlAPI + "/test/viewTestNewsletter.php"}>Voorbeeld template</a></li> */}
                                    </ul>
                                </div>

                                <div className="db-block db-block--navigation">
                                    <h2>E-mails</h2>
                                    <ul>
                                        <li><Link to="/link-download">E-mail met download-link Joe Dispenza</Link></li>
                                        <li><Link to="/confirm-partner">E-mail met bevestiging partnercode</Link></li>
                                        <li><Link to="/emails">Test e-mails</Link></li>
                                    </ul>
                                </div>

                                <div className="db-block db-block--navigation">
                                    <h2>Website analytics</h2>
                                    <ul>
                                        <li><Link to="/analytics">Google Analytics</Link></li>
                                        <li><Link to="/sms">Spryng SMS</Link></li>
                                        <li><a href="/admin-overview-sales">Overzicht verkopen</a></li>
                                        <li><Link to="/report-sales-sku">Overzicht verkopen per ISBN</Link></li>
                                        <li><Link to="/report-accounts">Overzicht accounts</Link></li>
                                        <li><Link to="/report-newsletter">Nieuwsbrief</Link></li>
                                    </ul>
                                </div>

                                <div className="db-block db-block--navigation">
                                    <h2>Documentatie</h2>
                                    <ul>
                                        <li><Link to="/wiki">Wiki</Link></li>
                                    </ul>
                                </div>

                                <div className="db-block db-block--navigation">
                                    <h2>Tools</h2>
                                    <ul>
                                        <li><Link to="/translation">Vertaling</Link></li>
                                        <li><Link to="/srt-to-vtt">Ondertiteling SRT naar VTT</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="wrapper--content">
                            {this.props.children}
                        </div>

                        {this.props.type !== 'wiki' &&
                            <div className="wrapper--content">
                                <div className="content--section">
                                    <div className="content--section__header content--section__header--wiki">
                                        <h2>Wiki {this.props.title}</h2>
                                    </div>
                                    <WikiFeature 
                                        wikiSection = {this.props.section}
                                        wikiFeature = {this.props.feature}
                                    />
                                </div>
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(Template);
