import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import ReportSalesShops from '../../components/reports/report-sales-shops';
import ReportSalesCategories from '../../components/reports/report-sales-categories';

const AdminOverviewSales = inject("stores") (
    observer (
        class AdminOverviewSales extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.storeCatalog = this.props.stores.storeCatalog;
                this.state = {
                    year: 2021,
                    totals: [],
                    has_totals: false
                }
            }

            componentDidMount = async() => {

            }

            selectYear = (year) => {
                this.setState({
                    year: year
                })
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>Overzicht verkopen</h1>
                            </div>

                            <ReportSalesShops

                            />

                            {/* <ReportSalesCategories
                                shop = 'SHOP_SB'
                            />

                            <ReportSalesCategories
                                shop = 'SHOP_ALL'
                            /> */}
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminOverviewSales));
