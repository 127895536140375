import React from 'react';

const Checkbox = props => {

    let formControl = "form-control " + props.className;

    // if (props.touched && !props.valid) {
    //     formControl = 'form-control control-error';
    // }

    return (
        <div className="form-group form-group--checkbox">
            <input type="checkbox"
                name = {props.name}
                value = {props.value}
                checked = {props.isSelected}
                //onChange = {props.onChange}
                onChange={props.onCheckboxChange}
                className = {formControl}
            />
            {typeof props.label === 'object' && 
                <label className={formControl}>{props.label}</label>
            }
            {typeof props.label !== 'object' && 
                <label className={formControl} dangerouslySetInnerHTML={{ __html: props.label }}></label>
            }
           
        </div>
    );
}

export default Checkbox;