import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../../templates/template';
import CBReportInit from '../../components/cb/cb-report-init';
import CBReportOverview from '../../components/cb/cb-report-overview';
import CBCheckResources from '../../components/resources/check-resources';

const CBReports = inject("stores") (
    observer (
        class CBReports extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.urlSB = process.env.REACT_APP_URL_SB;
                
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeCb = this.props.stores.storeCb;
                
                this.state = {
                }
            }

            componentDidMount = () => {
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                    >   
                        <React.Fragment>
                            <div className="wrapper--content__header">
                                <h1>CB Rapporten</h1>
                            </div>

                            <div className="content--section">
                                <div className="content--section__header">
                                    <h2>Overzicht catalogi</h2>
                                </div>

                                <div className="wrapper--content__columns">
                                    <div className="content-column">
                                        <CBReportOverview 
                                            loading_file_init = {this.storeCb.loading_file_init}
                                            loading_file_daily = {this.storeCb.loading_file_daily}
                                        />
                                    </div>
                                </div>
                            </div> 

                            <CBReportInit />  

                            <CBCheckResources />    
                                                  
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CBReports));
