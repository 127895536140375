import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as content from '../communicator/content';

class StoreContent{
    quote = {};

    constructor() {
        
    }

    async viewQuote(id) {
        const data = {
            id: id
        }
        try {
            const returnData = await content.getQuote(data);
            runInAction(() => {
                this.quote = returnData.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async saveVtt(filename, data) {
        const input = {
            filename: filename,
            data: data
        }

        try {
            const returnData = await content.saveVtt(input);
            runInAction(() => {
                
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getPromoBooks() {
        let result;

        try {
            const returnData = await content.getPromoBooks();
            runInAction(() => {
                result = returnData.data;

                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async updatePromoBooks(data) {
        console.log('SbC pronos update', data)

        try {
            const returnData = await content.updatePromoBooks(data);
            runInAction(() => {
                
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

}

decorate(StoreContent, {
    quote: observable,
    viewQuote: action,
    saveVtt: action
})

export default StoreContent;