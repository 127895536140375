import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";
import moment from "moment";

import * as cb from '../communicator/cb';

class StoreCb {
    cb_reports = [];
    feedback_init = '';
    files_init = [];
    files_daily = [];
    loading_file_daily = '';
    loading_file_init = '';
    dates = [];
    covers_missing = [];
    pdfs_missing = [];
    resources_missing = [];

    constructor() {
        this.createDates();
    }

    async clearCatalog() {
        try {
            const returnData = await cb.clearCatalog();
            runInAction(() => {
                this.feedback_init = returnData.data;
                this.files_init = [];
                this.loading_file_init = 'x';
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async clearUpdates() {
        try {
            const returnData = await cb.clearUpdates();
            runInAction(() => {
                this.feedback_daily = returnData.data;
                this.files_daily = [];
                this.loading_file_daily = '';
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    clearFeedback() {
        this.feedback_init = '';
    }

    createDates = () => {
        let day;
        let month;
        let year;
        let date;
        let arr_dates = [];
        let str_date;
        Array.from(Array(999), (e, i) => {
            date = moment().subtract(i, 'days');
            day = moment(date).format('DD');
            month = moment(date).format('MM');
            year = moment(date).format('YYYY');
            str_date = year + month + day;
            this.dates.push(str_date);
        })
    }

    async initCatalog(update, filename, date) {
        if (update === 'INIT') {
            this.loading_file_init = filename;
        }
        if (update === 'DAILY' || update === 'INIT_DAILY') {
            this.loading_file_daily = filename;
        }
        const data = {
            update: update,
            filename: filename,
            date: date
        }
        let idx;

        try {
            const returnData = await cb.initCatalog(data);
            runInAction(() => {
                if (update === 'INIT') {
                    idx = this.files_init
                        .findIndex((file) => file.filename === filename);
                    this.files_init[idx] = returnData.data[0];
                    this.loading_file_init = '';
                };
                if (update === 'DAILY' || update === 'INIT_DAILY') {
                    idx = this.files_daily
                        .findIndex((file) => file.filename === filename);
                    this.files_daily[idx] = returnData.data[0];
                    this.loading_file_daily = '';
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async initResources(update, filename, date) {
        if (update === 'INIT') {
            this.loading_file_init = filename;
        }
        if (update === 'DAILY' || update === 'INIT_DAILY') {
            this.loading_file_daily = filename;
        }
        const data = {
            update: update,
            filename: filename,
            date: date
        }
        let idx;

        try {
            const returnData = await cb.initResources(data);
            runInAction(() => {
                if (update === 'INIT') {
                    this.loading_file_init = '';
                };
                if (update === 'DAILY' || update === 'INIT_DAILY') {
                    this.loading_file_daily = '';
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async checkRecourcesCBMissing(type, update, filename, date) {
        const data = {
            type: type,
            action: "CHECK",
            update: update,
            filename: filename,
            date: date
        }
        let missing;
        try {
            const returnData = await cb.checkRecourcesCBMissing(data);
            runInAction(() => {
                missing = returnData.data;
                return missing;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return missing;
    }

    async checkResourcesMissing(update, filename, date) {
        const data = {
            update: update,
            filename: filename,
            date: date
        }
        let result;

        try {
            const returnData = await cb.checkResourcesMissing(data);
            runInAction(() => {
                this.resources_missing = returnData.data[0];
                result = returnData.data[0];
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return result;
    }

    async getResourcesMissing(update, filename, date) {
        const data = {
            update: update,
            filename: filename,
            date: date
        }
        let idx;

        try {
            const returnData = await cb.getResourcesMissing(data);
            runInAction(() => {
                this.resources_missing = returnData.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async downloadResourcesCBMissing(type, update, filename, date) {
        const data = {
            type: type,
            action: "DOWNLOAD",
            update: update,
            filename: filename,
            date: date
        }
        let idx;

        try {
            const returnData = await cb.checkRecourcesCBMissing(data);
            runInAction(() => {
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async updateCatalog(update, filename) {
        this.loading_file_init = filename;
        const data = {
            update: update,
            filename: filename
        }

        try {
            const returnData = await cb.initCatalog(data);
            runInAction(() => {
                const idx = this.files_daily
                    .findIndex((file) => file.filename === filename);
                this.files_daily[idx] = returnData.data[0];
                this.loading_file_daily = '';
            })
        } catch (error) { 
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getCBReports() {
        try {
            const returnData = await cb.getCBReports();
            runInAction(() => {
                this.cb_reports = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getDBCount(shop, value) {
        let count;
        let data = {
            shop: shop,
            value: value
        }

        try {
            const returnData = await cb.getDBCount(data);
            runInAction(() => {
                count = returnData.data;
                return count;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return count;
    }

    async listFilesInit() {
        let files_init;
        try {
            const returnData = await cb.listFilesInit();
            runInAction(() => {
                this.files_init = returnData.data;
                files_init = returnData.data;
                return files_init;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return files_init;
    }

    async listFilesDaily() {
        let data = {
            update: 'DAILY'
        }

        try {
            const returnData = await cb.listFiles(data);
            runInAction(() => {
                this.files_daily = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getInitReports(update) {
        let data = {
            update: update
        }
        try {
            const returnData = await cb.getInitReports(data);
            runInAction(() => {
                if (update === 'INIT') {
                    this.files_init = returnData.data;
                }
                if (update === 'DAILY') {
                    this.files_daily = returnData.data;
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async checkUpdates2008(update) {
        let data_updated;
        let data = {
            update: update
        }
        try {
            const returnData = await cb.checkUpdates2008(data);
            runInAction(() => {
                data_updated = returnData.data;
                return data_updated;
             })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return data_updated;
    }

    async getDailyDirs() {
        let dirs;
        try {
            const returnData = await cb.getDailyDirs();
            runInAction(() => {
                dirs = returnData.data;
                return dirs;
             })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return dirs;
    }

    async getCoverLinks(file) {
        let result;
        try {
            const returnData = await cb.getCoverLinks(file);
            runInAction(() => {
                result = returnData.data;
                console.log('SbC cover links::', result)
                return result;
             })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return result;
    }

    async restartCbDailyUpdate() {
        console.log('SbC CB update starting:')
        try {
            const returnData = await cb.restartCbDailyUpdate();
            runInAction(() => {
                let result = returnData.data;
                console.log('SbC CB update started:', result)
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }
    
}

decorate(StoreCb, {
    cb_reports: observable,
    feedback_init: observable,
    files_daily: observable,
    files_init: observable,
    loading_file_daily: observable,
    loading_file_init: observable,
    covers_missing: observable,
    pdfs_missing: observable,
    resources_missing: observable,
    checkRecourcesCBMissing: action,
    checkResourcesMissing: action,
    checkUpdates2008: action,
    clearCatalog: action,
    clearFeedback: action,
    clearUpdates: action,
    downloadResourcesCBMissing: action,
    getCBReports: action,
    getCoverLinks: action,
    getDailyDirs: action,
    getDBCount: action,
    getInitReports: action,
    getResourcesMissing: action,
    initCatalog: action,
    listFilesDaily: action,
    listFilesInit: action,
    restartCbDailyUpdate: action,
    updateCatalog: action
})

export default StoreCb;