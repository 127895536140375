import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';

import Template from '../templates/template';
import TextInput from '../elements/form-input-text';
import TextArea from '../elements/form-input-textarea';

const AdminEBook = inject("stores") (
    observer (
        class AdminEBook extends Component {

            constructor(props) {
                super(props);
                this.props = props;  
                this.storeUi = this.props.stores.storeUi;
                this.storeEBooks = this.props.stores.storeEBooks;
                
                this.state = {
                    ebook: {},
                    formControls: {
                        title: {
                            value: '',
                            placeholder: "Vul titel in"
                        },
                        subtitle: {
                            value: '',
                            placeholder: "Vul auteur in"
                        },
                        author: {
                            value: '',
                            placeholder: "Vul volledige naam auteur in"
                        },
                        author_firstname: {
                            value: '',
                            placeholder: "Vul voornaam in"
                        },
                        author_lastname: {
                            value: '',
                            placeholder: "Vul achternaam in"
                        },
                        sku: {
                            value: '',
                            placeholder: "Vul ISBN in van papieren versie of waarbij donwload hoort"
                        },
                        txtDescription: {
                            value: '',
                            placeholder: "Vul beschrijving in"
                        },
                        cover: {
                            value: '',
                            placeholder: "Vul auteur in"
                        },
                        has_paper_version: {
                            value: '',
                            placeholder: "Vul auteur in"
                        },
                        isSupplement: {
                            value: '',
                            placeholder: "Hoort bij boek 1 of 0"
                        },
                        txtSupplement: {
                            value: '',
                            placeholder: "Vul toelichting in, indien bij boek hoort"
                        },
                        language: {
                            value: '',
                            placeholder: "Vul taal in NE of EN"
                        },
                        price: {
                            value: '',
                            placeholder: "Vul prijs in"
                        },
                        show_on_sb: {
                            value: '',
                            placeholder: "Toon op website 1 of 0"
                        },
                        txtRemark: {
                            value: '',
                            placeholder: "Vul opmerking in"
                        },
                        url_download: {
                            value: '',
                            placeholder: "Vul filename in (zonder extensie .pdf)"
                        },
                    }
                }
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.activeId !== this.props.activeId && this.props.activeId !== 0) {
                    const ebook = this.props.ebooks
                        .filter((ebook) => ebook.id == this.props.activeId);
                    this.setState({
                        ebook: ebook[0]
                    }, () => this.initEBook())
                }
            }

            handleChange = (event) => { 
                this.storeEBooks.clearFormFeedback();
                    
                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;

                updatedControls[name] = updatedFormElement;
          
                this.setState({
                    formControls: updatedControls
                })
            }

            initEBook = () => {
                const updatedControls = {
                    ...this.state.formControls
                };

                Object.keys(updatedControls).map((key) => {
                    if (this.props.activeId !== -1 && this.state.ebook[key] !== null) {
                        updatedControls[key] = {
                            value: this.state.ebook[key]
                        }
                    } else {
                        updatedControls[key] = {
                            value: ''
                        }
                    }
                })

                this.setState({
                    formControls: updatedControls
                })                
            }

            updateEBook = async(action) => {
                await this.storeEBooks.saveEBook(this.props.activeId, this.state.formControls);
                if (action === 'added') {
                    this.storeEBooks.setFormFeedback('success', 'Het boek is opgeslagen');
                };
                if (action === 'updated') {
                    this.storeEBooks.setFormFeedback('success', 'Het boek is aangepast');
                };
                this.props.onAdd();
            }

            deleteEBook = async() => {
                await this.storeEBooks.deleteEBook(this.props.activeId);
                this.props.onDelete();
            }

            render() {
                return (     
                    <React.Fragment>             
                        <ul className="block block-edit__list list--vert">
                            {Object.keys(this.state.formControls).map((key) => (
                                <li className="block block-edit__listitem">
                                    <span>{i18n.t("forms.label." + key)}</span>
                                    {(key !== 'txtDescription' &&
                                      key !== 'txtRemark' &&
                                      key !== 'txtSupplement') &&
                                        <TextInput 
                                            name = {key} 
                                            value = {this.state.formControls[key].value}
                                            onChange = {(e) => this.handleChange(e)}
                                            touched = {this.state.formControls[key].touched}
                                        />
                                    }
                                    {(key === 'txtDescription' ||
                                     key === 'txtRemark' ||
                                     key === 'txtSupplement') &&
                                        <TextArea
                                            name = {key} 
                                            value = {this.state.formControls[key].value}
                                            valid = {true}
                                            onChange = {this.handleChange}
                                        />
                                    }
                                </li>
                            ))}
                        </ul>

                        <div className="content--section__form-actions">
                            {(this.props.activeId !== -1 && this.props.activeId !== 0) &&
                                <button className="btn btn--secundary" onClick={(e) => this.deleteEBook(e)}>Verwijder e-book</button>
                            }
                            {(this.props.activeId === -1 || this.props.activeId === 0)
                            ?   <button className="btn btn--primary" onClick={(e) => this.updateEBook('added')}>Bewaar e-book</button>
                            :   <button className="btn btn--primary" onClick={(e) => this.updateEBook('updated')}>Update e-book</button>
                            }
                        </div>

                        {Object.keys(this.storeEBooks.form_feedback).length > 0 && 
                            <div className="form__feedback">
                                {'success' in this.storeEBooks.form_feedback &&
                                    <React.Fragment>
                                        <span className="form__feedback--success">{this.storeEBooks.form_feedback.success}</span>
                                    </React.Fragment>
                                }
                                {/* {'error' in this.storeBooks.form_feedback &&
                                    <React.Fragment>
                                        <span className="form__feedback--error">{this.storeBooks.form_feedback.error}</span>
                                    </React.Fragment>
                                } */}
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(AdminEBook));
